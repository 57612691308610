import { Box, Typography } from '@mui/material'
import React from 'react'

function CBCard({ children, label,newLine = false, sx, ...props }) {
    return (
        <Box sx={{ bgcolor: '#373737', borderRadius: 2, p: 2,whiteSpace:'pre-wrap', overflow:'auto', ...sx }} {...props}>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <div>{children}</div>
                {label && <Typography variant="caption" color="text.secondary">{label}</Typography>}
            </div>
        </Box>
    )
}

export default CBCard
