import { Box, Typography } from '@mui/material'
import React from 'react'
import Lottie from 'react-lottie'

function AcceptedConsoleOutput() {
    return (
        <Box sx={{ justifyContent: 'space-around' }}>
            <Typography sx={{}} variant="h6" color="success.main">ACCEPTED !</Typography>
            <Lottie
                options={{
                    path: 'https://assets1.lottiefiles.com/packages/lf20_s2lryxtd.json',
                    loop: true,
                    autoplay: true,
                }}
                width='fit-content'
                height='auto'
            />
        </Box >
    )
}

export default AcceptedConsoleOutput
