import { Refresh } from "@mui/icons-material";
import CancelIcon from '@mui/icons-material/Cancel';
import {
	Box,
	Checkbox,
	IconButton,
	Stack,
	Tooltip
} from "@mui/material";
import { debounce } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import CBButton from "../../common/components/CBButton";
import CBChip from "../../common/components/CBChip";
import CBCurvedTabSection from "../../common/components/CBCurvedTabSection";
import CBSearch from "../../common/components/CBSearch";
import CBTable from "../../common/components/CBTable";
import CBTabPanel from "../../common/components/CBTabPanel";
import DifficultyLevelChip from "../../common/components/DifficultyLevelChip";
import TopicsTabPanel from "../../common/components/TopicsTabPanel";
import { difficultyLevelSmall, solveStatusMap } from "../../common/constants";
import { useAuth } from "../../common/context/AuthContext";
import { getPublishedProblems } from "../apis/problemApis";
import { ReactComponent as GreenTickIcon } from "../assets/icon_green_tick.svg";
import { ReactComponent as YellowTickIcon } from "../assets/icon_yellow_tick.svg";
import { ReactComponent as PickRandomIcon } from "../assets/pick_random.svg";
import DifficultyMenu from "../components/DifficultyMenu";
import { DIFFICULTY_BY_VALUE } from "../constants";
import CBMenuButton from "../../common/components/CBMenuButton";
import CBSeo from "../../common/components/CBSeo";

const ProblemsPage = () => {
	const { publishedProblems } = useSelector((state) => state.publishedProblems);
	const [searchQuery, setSearchQuery] = useState("");
	const [selectedTags, setSelectedTags] = useState([]);
	const [difficultyLevel, setDifficultyLevel] = useState({ value: "", label: "Difficulty" });
	const [solveStatus, setSolveStatus] = useState({ value: "", label: "Status" });
	const [showTopicTags, setShowTopicTags] = useState(false);
	const [filteredProblemsComponent, setFilteredProblemsComponent] = useState(getFilteredProblemsData(publishedProblems || []));
	const [filteredProblems, setFilteredProblems] = useState((publishedProblems || []));

	const { currentUser, loading } = useAuth();

	useEffect(() => {
		if (!loading) {
			getPublishedProblems();
		}
	}, [loading]);

	useEffect(() => {
		debouncedFilterProblems();
		return () => debouncedFilterProblems.cancel(); // Cleanup on unmount
	}, [searchQuery, selectedTags, difficultyLevel, solveStatus, publishedProblems, showTopicTags]);


	const getFilteredProblemComponent = useCallback(() => {
		return getFilteredProblemsData(getFilteredProblems())
	}, [searchQuery, selectedTags, difficultyLevel, solveStatus, publishedProblems, showTopicTags]);

	const getFilteredProblems = useCallback(() => {
		const search = searchQuery.trim().toLowerCase();

		if (
			!selectedTags.length &&
			!search &&
			!difficultyLevel.value &&
			!solveStatus.value
		) {
			return (publishedProblems || []);
		}

		return ((publishedProblems || []).filter((problem) => {
			const matchesTitle = !search || problem.problemTitle.toLowerCase().includes(search);
			const matchesTags = !selectedTags.length || problem.tags.some((tag) => selectedTags.includes(tag));
			const matchesDifficulty = !difficultyLevel.value || problem.difficultyLevel === difficultyLevel.value;
			const matchesSolveStatus =
				!solveStatus.value ||
				(solveStatus.value === "TODO" && !problem.userProblemStatus) ||
				(solveStatus.value !== "TODO" && problem.userProblemStatus === solveStatus.value);

			return matchesTitle && matchesTags && matchesDifficulty && matchesSolveStatus;
		}));

	}, [searchQuery, selectedTags, difficultyLevel, solveStatus, publishedProblems, showTopicTags]);

	function getFilteredProblemsData(filteredProblems) {
		console.log("getFilteredProblemsData", filteredProblems[0])
		const data = filteredProblems.map((problem, index) => ({
			userProblemStatus: {
				value: problem.userProblemStatus,
				render: (
					<>
						{problem.userProblemStatus === "ATTEMPTED" ? (
							<Tooltip title="Attempted" arrow><YellowTickIcon /></Tooltip>
						) : problem.userProblemStatus === "ACCEPTED" ? (
							<Tooltip title="Solved" arrow><GreenTickIcon /></Tooltip>
						) : null}
					</>
				)
			},
			problemTitle: {
				value: problem.problemTitle,
				render: (
					<Box display={'flex'} flexDirection={'column'} alignItems={'start'}>
						<CBButton
							color="inherit"
							variant="text"
							onClick={() => handleProblemSelect(problem.titleSlug)}
							style={{ textAlign: 'start' }}
						>
							{problem.problemTitle}
						</CBButton>
						{showTopicTags &&
							<Box>{
								problem.tags.map((tag, index) => (
									<CBChip key={index} label={tag} sx={{ mr: 0.5, color: '#999999', bgcolor: '#202020' }} size="small" />
								))}
							</Box>
						}


					</Box>
				)
			}
			,
			difficultyLevel: {
				value: DIFFICULTY_BY_VALUE[problem.difficultyLevel],
				render: (
					<DifficultyLevelChip difficultyLevel={problem.difficultyLevel} />
				)
			}
		}))

		return data;
	}

	const debouncedFilterProblems = useMemo(
		() => debounce(() => {
			setFilteredProblemsComponent(getFilteredProblemComponent())
			setFilteredProblems(getFilteredProblems())
		}, 300),
		[getFilteredProblemComponent]
	);

	const headers = [
		{ key: 'userProblemStatus', label: 'Status', sortable: false },
		{ key: 'problemTitle', label: 'Title', sortable: true },
		{ key: 'difficultyLevel', label: 'Difficulty', sortable: true }
	];

	const handleFiltersReset = () => {
		setSelectedTags([])
	}

	const handleProblemSelect = useCallback((titleSlug) => {
		window.location.pathname = `problems/${titleSlug}/description`;
	}, []);

	const pickRandomProblem = useCallback(() => {
		if (filteredProblems.length) {
			const randomProblem = filteredProblems[Math.floor(Math.random() * filteredProblems.length)];
			handleProblemSelect(randomProblem.titleSlug);
		}
	}, [filteredProblems, handleProblemSelect]);

	return (
		<Box sx={{ bgcolor: "#1a1a1a", height: '100vh' }}>
			<CBSeo
				title={`Problems - CodeBattle`}
				description={`Level up your coding skills on CodeBattle.in! Practice DSA and coding interview questions with friends, compete in coding contests, and join a vibrant community. Tackle real tech challenges together and prepare for interviews with our extensive problem library and live competitions.`}
			/>
			<CBTabPanel sx={{ px: { lg: 20, md: 5, sm: 1, xs: 1, overflowY: 'auto' } }}>
				<Box sx={{ display: "flex", flexDirection: "row", height: 'calc(100vh - 94px)', bgcolor: "#1a1a1a" }}>
					<Box sx={{ flex: 1 }}>
						<Stack direction="column">
							<Filters
								difficultyLevel={difficultyLevel}
								setDifficultyLevel={setDifficultyLevel}
								solveStatus={solveStatus}
								setSolveStatus={setSolveStatus}
								onReset={handleFiltersReset}
								pickRandomProblem={pickRandomProblem}
								showTopicTags={showTopicTags}
								setShowTopicTags={setShowTopicTags}
							>
								<CBSearch
									placeholder="Search Questions"
									sx={{ ml: 1 }}
									onQueryChange={setSearchQuery}
									searchQuery={searchQuery}
								/>
							</Filters>
							<CBTable headers={headers} data={filteredProblemsComponent} sx={{ mt: 2 }} loading={(!publishedProblems || publishedProblems.length === 0)} noDataMessage={'No Problems Found 😔'} />
						</Stack>
					</Box>
					<ProblemTags selectedTags={selectedTags} setSelectedTags={setSelectedTags} />
				</Box>
			</CBTabPanel>
		</Box>
	);
};


function Filters({ difficultyLevel, setDifficultyLevel, solveStatus, setSolveStatus, onReset, pickRandomProblem, showTopicTags, setShowTopicTags, children }) {

	const handleShowTopicTags = (e) => {
		setShowTopicTags(e.target.checked)
	}

	const onResetClick = () => {
		setDifficultyLevel({ value: "", label: "Difficulty" })
		setSolveStatus({ value: "", label: "Status" })
		onReset()
	}
	return (<>
		<Box display="flex" flexDirection="row" alignItems="left">
			<DifficultyMenu
				difficultyMap={difficultyLevelSmall}
				difficultyLevel={difficultyLevel}
				setDifficultyLevel={setDifficultyLevel}
				variant="contained"
				color="third"
				sx={{ ml: 0 }}
			/>
			<CBMenuButton
				options={solveStatusMap}
				selectedOption={solveStatus}
				setSelectedOption={setSolveStatus}
				variant='contained'
				color="third"
				sx={{ ml: 1 }}
			/>
			{children}
			<Box sx={{ ml: 1 }}>
				<Tooltip title="Pick One">
					<IconButton sx={{ height: 36, width: 36, p: 0 }} onClick={pickRandomProblem}>
						<PickRandomIcon />
					</IconButton>
				</Tooltip>
			</Box>
		</Box>

		<Box display="flex" flexDirection="row" alignItems="left" sx={{ mt: 2 }}>
			{difficultyLevel.value &&
				<CBChip
					label={difficultyLevel.label}
					clickable
					onDelete={() => setDifficultyLevel({ value: "", label: "Difficulty" })}
					sx={{mr:1}}
				/>
			}
			{solveStatus.value &&
				<CBChip
					label={solveStatus.label}
					clickable
					onDelete={() => setSolveStatus({ value: "", label: "Status" })}
				/>
			}
			<Box sx={{ ml: 'auto', display: 'flex', gap: 1, alignItems: 'center', justifyContent: 'center' }}>
				<CBButton onClick={() => setShowTopicTags(!showTopicTags)} variant="text" color="secondary" sx={{ color: '#999999' }}> <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}> <Checkbox checked={showTopicTags} onChange={handleShowTopicTags} sx={{ color: "#999999", p: 0, pr: 0.5 }} size="small" /> Show topic tags </Box></CBButton>
				{(true) ? <CBButton onClick={onResetClick} variant="text" sx={{ color: '#999999', }} color="secondary"><Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}> <Refresh sx={{ mr: 0.5 }} fontSize="small" /> reset  </Box> </CBButton> : null}
			</Box>
		</Box>
	</>)
}

const ProblemTags = ({ selectedTags, setSelectedTags }) => (
	<Box sx={{ alignSelf: 'flex-start', width: 400, backgroundColor: "#282828", borderRadius: 2, ml: 2 }}>
		<CBCurvedTabSection
			tabs={[
				{
					key: "Problem Tags",
					node: <TopicsTabPanel selectedTags={selectedTags} setSelectedTags={setSelectedTags} sx={{ height: "100%" }} />,
				},
			]}
			initalTabIndex={0}
		/>
	</Box>
);
export default ProblemsPage;
