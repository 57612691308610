import Axios from "axios";
import { store } from '../../common/redux/store';
import { GET_CONTEST_CHAT } from "../constants";
import { setError } from "../redux/error";
import { setMessages } from "../redux/lobbyMessage";
import { } from "../redux/question";
import { processLoadMessage } from "../utils/lobbyUtils";

const headers = {
    'Content-Type': "application/json; charset=utf-8",
    "accept": "application/json"
}

export const getContestChat = () => {

    const contestId = store.getState().contest.id;
    if (!contestId) {
        console.log("Contest ID not present");
        return;
    }

    const targetUrl = `${GET_CONTEST_CHAT}`

    const payload = {
        "id": contestId
    }

    const requestOptions = {
        headers: headers,
    };

    Axios.post(targetUrl, payload, requestOptions)
        .then(
            (result) => {
                console.log("getScoreboard result : ", result);
                if (result.status === 200) {
                    store.dispatch(setMessages(processLoadMessage(result.data)))
                }
            },
            (error) => {
                console.log(error);
                store.dispatch(setError({
                    message: error?.response?.data?.message,
                    httpStatus: error?.response?.status,
                    code: error?.response?.data?.code,
                    shouldRedirect: error?.response?.status == 404 ? true : false,
                }));
            }
        )
}