// redux/store.ts (or store.js if you're not using TypeScript but applying the types conceptually)
import { configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import {
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import persistReducer from 'redux-persist/es/persistReducer';
import persistStore from 'redux-persist/es/persistStore';
import storage from 'redux-persist/lib/storage';
import { rootReducer } from './reducer'; // Adjust the path if necessary

const persistConfig = {
  key: 'root',
  blacklist: ['contest', 'question', 'scoreboard', 'lobbyMessage'],
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(logger),
});

export const persistor = persistStore(store);

// Define the RootState type based on the rootReducer
export type RootState = ReturnType<typeof rootReducer>;
