import { Box, IconButton } from '@mui/material';
import React, { useState } from 'react'
import CBMenu, { CBMenuOption } from '../../../../common/components/CBMenu';
import SettingsIcon from '@mui/icons-material/SettingsOutlined';
import { leaveContest } from '../../../apis/contestApis';
import Logout from '@mui/icons-material/Logout';

interface SettingMenuProps {
    contest_id: string
}

const settingOptions : CBMenuOption[] = [
    {
        value: 'leave',
        label: 'Leave',
        startIcon: <Logout fontSize="small" />
    }
]

const SettingMenu : React.FC<SettingMenuProps> = ({contest_id}) => {

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [selectedSetting, setSelectedSetting] = useState(settingOptions[0])

    const onSettingsIconClick = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleLeave = () => {
        leaveContest(contest_id);
    };

    const handleSelectSetting = (option: CBMenuOption) => {
        switch (option?.value) {
            case settingOptions[0]?.value:
                setSelectedSetting(option)
                handleLeave();
                break;
            default:
                break;
        }
        handleCloseMenu();
    }

    return (
        <Box>
            <IconButton sx={{ color: '#8a8a8a', ml: 1 }} onClick={(e) => onSettingsIconClick(e)}>
                <SettingsIcon />
            </IconButton>
            <CBMenu
                target={anchorEl}
                onCloseMenu={handleCloseMenu}
                onItemSelect={handleSelectSetting}
                options={settingOptions}
                checkIcon
                selectedOption={selectedSetting}
            />
        </Box>
    )
}

export default SettingMenu