import { Box, ListItem, Skeleton, Stack, Typography } from '@mui/material';

function LoadingConsoleOutput() {
    const sampleInputs = ['','','']
    return (
        <Box sx={{ justifyContent: 'space-around' }}>
            <Typography variant="h6"><Skeleton width="40%"/></Typography>
            <Stack direction="row" spacing={1} alignItems="center" sx={{ mt: 1 }}>
                {sampleInputs && sampleInputs.map((data, index) => {
                    return (
                        <ListItem key={index} sx={{ width: "56px", p: 0 }}>
                            <Skeleton width={72} height={56}/>
                        </ListItem>
                    );
                })}
            </Stack>
            <Skeleton sx={{mt:1}} height={56}/>
            <Skeleton sx={{mt:1}} height={56}/>
        </Box >
    )
}

export default LoadingConsoleOutput
