
import ShareIcon from '@mui/icons-material/Share';
import { Badge, Box, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import Countdown, { zeroPad } from 'react-countdown';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import CBButton from '../../common/components/CBButton';
import CBLoader from '../../common/components/CBLoader';
import CBTabPanel from '../../common/components/CBTabPanel';
import { CopyToolTip } from '../../common/components/CopyToolTip';
import DifficultyLevelChip from '../../common/components/DifficultyLevelChip';
import { getContests } from '../apis/contestApis';
import { CONTEST_STATUS } from '../constants';
import { PATH_EDITOR, PATH_PRACTICE_ROOM } from '../../common/utils/NavOptionRoutes';
import CBSeo from '../../common/components/CBSeo';


const ContestListPage = () => {
    const [copiedLink, setCopiedLink] = useState(false)
    const { rooms } = useSelector(state => state.room)
    const { activeRoomBanner } = useSelector(state => state.remoteConfig)

    useEffect(() => {
        getContests()
    }, [])

    console.log("getContests: RoomList",rooms);

    const getContestPath = (contest) => {
        if(contest?.difficultyLevel === 'CUSTOM'){
            return PATH_PRACTICE_ROOM + contest.id
        } else {
           return PATH_EDITOR + contest.id
        }
    }


    const handleJoinClick = (contest) => {
        window.location.pathname = getContestPath(contest)
    }

    const handleShareClick = (contest) => {
        navigator.clipboard.writeText(window.location.origin + getContestPath(contest))
        setCopiedLink(true)
        toast.success("Link is Copied", { hideProgressBar: true, autoClose: 1000 })
    }

    const getStatus = (status) => {
        if (status == CONTEST_STATUS.STARTED) {
            return <Badge variant="dot" color="success" sx={{
                '& .MuiBadge-badge': {
                    right: '100%',
                    top: '50%',
                },
            }}><Typography sx={{ paddingLeft: 2 }}>Live</Typography></Badge>
        } else if (status == CONTEST_STATUS.CREATED) {
            return <Badge variant="dot" color="warning" sx={{
                '& .MuiBadge-badge': {
                    right: '100%',
                    top: '50%',
                },
            }}><Typography sx={{ paddingLeft: 2 }}>Yet to Start</Typography></Badge>
        }
        return status
    }

    const openBannerLink = () => {
        if(activeRoomBanner?.onClickUrl){
            window.location = (activeRoomBanner?.onClickUrl)
        }
    }

    console.log("ContestListPage", activeRoomBanner)
    return (
        <Box sx={{overflow:'auto', bgcolor: '#1a1a1a',}}>
            <CBSeo
                title="Rooms - CodeBattle"
                description="Explore active coding rooms on CodeBattle.in! Join upcoming challenges, practice DSA problems with friends, and test your skills in a range of coding battles. Compete, learn, and excel on CodeBattle."
            />
            {activeRoomBanner?.isVisible && activeRoomBanner?.imgUrl &&
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    pt: 2,
                    px: {
                        lg: 20,
                        md: 5,
                        sm: 1,
                        xs: 1,
                    },
                    cursor: 'pointer',  
                }} onClick={openBannerLink}>
                    <img width='100%' src={activeRoomBanner?.imgUrl} />
                </Box>
            }
            {
                !rooms || rooms?.length == 0 ? <CBLoader /> :
                    <CBTabPanel sx={{
                        px: {
                            lg: 20,
                            md: 5,
                            sm: 1,
                            xs: 1,
                        },
                        bgcolor: '#1a1a1a'
                    }}>
                        <Table sx={{
                            '& .MuiTableCell-root': {
                                border: 'none'
                            }
                        }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ fontWeight: 'bold', fontSize: '14px' }}>Contest Name</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold', fontSize: '14px' }}>Participants</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold', fontSize: '14px' }}>Time Left</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold', fontSize: '14px' }}>Difficulty Level</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold', fontSize: '14px' }}>Status</TableCell>
                                    <TableCell align='center' sx={{ fontWeight: 'bold', fontSize: '14px' }}>Action</TableCell>


                                </TableRow>

                            </TableHead>
                            <TableBody>
                                {rooms?.map((contest, index) => (
                                    <TableRow key={contest.id} sx={{
                                        bgcolor: index % 2 == 0 && '#282828',

                                    }}>
                                        <TableCell>
                                            <Typography variant="body2" fontWeight='600'>{index + 1}.  &nbsp; {contest.name}</Typography>
                                        </TableCell>
                                        <TableCell>{contest?.participants?.length + " / " + contest?.participantLimit}</TableCell>
                                        <TableCell>
                                            {contest.status == CONTEST_STATUS.CREATED ? <Typography variant="body2">{zeroPad(contest.contestLength)}:{zeroPad(0)}</Typography> :
                                                <Countdown
                                                    key={Date.now() + contest.timeLeft}
                                                    date={Date.now() + contest.timeLeft}
                                                    renderer={({ days, hours, minutes, seconds }) => {
                                                        if (days && days > 0) {
                                                            const totalHours = days * 24 + hours;
                                                            return <Typography variant="body2">{zeroPad(totalHours)}:{zeroPad(minutes)}:{zeroPad(seconds)}</Typography>
                                                        }
                                                        else if (hours && hours > 0) {
                                                            return <Typography variant="body2">{zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}</Typography>
                                                        }
                                                        return <Typography variant="body2">{zeroPad(minutes)}:{zeroPad(seconds)}</Typography>
                                                    }}
                                                />
                                            }
                                        </TableCell>
                                        <TableCell>
                                            <DifficultyLevelChip difficultyLevel={contest.difficultyLevel} />
                                        </TableCell>
                                        <TableCell>{getStatus(contest.status)}</TableCell>
                                        <TableCell sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
                                            <CBButton variant="outlined" disabled={contest.status == CONTEST_STATUS.ENDED} onClick={() => handleJoinClick(contest)}>Join</CBButton>
                                            <CopyToolTip copiedLink={copiedLink} setCopiedLink={setCopiedLink}>
                                                <IconButton sx={{ ml: 1, color: '#8a8a8a' }} disabled={contest.status == CONTEST_STATUS.ENDED} onClick={() => handleShareClick(contest)}>
                                                    <ShareIcon fontSize="small" />
                                                </IconButton>
                                            </CopyToolTip>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </CBTabPanel>
            }
        </Box>
    )
}

export default ContestListPage;