import { createSlice } from '@reduxjs/toolkit'
import { getObjectColor } from '../../utils'

const initialState = {
    participants: [],
    isUserReady: true
}

export const participantSlice = createSlice({
    name: 'participant',
    initialState,
    reducers: {
        setParticipants: (state, action) => {
            if(action?.payload){
                action?.payload?.forEach((participant,index) => {
                    participant.color = getObjectColor(index)
                });
            }
            state.participants = action.payload
        },
        addParticipant: (state, action) => {
            console.log("addParticipant : ",action.payload);
            if(!action.payload) return
            const participantExist = state.participants.some(item => item.uid === action.payload.uid);
            console.log("participantExist : ",participantExist);
            if(!participantExist){
                action.payload.color = getObjectColor(state.participants.length + 1)
                state.participants = [...state.participants,action.payload]
            }
            console.log(state.participants);
        },
        updateParticipantStatus: (state, action) => {
            console.log("updateParticipantStatus : ",action.payload);
            if(!action.payload) return
            const dummyList = [...state.participants]
            dummyList.map(p => {
                if(p.uid === action.payload.uid){
                    p.status = action.payload.status
                }
                return p;
            })
            state.participants = [...dummyList]
        },
        setIsUserReady: (state, action) => {
            state.isUserReady = action.payload
        },
    },
})

export const { setParticipants, addParticipant, setIsUserReady, updateParticipantStatus } = participantSlice.actions

export default participantSlice.reducer