import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CBProblemTemplate from '../../../../common/components/CBProblemTemplate';
import CBTabPanel from '../../../../common/components/CBTabPanel';
import { setActiveQuestion } from '../../../redux/contest';
import { setActiveInputCases } from '../../../../common/redux/submission';


const ProblemTabPanel = () => {
    const [problemIndex, setProblemIndex] = useState(0)
    const { questions } = useSelector(state => state.question)
    const dispatch = useDispatch()
    useEffect(() => {
        if (questions[problemIndex]) {
            dispatch(setActiveQuestion(questions[problemIndex]))
            dispatch(setActiveInputCases(questions[problemIndex]?.sampleInput))
        }
    }, [problemIndex])

    const onProblemIndexChange = (value) => {
        if (value < 0 || value >= questions.length) return
        setProblemIndex(value)
    }

    return (
        <CBTabPanel sx={{ overflow: 'auto' }}>
            <CBProblemTemplate problem={questions?.[problemIndex] || {}} problemIndex={problemIndex} setProblemIndex={onProblemIndexChange} totalProblems={questions.length}/>
        </CBTabPanel>
    );
}

export default ProblemTabPanel;