import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    rooms: [],
}

export const roomSlice = createSlice({
    name: 'room',
    initialState,
    reducers: {
        setAllRooms: (state, action) => {
            console.log("getContests action: ",action);
            if(!action?.payload) return;
            state.rooms = [...action?.payload]
        }
    },
})

export const { setAllRooms } = roomSlice.actions

export default roomSlice.reducer