import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    messages: []
};

const lobbyMessageSlice = createSlice({
  name: 'lobbyMessage',
  initialState,
  reducers: {
    addMessage: (state, action) => {
        if(!action.payload) return
        const newMessages = [...state.messages,action.payload]
        state.messages = newMessages
    },
    setMessages: (state, action) => {
        if(!action.payload) return
        state.messages = action.payload
    },
  },
});

export const { addMessage,setMessages } = lobbyMessageSlice.actions;

export default lobbyMessageSlice.reducer;
