import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    scores: [],
}

export const scoreboardSlice = createSlice({
    name: 'scoreboard',
    initialState,
    reducers: {
        setScores: (state, action) => {
            state.scores = action.payload
        }
    },
})

export const { setScores } = scoreboardSlice.actions

export default scoreboardSlice.reducer