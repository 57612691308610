import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    endTime : 0
};

const countdownSlice = createSlice({
  name: 'countdown',
  initialState,
  reducers: {
    setEndTime: (state, action) => {
        state.endTime = action.payload
    },
  },
});

export const { setEndTime } = countdownSlice.actions;

export default countdownSlice.reducer;
