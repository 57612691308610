import { combineReducers } from 'redux'

import contestReducer from '../../cbApp/redux/contest'
import loginReducer from './login'
import participantReducer from '../../cbApp/redux/participant'
import questionReducer from '../../cbApp/redux/question'
import roomReducer from '../../cbApp/redux/room'
import publishedProblemsReducer from '../../cbApp/redux/publishedProblems'
import websocketReducer from '../../cbApp/redux/websocket'
import scoreboardReducer from '../../cbApp/redux/scoreboard'
import countdownReducer from '../../cbApp/redux/countdown'
import editorReducer from '../../cbApp/redux/editor'
import usersReducer from '../../cbApp/redux/users'
import errorReducer from "../../cbApp/redux/error";
import leaderboardReducer from "../../cbApp/redux/leaderboard"
import lobbyMessageReducer from "../../cbApp/redux/lobbyMessage";

import adminQuestionReducer from "../../adminApp/redux/adminQuestions"
import createProblemReducer from "../../adminApp/redux/createProblem"
import problemTopicReducer from "../../adminApp/redux/topic"
import publishedProblemTopicReducer from "../../cbApp/redux/publishedTopic"
import remoteConfigReducer from "../../cbApp/redux/remoteConfig"
import submissionReducer from "./submission"

const appReducer = combineReducers({
  /* your app’s top-level reducers */
    contest: contestReducer,
    room: roomReducer,
    login: loginReducer,
    participant: participantReducer,
    question: questionReducer,
    publishedProblems: publishedProblemsReducer,
    websocket: websocketReducer,
    scoreboard: scoreboardReducer,
    countdown: countdownReducer,
    editor: editorReducer,
    users: usersReducer,
    error: errorReducer,
    lobbyMessage: lobbyMessageReducer,
    adminQuestion: adminQuestionReducer,
    submission: submissionReducer,
    leaderboard: leaderboardReducer,
    createProblem: createProblemReducer,
    problemTopic: problemTopicReducer,
    publishedProblemTopic: publishedProblemTopicReducer,
    remoteConfig: remoteConfigReducer,
})


const RESET_STATE = 'RESET_STATE';
export function resetStateAction() {
  return { type: RESET_STATE };
}

export const rootReducer = (state, action) => {
  if (action.type === RESET_STATE) {
    state = undefined;
  }
  return appReducer(state, action)
}