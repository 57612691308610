import CloseIcon from '@mui/icons-material/Close';
import { Box, Card, Dialog, IconButton, Typography } from '@mui/material';
import { useState } from 'react';
import { toast } from 'react-toastify';
import CBButton from '../../common/components/CBButton';

const InvitePopup = ({ showInvitePopup, setShowInvitePopup }) => {

    const [copiedLink, setCopiedLink] = useState(false)

    const handleOnClickCopy = () => {
        navigator.clipboard.writeText(window.location.href)
        setCopiedLink(true);
        setShowInvitePopup(false)
        toast.success("Link is Copied",{hideProgressBar: true,autoClose: 1000})
    }

    return (
        <Dialog maxWidth='md' open={showInvitePopup} onClose={() => setShowInvitePopup(false)}>
            <Box sx={{ p: 2, minWidth: '400px', borderRadius: 2 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <Typography>Invite friends to the contest</Typography>
                    <IconButton size='small' sx={{ ml: 'auto' }} onClick={() => setShowInvitePopup(false)}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' , mt:2}}>
                    <Card sx={{ p: 1, mr:2}}>
                        <Typography sx={{overflow: 'hidden', textOverflow: 'ellipsis'}}>{window.location.href}</Typography>
                    </Card>
                    <CBButton onClick={handleOnClickCopy}>COPY</CBButton>

                </Box>
            </Box>
        </Dialog>
    )
}

export default InvitePopup