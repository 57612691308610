import axios from 'axios';
import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { fetchAndActivate, getRemoteConfig, getValue } from "firebase/remote-config";
import * as firebaseui from "firebaseui";
import 'firebaseui/dist/firebaseui.css';
import { toast } from "react-toastify";
import { setActiveRoomBanner } from "./cbApp/redux/remoteConfig";
import { ACTIVE_ROOM_SPECIAL_BANNER, DEFAULT_ACTIVE_ROOM_SPECIAL_BANNER } from "./cbApp/remoteConstants";
import { store } from "./common/redux/store";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const remoteConfig = getRemoteConfig(app);
remoteConfig.settings.minimumFetchIntervalMillis = 3600000;
remoteConfig.defaultConfig = {
  ACTIVE_ROOM_SPECIAL_BANNER: DEFAULT_ACTIVE_ROOM_SPECIAL_BANNER
}

fetchAndActivate(remoteConfig)
  .then(() => {
    const banner = getValue(remoteConfig, ACTIVE_ROOM_SPECIAL_BANNER)?.asString();
    console.log("RemoteConfig90 banner:", banner);
    store.dispatch(setActiveRoomBanner(JSON.parse(banner)))
  })
  .catch((err) => {
    console.log("RemoteConfig90 :err", err);
    store.dispatch(setActiveRoomBanner(DEFAULT_ACTIVE_ROOM_SPECIAL_BANNER))
  });


// onIdTokenChanged(auth, async (user) => {
//   console.log("onIdTokenChanged : ",user);
//   if (user) {
//     const token = await user.getIdToken();
//     axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
//   } else {
//     delete axios.defaults.headers.common['Authorization'];
//   }
// });

const uiConfig = {
  callbacks: {
    signInSuccessWithAuthResult: function (authResult, redirectUrl) {
      console.log("authResult : ", authResult);
      console.log("redirectUrl : ", redirectUrl);
      console.log("ui accessToken : ", authResult.user.accessToken);
      localStorage.setItem('access_token', authResult?.user?.accessToken)
      // const payload = {
      //   "uid": authResult.user.uid,
      //   "displayName": authResult.user.displayName,
      //   "email": authResult.user.email,
      //   "phoneNumber": authResult.user.phoneNumber,
      //   "photoURL": authResult.user.photoURL
      // }
      // console.log("saving use in firebase.js with : ",payload);
      // saveUser(payload)
      window.location.reload()
    },
  },
  signInFlow: 'popup',
  signInOptions: [
    {
      provider: GoogleAuthProvider.PROVIDER_ID,
      clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID
    }
  ],
  // credentialHelper: firebaseui.auth.CredentialHelper.GOOGLE_YOLO,

};

// // Initialize the FirebaseUI Widget using Firebase
// const ui = new firebaseui.auth.AuthUI(auth);
// ui.start('#firebaseui-auth-container', uiConfig);

// Set the maximum number of retries
const maxRetries = 3;

// Define a retry delay (in milliseconds)
const retryDelay = 500;

// Define a function to retry a failed request
const retryRequest = async (error, originalRequest) => {
  // Check if the maximum number of retries has been reached
  if (originalRequest._retryCount >= maxRetries) {
    return Promise.reject(error);
  }

  // Increment the retry count
  originalRequest._retryCount = (originalRequest._retryCount || 0) + 1;

  // Get the Firebase auth token
  const user = auth.currentUser;
  const token = await user.getIdToken(true);

  // Set the auth token in the request headers
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

  // Wait for the retry delay before retrying the request
  await new Promise(resolve => setTimeout(resolve, retryDelay));

  originalRequest.headers['Authorization'] = `Bearer ${token}`

  // Retry the original request
  return axios(originalRequest);
};

// Add an axios interceptor to catch 401 errors and retry with Firebase auth credentials
axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      originalRequest._retryCount = 0; // Set the initial retry count to zero
      try {
        return retryRequest(error, originalRequest);
      } catch (error) {
        // Handle Firebase authentication errors
        return Promise.reject(error);
      }
    } else if (error.response.status === 429) {
      toast.error('Too Many Requests. Please try again later.');
    }
    return Promise.reject(error);
  }
);


export { auth, firebaseui, uiConfig, analytics };
