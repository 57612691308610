import { BottomNavigation } from '@mui/material';
import { ProblemEditStage, ProblemStatus } from '../../constants';
import ProblemMetaSection from './metadataSection/problemMetaSection';
import ProblemEditorSection from './problemSection/problemEditorSection';
import ProblemSolveSection from './problemSolveSection/problemSolveSection';
import { useEffect, useState } from 'react';
import { createProblem, updateProblem, updateProblemMetadata, updateProblemStatus } from '../../apis/adminApis';
import { useParams } from 'react-router-dom';
import CBButton from '../../../common/components/CBButton';

const RightPanelSection = ({selectedQuestionMetaData,selectedQuestion,currentUser,setSelectedQuestion}) => {

    const [stageIndex, setStateIndex] = useState(0)
    const { problem_id } = useParams();
    

    useEffect(() => {
        if (selectedQuestion?.status === ProblemStatus.PUBLISHED) {
            setStateIndex(ProblemEditStage.length - 1)
        }
        else if (selectedQuestion?.status === ProblemStatus.DRAFT) {
            setStateIndex(0)
        }
    }, [selectedQuestion?.status])

    const onNextClick = () => {
        setStateIndex(stageIndex + 1)
    }

    const onPrevClick = () => {
        setStateIndex(stageIndex - 1)
    }

    const onPublishClick = () => {
        const newState = selectedQuestion?.status == ProblemStatus.PUBLISHED ? ProblemStatus.DRAFT : ProblemStatus.PUBLISHED
        updateProblemStatus(problem_id, newState)
    }

    const onSaveClick = () => {
        switch (ProblemEditStage[stageIndex]) {
            case "PROBLEM_DESCRIPTION":
                {
                    if(selectedQuestion?.id){
                        updateProblem(selectedQuestion, currentUser.uid)
                    }else{
                        createProblem(selectedQuestion, currentUser.uid)
                    }
                    break;
                }
            case "PROBLEM_METADATA":
                {
                    updateProblemMetadata(problem_id, selectedQuestion.problemTitle, selectedQuestionMetaData)
                    break;
                }
            case "PROBLEM_SOLVE":
                {
                    break;
                }
        }
    }
  

    const getRightPanel = (stageIndex,selectedQuestion) =>{
        console.log("getRightPanel",selectedQuestion);
        console.log("getRightPanel",stageIndex);
        switch(ProblemEditStage[stageIndex]){
            case "PROBLEM_DESCRIPTION":
                return <ProblemEditorSection selectedQuestion={selectedQuestion} setSelectedQuestion={setSelectedQuestion}/>
            case "PROBLEM_METADATA":
                return <ProblemMetaSection selectedQuestionMetaData={selectedQuestionMetaData}/>
            case "PROBLEM_SOLVE":
                return <ProblemSolveSection selectedQuestion={selectedQuestion} selectedQuestionMetaData={selectedQuestionMetaData}/>
        }
    }

    return (
        <div>
            {getRightPanel(stageIndex,selectedQuestion)}
            <BottomNavigation
                        showLabels
                        value={stageIndex}
                        onChange={(event, newValue) => {
                            setStateIndex(newValue);
                        }}
                        sx={{ zIndex:1000,position: 'sticky', bottom: '0', justifyContent: 'end', pr: 8 }}>
                        <CBButton variant='text' onClick={onPrevClick} disabled={stageIndex <= 0 || selectedQuestion?.status == ProblemStatus.PUBLISHED}>Previous</CBButton>
                        <CBButton variant='text' onClick={onNextClick} disabled={stageIndex >= ProblemEditStage.length - 1} >Next</CBButton>
                        <CBButton variant='text' onClick={onSaveClick}>Save</CBButton>
                        <CBButton variant='text' onClick={onPublishClick}>{selectedQuestion?.status == ProblemStatus.PUBLISHED ? "Move to Draft" : "Publish"}</CBButton>
                    </BottomNavigation>
        </div>
    )
}

export default RightPanelSection;

