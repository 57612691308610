import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, Modal, Tooltip, Typography } from "@mui/material";
import { useState } from "react";
import { createContest } from '../../cbApp/apis/contestApis';
import { setShowLoginPopup } from '../redux/login';
import { useAuth } from '../context/AuthContext';
import CBButton from "./CBButton";
import CBCurvedTabSection from "./CBCurvedTabSection";
import CBRadioGroup from './CBRadioGroup';
import TopicsTabPanel from "./TopicsTabPanel";
import CBTimeSelector from './CBTimeSelector';
import { zeroPad } from 'react-countdown';
import AlarmOnIcon from '@mui/icons-material/AlarmOn';

const AdvanceControl = ({ showAdvance, setShowAdvance, difficultyLevel, setDifficultyLevel, difficultyMap }) => {


    const [selectedTags, setSelectedTags] = useState([]);
    const tabs = [
        { key: "Topics", node: <TopicsTabPanel selectedTags={selectedTags} setSelectedTags={setSelectedTags} /> }
    ]

    const { currentUser, loading } = useAuth();

    const handleCreateRoom = () => {

        if (!currentUser) {
            setShowLoginPopup(true, () => {
                createContest(
                    {
                        difficultyLevel: difficultyLevel.value,
                        contestLength: difficultyLevel.defaultContestTime,
                        topics: selectedTags
                    },
                    currentUser
                );
            });
        } else {
            createContest(
                {
                    difficultyLevel: difficultyLevel.value,
                    contestLength: difficultyLevel.defaultContestTime,
                    topics: selectedTags
                },
                currentUser
            );
        }
    }

    const [tooltipOpen, setTooltipOpen] = useState(false);

    const handleTooltipOpen = () => {
        console.log("AdvanceControl: handleTooltipOpen");
        setTooltipOpen(true);
    };

    const handleTooltipClose = () => {
        setTooltipOpen(false);
    };

    return (
        <Modal open={showAdvance}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 2, backdropFilter: 'blur(5px)', outline: 'none' }}>
            <Box sx={{ bgcolor: '#1a1a1a', p: 3, width: { xl: '50%', lg: '50%', md: '60%', sm: '90%', xs: '90%', maxHeight: '660px', maxWidth: '652px', overflow: 'auto' }, borderRadius: 4 }}>
                <Box display={'flex'} flexDirection={'row'}>
                    <Typography fontSize={'1.375rem'} fontWeight={'600'} component="h2">
                        Create a room
                    </Typography>
                    <IconButton size='small' sx={{ ml: 'auto' }} onClick={() => setShowAdvance(false)}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Typography fontSize={'1rem'} fontWeight={'600'} sx={{ mt: 3 }}>
                    Level
                </Typography>

                <CBRadioGroup options={difficultyMap} selectedOption={difficultyLevel} setSelectedOption={setDifficultyLevel} />

                <Typography sx={{ mt: 2 }} fontSize={'1rem'} fontWeight={'600'}>
                    Filters
                </Typography>
                <CBCurvedTabSection tabs={tabs} initalTabIndex={0} />

                <Typography sx={{ mt: 2 }} fontSize={'1rem'} fontWeight={'600'}>
                    Duration
                </Typography>

                <Tooltip
                    open={tooltipOpen}
                    onClose={handleTooltipClose}
                    title="Custom duration coming soon..."
                    PopperProps={{
                        sx: {
                            '& .MuiTooltip-arrow': {
                                color: 'rgba(62, 62, 62, 0.3)'
                            },
                            '& .MuiTooltip-tooltip': {
                                backgroundColor: 'rgba(62, 62, 62, 0.3)',
                            },
                        }
                    }}
                    arrow>
                    <Box sx={{ marginTop: '8px', display: 'inline-flex', flexDirection: 'row' }} onMouseEnter={handleTooltipOpen} onMouseLeave={handleTooltipClose} onClick={handleTooltipOpen}>

                        <CBTimeSelector selectedTime={difficultyLevel.defaultContestTime} />

                        <Box sx={{ px: 1.5, py: 1, bgcolor: 'rgba(62, 62, 62, 0.3)', borderRadius: 2, ml: 1, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <AlarmOnIcon fontSize="small" sx={{ mr: 1 }} />
                            <Typography>
                                {zeroPad(difficultyLevel.defaultContestTime)} min
                            </Typography>
                        </Box>

                    </Box>
                </Tooltip>


                <Box display='flex' justifyContent={'end'} sx={{ mt: 6.5 }}>
                    <CBButton variant="outlined" onClick={() => setShowAdvance(false)}>Cancel</CBButton>
                    <CBButton sx={{ ml: 2 }} onClick={handleCreateRoom}>Create Room</CBButton>
                </Box>
            </Box>
        </Modal>
    )

}

export default AdvanceControl;