import { createSlice } from '@reduxjs/toolkit';
import { DEFAULT } from '../constants';
import { languageOptions, languageModeMap, EDITOR_FONT_SIZE_OPTIONS, EDITOR_KEY_BINDING_OPTIONS, EDITOR_TAB_SIZE_OPTIONS } from '../../common/constants';

const initialState = {
  selectedLanguage: languageOptions[0],
  editorMode: 'c_cpp',
  questionCode: Object.assign({}, ...languageOptions.map(({ value, code }) => ({ [`${DEFAULT}${value}`]: code }))),
  defaultCode: Object.assign({}, ...languageOptions.map(({ value, code }) => ({ [`${DEFAULT}${value}`]: code }))),
  questionLang: {
    [DEFAULT]: languageOptions[0]?.value
  },
  editorLoading: false,
  fontSize: EDITOR_FONT_SIZE_OPTIONS[1],
  keyBinding: EDITOR_KEY_BINDING_OPTIONS[0],
  tabSize: EDITOR_TAB_SIZE_OPTIONS[0],
  wordWrap: false,
};

const editorSlice = createSlice({
  name: 'editor',
  initialState,
  reducers: {
    setSelectedLanguage: (state, action) => {
      state.selectedLanguage = action.payload
      state.editorMode = languageModeMap.find(item => item?.value === action?.payload?.value)?.mode || 'c_cpp'
      if (action.payload.activeQuestionId) {
        state.questionLang = {
          ...state.questionLang,
          [action.payload.activeQuestionId]: action.payload.value
        }
      } else {
        state.questionLang = {
          ...state.questionLang,
          [DEFAULT]: action.payload.value
        }
      }
    },
    setDefaultCodes: (state, action) => {
      const { defaultCodes } = action.payload;
      const newQuestionCode = { ...state.questionCode }
      defaultCodes?.map((defaultCode) => {
        newQuestionCode[defaultCode.problemId + defaultCode.languageId] = defaultCode.userCode
      })
      const newDefaultCodes = { ...state.defaultCode }
      defaultCodes?.map((defaultCode) => {
        newDefaultCodes[defaultCode.problemId + defaultCode.languageId] = defaultCode.userCode
      })
      state.questionCode = newQuestionCode
      state.defaultCode = newDefaultCodes
    },
    addQuestionCode: (state, action) => {
      const { questionId } = action.payload;
      const newQuestionCode = { ...state.questionCode }

      state.questionCode = {
        ...state.questionCode,
        [questionId]: {
          ...state.questionCode[questionId],
        }
      }
    },
    updateQuestionCode: (state, action) => {
      state.questionCode = {
        ...state.questionCode,
        [action.payload.key]: action.payload.value
      }
    },
    resetQuestionCode: (state, action) => {
      state.questionCode = {
        ...state.questionCode,
        [action.payload.key]: state.defaultCode[action.payload.key]
      }
    },
    setEditorLoading: (state, action) => {
      state.editorLoading = action.payload
    },
    setEditorSettings: (state, action) => {
      const { fontSize, keyBinding, tabSize, wordWrap } = action?.payload;

      state.fontSize = fontSize !== undefined ? fontSize : state?.fontSize;
      state.keyBinding = keyBinding !== undefined ? keyBinding : state?.keyBinding;
      state.tabSize = tabSize !== undefined ? tabSize : state?.tabSize;
      state.wordWrap = wordWrap !== undefined ? wordWrap : state?.wordWrap;
    },
    resetEditorSettings: (state, action) => {
      state.fontSize = initialState.fontSize;
      state.keyBinding = initialState.keyBinding;
      state.tabSize = initialState.tabSize;
      state.wordWrap = initialState.wordWrap;
    }
  },
});

export const {
  setSelectedLanguage,
  addQuestionCode,
  updateQuestionCode,
  resetQuestionCode,
  setDefaultCodes,
  setEditorLoading,
  setEditorSettings,
  resetEditorSettings
} = editorSlice.actions;

export default editorSlice.reducer;
