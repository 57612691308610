import { Box } from '@mui/material';
import Split from 'react-split';
import { useAuth } from '../../common/context/AuthContext';
import ProblemSection from '../components/ProblemSection';
import RightPanelSection from '../components/sections/rightPanelSection';

import CBLoader from '../../common/components/CBLoader';
import { useSelector } from 'react-redux';
import { setNewQuestion } from '../redux/createProblem';

const CreateProblemPage = () => {
    const { newQuestion, newQuestionMetaData } = useSelector(state => state.createProblem)

    const { currentUser, loading } = useAuth();


    return (
        <div>
            {(loading && newQuestion) ? <CBLoader /> :
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Split minSize={300} style={{ display: 'flex', flexDirection: 'row', backgroundColor: '#1a1a1a' }} sizes={[30, 70]} gutterSize={8}>
                        <ProblemSection selectedQuestion={newQuestion} />
                        <RightPanelSection currentUser={currentUser} selectedQuestionMetaData={newQuestionMetaData} selectedQuestion={newQuestion} setSelectedQuestion={setNewQuestion}/>
                    </Split>
                </Box>
            }
        </div>
    )
}

export default CreateProblemPage;

