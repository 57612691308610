import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CONTEST_STATUS, MESSAGE_ACTION } from '.././constants';
import { setContestEnded, setContestStarted, setContestStarting } from '../redux/contest';
import { setEndTime } from '../redux/countdown';
import { addMessage, setMessages } from '../redux/lobbyMessage';
import { addParticipant, updateParticipantStatus } from '../redux/participant';
import { setQuestions } from '../redux/question';
import { WebSocketContext } from './websocket';
import { processAddMessage, processLoadMessage } from '../utils/lobbyUtils';

function WebsocketInit({ contest_id, currentUser }) {
    const { subscribe, connected } = useContext(WebSocketContext);
    const dispatch = useDispatch();

    useEffect(() => {
        if (connected) {
            // Subscribe to a destination when the component mounts
            subscribe('/topic/' + contest_id, handleWebSocketMessage);
        }
        return () => {
        };
    }, [connected]);

    const handleWebSocketMessage = (message) => {
        console.log("WebSocketContext WebsocketInit  handleWebSocketMessage : ", message);
        if (message) {
            const messageBody = message
            switch (messageBody.action) {
                case MESSAGE_ACTION.CONTEST_CREATED:
                case MESSAGE_ACTION.SUBMISSION_ACCEPTED:
                case MESSAGE_ACTION.SUBMITTED:
                    dispatch(addMessage(messageBody?.lobbyMessage))
                    break;
                case MESSAGE_ACTION.LOAD_MESSAGES:
                    dispatch(setMessages(processLoadMessage(messageBody?.payload)))
                    break;
                case MESSAGE_ACTION.ADD_PARTICIPANT:
                    dispatch(addMessage(messageBody?.lobbyMessage))
                    dispatch(addParticipant(messageBody.payload))
                    break;
                case MESSAGE_ACTION.UPDATE_PARTICIPANT:
                    dispatch(addMessage(messageBody?.lobbyMessage))
                    dispatch(updateParticipantStatus(messageBody.payload))
                    break;
                case MESSAGE_ACTION.CONTEST_STARTING:
                    dispatch(addMessage(messageBody?.lobbyMessage))
                    dispatch(setContestStarting(messageBody.payload))
                    dispatch(setEndTime(Date.now() + 120000))
                    break;
                case MESSAGE_ACTION.CONTEST_STARTED:
                    dispatch(addMessage(messageBody?.lobbyMessage))
                    dispatch(setContestStarted(messageBody?.payload))
                    dispatch(setEndTime(Date.now() + (messageBody?.payload?.timeLeft || 1800000)))
                    dispatch(setQuestions(messageBody.payload.questions))
                    break;
                case MESSAGE_ACTION.CONTEST_ENDED:
                    dispatch(addMessage(messageBody?.lobbyMessage))
                    dispatch(setContestEnded(CONTEST_STATUS.ENDED))
                    break;
                case MESSAGE_ACTION.NEW_MESSAGE:
                    const lobbyMessage = processAddMessage(messageBody?.lobbyMessage,currentUser)
                    if (lobbyMessage) dispatch(addMessage(lobbyMessage))
                    break;
                default:
                    break;
            }
        } else {
            console.log("WebsocketInit  handleWebSocketMessage : message body not found");
        }
    }

    return (
        <></>
    )
}

export default WebsocketInit
