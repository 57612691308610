import React, { useEffect } from 'react';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { clearError } from '../../cbApp/redux/error';
import { useNavigate } from 'react-router-dom';

const Notification = () => {
    const dispatch = useDispatch();
    const error = useSelector((state) => state.error);
    const navigate = useNavigate();

    useEffect(() => {
        console.log("I am logging error");
        console.log(error);
        if (error?.message) {
            if (error.shouldRedirect) {
                navigate("/error", { replace: true });
            } else {
                toast.error(error.message);
                dispatch(clearError());
            }
        }
    }, [error, dispatch, navigate]);

    return (
        <>
            <ToastContainer
                position="bottom-left"
                theme="dark"
            />
        </>
    );
}

export default Notification;
