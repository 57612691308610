import SendIcon from '@mui/icons-material/Send';
import { Box, Card, IconButton, TextField, Typography } from '@mui/material';
import { useContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { getCurrentTime, updateTimeZone } from '../../../../utils';
import ChatMsg from '../../common/ChatMsg';
// import {useMessaging} from '../../../websocket/useMessaging';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import { useDispatch } from 'react-redux';
import { addMessage } from '../../../redux/lobbyMessage';
import { WebSocketContext } from '../../../websocket/websocket';
import EomjiPicker from '../../common/EomjiPicker';
import CloseIcon from '@mui/icons-material/Close';
import ChatBoxRow from './chatBoxRow';
import { VariableSizeList as List } from 'react-window';
import AutoSizer from "react-virtualized-auto-sizer";

function ChatBox({ currentUser, contestID }) {
    const messages = useSelector(state => state.lobbyMessage.messages)
    const [userChatMessage, setUserChatMessage] = useState("");
    const [showEmojiPicker, setShowEmojiPicker] = useState(false)
    const { sendMessage, connected } = useContext(WebSocketContext);
    const dispatch = useDispatch()
    const chatBoxRef = useRef(null);
    const textFieldRef = useRef(null);
    const rowHeights = useRef({});
    const listRef = useRef({});
    const bottomRef = useRef(null);

    useEffect(() => {
        listRef?.current?.scrollToItem?.(messages?.length - 1, "end");
    }, [messages, showEmojiPicker])
    

    const handleOnChangeValue = (val) => {
        setUserChatMessage(val);
    }

    const handleSendMessage = () => {
        if (userChatMessage.trim().length > 0) {
            // Perform the send message action here
            const webSocketMessage = {
                author: currentUser?.uid,
                message: userChatMessage,
                time: getCurrentTime()
            };
            console.log('Sending message:', webSocketMessage);
            dispatch(addMessage(webSocketMessage))
            sendMessage('/app/contest/' + contestID, webSocketMessage)
            setUserChatMessage('');
            setShowEmojiPicker(false);
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            handleSendMessage();
        }
    };

    const handleShowEmojiPicker = () => {
        setShowEmojiPicker(!showEmojiPicker)
    }

    const getRowHeight = (index) => {
        return rowHeights.current[index] + 12 || 82;
    }

    const setRowHeight = (index, size) => {
        listRef?.current?.resetAfterIndex(0);
        rowHeights.current = { ...rowHeights.current, [index]: size };
    }


    return (
        <Card sx={{flex:1, display: 'flex', flexDirection: 'column', justifyContent:'end' }}>
            {/* <Box sx={{backgroundColor:'red', height:'100%'}}> */}
            <AutoSizer style={{ height: '100%', width: '100%' }}>
                {({ height, width }) => (
                    <List
                        height={height - 64} // Adjust the height as needed
                        width={width}
                        itemSize={getRowHeight} // Adjust the itemSize as needed
                        itemCount={messages.length}
                        ref={listRef}
                    >
                        {({ index, style }) => <ChatBoxRow message={messages[index]} index={index} userID={currentUser?.uid} style={style} setRowHeight={setRowHeight} />}
                    </List>
                )}
            </AutoSizer>
            {/* </Box> */}
            <div ref={chatBoxRef} />
            <Box ref={bottomRef} sx={{p: 1}}>
                {showEmojiPicker &&
                    <EomjiPicker setUserChatMessage={setUserChatMessage} userChatMessage={userChatMessage} textFieldRef={textFieldRef} />
                }
                <TextField
                    maxRows={6}
                    inputRef={textFieldRef}
                    multiline
                    value={userChatMessage}
                    onChange={(e) => handleOnChangeValue(e?.target?.value)}
                    onKeyDown={handleKeyDown}
                    placeholder="Type a message"
                    sx={{ bgcolor: '#373737', borderRadius: 2,width:'100%' }}
                    inputProps={{
                        maxLength: 256
                    }}
                    InputProps={{
                        sx: {
                            typography: 'body2',
                            px: 0,
                            py: 0.5,
                            borderRadius: 2,
                        },
                        startAdornment: (
                            <IconButton
                                aria-label="send"
                                onClick={handleShowEmojiPicker}
                                sx={{ color: (!showEmojiPicker && '#8a8a8a') }}
                            >
                                {showEmojiPicker ? <CloseIcon /> : <InsertEmoticonIcon />}
                            </IconButton>
                        ),
                        endAdornment: (
                            <IconButton
                                aria-label="send"
                                onClick={handleSendMessage}
                                disabled={userChatMessage.trim().length === 0}
                            >
                                <SendIcon />
                            </IconButton>
                        ),
                    }}
                />
            </Box>
        </Card>
    )
}

export default ChatBox
