import { Box, Typography } from '@mui/material';
import React, { useState } from 'react'
import Split from 'react-split';
import CBTabPanel from '../../../../common/components/CBTabPanel';
import CBEditor from '../../../../common/components/CBEditor';
// import CBEditor from '../../../../common/components/CBEditor';

interface Metadata {
    lang:String;
    mode: string;
    userCode: string;
    defaultCode: string;
}

interface MetadataEditorsProps {
    onDefaultCodeChange: (value : string,index : number) => void;
    onUserCodeChange: (value : string,index : number) => void;
    metadata: Metadata;
    index:number;
}

const MetadataEditors : React.FC<MetadataEditorsProps> = ({onDefaultCodeChange,onUserCodeChange,metadata,index}) => {

    const [sizes, setSizes] = useState<number[]>([50,50]);
    
    const handleDrag = (sizes : number[]) => {
        setSizes(sizes);
    }
                    
    return (
        <CBTabPanel sx={{ height: 'calc(100vh - 150px)' }}>
            <Split minSize={100} direction="vertical" style={{ height: '100%' }} sizes={sizes} onDrag={handleDrag} gutterSize={8}>
                <Box sx={{ height: `${sizes[0]}%`, width: '100%', display: 'flex', flexDirection: 'column' }}>
                    <Typography variant="h6" fontSize='1.125rem' lineHeight='1.75rem' fontWeight={600}>1. Default code</Typography>
                    <Box sx={{m:1,p:0.2,bgcolor:'#1a1a1a'}}></Box>
                    <CBEditor name='lokesh' onChange={(e) => onDefaultCodeChange(e,index)} value={metadata.defaultCode} mode={metadata.mode}/>
                </Box>
                <Box sx={{ mt: 1, height: `${sizes[1]}%`, width: '100%', display: 'flex', flexDirection: 'column' }}>
                    <Typography variant="h6" fontSize='1.125rem' lineHeight='1.75rem' fontWeight={600}>2. User code</Typography>
                    <Box sx={{m:1,p:0.2,bgcolor:'#1a1a1a'}}></Box>
                    <CBEditor name='kedia' onChange={(e) => onUserCodeChange(e,index)} value={metadata.userCode} mode={metadata.mode}/>  
                </Box>
            </Split>
        </CBTabPanel>
    )
}

export default MetadataEditors