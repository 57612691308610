import React, { useEffect, useState } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TableSortLabel,
  Skeleton, Box, Button, Select, MenuItem, Typography
} from '@mui/material';
import { ReactComponent as DefaultSortIcon } from "../assets/icon_sort.svg";
import { ReactComponent as UpArrowIcon } from "../assets/icon_up_arrow.svg";
import { ReactComponent as DownArrowIcon } from "../assets/icon_down_arrow.svg";
import CBCenterInfo from './CBCenterInfo';
import CBMenuButton from './CBMenuButton';
import CBButton from './CBButton';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

const CBTable = ({ headers, data, sx, loading, noDataMessage }) => {
  const [tableData, setTableData] = useState(data);
  const [sortConfig, setSortConfig] = useState({ key: '', direction: 'default' });

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);

  const rowsPerPageOptions = [
    { label: "20 / page", value: 20 },
    { label: "50 / page", value: 50 },
    { label: "100 / page", value: 100 }
  ]
  const [rowsPerPageOption, setRowsPerPageOption] = useState(rowsPerPageOptions[0]); // Default rows per page
  const rowsPerPage = rowsPerPageOption.value
  

  useEffect(() => {
    setTableData(data);
  }, [data]);

  const handleSort = (columnKey) => {
    let direction = 'asc';
    if (sortConfig.key === columnKey && sortConfig.direction === 'asc') {
      direction = 'desc';
    } else if (sortConfig.key === columnKey && sortConfig.direction === 'desc') {
      direction = 'default';
    }

    const sortedData = [...data];
    if (direction !== 'default') {
      sortedData.sort((a, b) => {
        const aValue = a[columnKey]?.value ?? a[columnKey];
        const bValue = b[columnKey]?.value ?? b[columnKey];
        if (aValue < bValue) return direction === 'asc' ? -1 : 1;
        if (aValue > bValue) return direction === 'asc' ? 1 : -1;
        return 0;
      });
    }

    setSortConfig({ key: columnKey, direction });
    setTableData(direction === 'default' ? data : sortedData);
  };

  const getSortIcon = (direction) => {
    switch (direction) {
      case 'asc':
        return <UpArrowIcon fontSize="small" />;
      case 'desc':
        return <DownArrowIcon fontSize="small" />;
      default:
        return <DefaultSortIcon fontSize="small" />;
    }
  };

  // Pagination handlers
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleRowsPerPageChange = (e) => {
    setRowsPerPageOption(e);
    setCurrentPage(1); // Reset to first page on rows change
  };

  // Calculate visible rows
  const startIndex = (currentPage - 1) * rowsPerPage;
  const visibleRows = tableData.slice(startIndex, startIndex + rowsPerPage);
  const totalPages = Math.ceil(tableData.length / rowsPerPage);

  return (
    <>
      <Table sx={{ "& .MuiTableCell-root": { border: "none" }, "& .MuiTableCell-head": { py: 1, px: 2 }, "& .MuiTableCell-body": { py: 1, px: 2 }, ...sx }}>
        <TableHead>
          <TableRow>
            {headers.map((header, index) => (
              <TableCell sx={{ color: "#eff2f699" }} key={index}>
                {header.sortable ? (
                  <TableSortLabel
                    active={sortConfig.key === header.key}
                    direction={sortConfig.direction === 'default' ? 'asc' : sortConfig.direction}
                    IconComponent={() => getSortIcon(sortConfig.key === header.key ? sortConfig.direction : 'default')}
                    onClick={() => handleSort(header.key)}
                  >
                    {header.label}
                  </TableSortLabel>
                ) : (
                  header.label
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? (
            <Loading headers={headers} />
          ) : visibleRows.length === 0 ? (
            <TableRow>
              <TableCell colSpan={headers.length}>
                <CBCenterInfo sx={{ mt: 10 }} info={noDataMessage} />
              </TableCell>
            </TableRow>
          ) : (
            visibleRows.map((row, rowIndex) => (
              <TableRow key={rowIndex} sx={{ bgcolor: rowIndex % 2 === 0 ? "#282828" : "inherit" }}>
                {headers.map((header, cellIndex) => (
                  <TableCell key={cellIndex}>
                    {typeof row[header.key] === 'object' && row[header.key]?.render
                      ? row[header.key].render
                      : row[header.key]}
                  </TableCell>
                ))}
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>

      {/* Pagination Controls */}
      <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mt: 2 }}>
          <CBMenuButton options={rowsPerPageOptions}  selectedOption= {rowsPerPageOption} setSelectedOption = {handleRowsPerPageChange} variant='contained' color="third"/>
          <Box display="flex" alignItems="center">
            <CBButton
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              color='secondary'
            >
              <ChevronLeftIcon fontSize='small'/>
            </CBButton>
            <Typography sx={{ mx: 2 }}>
              Page {currentPage} of {totalPages}
            </Typography>
            <CBButton
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              color='secondary' 
            >
              <ChevronRightIcon fontSize='small'/>
            </CBButton>
          </Box>
      </Box>
    </>
  );
};

const Loading = ({ headers }) => {
  const skeletonArray = Array.from({ length: 12 }, (_, index) => (
    <TableRow key={index}>
      {headers.map((header, cellIndex) => (
        <TableCell key={cellIndex}>
          <Skeleton variant="rounded" height="24px" width="100%" sx={{ mt: 1, ml: 1 }} />
        </TableCell>
      ))}
    </TableRow>
  ));

  return <>{skeletonArray}</>;
};

export default CBTable;
