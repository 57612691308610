import { Box, BoxProps, Typography } from '@mui/material';
import { FC } from 'react';

interface CBCenterInfoProps extends BoxProps {
    info: String
}

const CBCenterInfo : FC<CBCenterInfoProps> = ({info,sx}) => {
    return (
        <Box sx={{ overflow: "auto", height: '100%', width:'100%', display: 'flex', justifyContent: 'center', alignItems: 'center',...sx }}>
            <Typography variant='caption' color={'text.secondary'}>{info}</Typography>
        </Box>
    )
}

export default CBCenterInfo