import {useRef,useEffect} from 'react'
import { Box, Typography } from '@mui/material';
import { updateTimeZone } from '../../../../utils';
import ChatMsg from '../../common/ChatMsg';

function ChatBoxRow({ message, index, userID, style, setRowHeight }) {

    const rowRef = useRef({});

    useEffect(() => {
        if (rowRef.current) {
          setRowHeight(index, rowRef?.current?.clientHeight);
        }
        // eslint-disable-next-line
      }, [rowRef]);

    return (<div style={style}>
        <Box ref={rowRef} sx={{px:1, paddingTop:(index == 0 && 1)}}>
        {message?.author === "system" ? (
            <Box key={index} sx={{ width: "100%", bgcolor: '#373737', borderRadius: 2, px: 1, py: 1, mb: 1.5, display: 'flex', justifyContent: 'space-between', color: "text.secondary" }}>
                <Typography variant="body2">{message?.message}</Typography>
                <Typography variant="caption" sx={{ ml: 0.5 }}>{updateTimeZone(message?.time)}</Typography>
            </Box>
        ) : message?.author === userID ? (
            <ChatMsg
                side={'right'}
                messages={[message]}
            />
        ) : (
            <ChatMsg
                avatar={message?.user?.photoURL || ''}
                author={message?.user?.displayName || message?.author}
                messages={[message]}
                color={message?.user?.color || 'white'}
            />
        )}
        </Box>
    </div>)
}

export default ChatBoxRow