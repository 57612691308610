import { Box, Grid, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CBButton from '../components/CBButton';
import { store } from '../redux/store';
import { clearError } from '../../cbApp/redux/error';
import { useEffect } from 'react';

function ErrorPage() {

    const error = useSelector(state => state.error)
    const navigate = useNavigate();

    const handleNavigateClick = () => {
        store.dispatch(clearError())
    }

    useEffect(() => {
      if(!error?.message){
        navigate('/', { replace: true })
      }
    }, [error])
    

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                minHeight: '100vh',
                backgroundColor: 'secondary',
            }}
        >
            <Typography variant="h2" style={{ color: 'white' }}>
                {"OOPS!! " + error?.httpStatus}
            </Typography>
            <Typography variant="h6" style={{ color: 'white', mt:3 }}>
                {error?.message ? error.message : "Something went wrong!"}
            </Typography>
            <CBButton sx={{mt:1}} onClick={handleNavigateClick}>Back Home</CBButton>
        </Box>
    )
}

export default ErrorPage;