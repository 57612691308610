import { Box, List, ListItem, ListItemButton, ListItemText, SxProps, Theme } from '@mui/material';
import React from 'react';

interface CBListTabsProps {
    items: { text: string; icon: React.ReactNode; onClick: () => void; selected: boolean }[];
    sx?: SxProps<Theme>; // Allow custom styling for the list
    buttonSx?: SxProps<Theme>; // Allow custom styling for the list buttons
}

const CBListTabs: React.FC<CBListTabsProps> = ({ items, sx, buttonSx }) => {
    return (
        <List sx={{ bgcolor: '#1a1a1a', ...sx }}>
            {items.map((item, index) => (
                <ListItem disablePadding key={index}>
                    <ListItemButton
                        onClick={item.onClick}
                        selected={item.selected}
                        sx={{ borderRadius: 2, ...buttonSx }} // Rounded corners and additional styling
                    >
                        {item.icon}
                        <ListItemText primary={item.text} />
                    </ListItemButton>
                </ListItem>
            ))}
        </List>
    );
};

export default CBListTabs;
