import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Split from "react-split";
import CBLoader from "../../common/components/CBLoader";
import { store } from "../../common/redux/store";
import { getProblemByID, getProblemMetadataById, getPublishedProblems } from "../apis/problemApis";
import ProblemContentSection from "../components/sections/contentSections/problemContentSection";
import PracticeEditorSection from "../components/sections/editorSections/practiceEditorSection";
import { setError } from "../redux/error";

const ProblemDescriptionPage = () => {
	const { slugProblemIdMap } = useSelector((state) => state.publishedProblems);
	const { problem_slug } = useParams();
	const navigate = useNavigate();

	useEffect(() => {
		console.log("ProbDesc useeffect")
		if (Object.keys(slugProblemIdMap).length !== 0) {
			if (slugProblemIdMap[problem_slug]) {
				getProblemByID(slugProblemIdMap[problem_slug]);
				getProblemMetadataById(slugProblemIdMap[problem_slug])
			} else {
				store.dispatch(setError({ message: "Problem Not Found", shouldRedirect: true, httpStatus: 404 }))
			}
		} else {
			getPublishedProblems();
		}
	}, [slugProblemIdMap, problem_slug]);

	return (
		<div>
			{(!slugProblemIdMap || !slugProblemIdMap[problem_slug]) ? (
				<CBLoader />
			) : (
				<div>
					<Split
						minSize={300}
						style={{
							display: "flex",
							flexDirection: "row",
							backgroundColor: "#1a1a1a",
						}}
						sizes={[35, 65]}
						gutterSize={8}
					>
						<ProblemContentSection />
						<PracticeEditorSection problemId={slugProblemIdMap[problem_slug]} />
					</Split>
				</div>
			)}
		</div>
	);
};

export default ProblemDescriptionPage;
