/* global google */

import React, { useContext, useState, useEffect } from "react"
import { auth, firebaseui, uiConfig } from "../../firebase"
import { GoogleAuthProvider, signInWithCredential } from "firebase/auth";
import { useDispatch, useSelector } from 'react-redux';
import { setUserSignedOut, setShowLoginPopup } from '../redux/login';
import axios from 'axios'
import { saveUser } from "../apis/userApis"

const AuthContext = React.createContext()

export function useAuth() {
    return useContext(AuthContext)
}

export function AuthProvider({ children }) {
    const [currentUser, setCurrentUser] = useState()
    const [loading, setLoading] = useState(true)
    const { isUserSignedOut } = useSelector(state => state.login);
    const dispatch = useDispatch();

    const logout = () => {
        console.log("LOGGING OUT");
        localStorage.removeItem('access_token');
        dispatch(setUserSignedOut(true))
        auth.signOut()
        window.location.reload()
    }

    
// One Tap Sign-In configuration
const oneTapSignIn = () =>  {
    console.log("oneTapSignIn");
    google.accounts.id.initialize({
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      callback: handleCredentialResponse,
      auto_select: true,
      context: "use",
      native_callback: nativeCallback,
    });
    google.accounts.id.prompt(); // Displays the One Tap Sign-In prompt
  };
  
  const handleCredentialResponse = response => {
    console.log('oneTapSignIn handleCredentialResponse : ', response);
    const credential = signInWithCredential(auth, GoogleAuthProvider.credential(response.credential))
      .then((result) => {
        console.log(result);
        const {user} = result;
  
        console.log("oneTapSignIn handleCredentialResponse : ",user.accessToken); // firebase JWT token
        window.location.reload()
      })
      .catch((error) => {
        console.error("firebase error", error);
      });
  };
  
  const nativeCallback = (obj) => console.log("native_callback!");

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            setLoading(false)
            if (user) {
                console.log("User logged in : ", user);
                console.log("Setting CurrentUser");
                setCurrentUser(user)
                localStorage.setItem('access_token', user?.accessToken)
                axios.defaults.headers.common['Authorization'] = `Bearer ${user?.accessToken}`;
                console.log("saving user in AuthContest.js with : ",user);
                saveUser(user)
            } else {
                console.log("User not logged in");
                localStorage.removeItem('access_token')
                if(!isUserSignedOut)
                    oneTapSignIn()
                // const ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(auth);
                // ui.start('#autoSignIn', uiConfig);
                // ui.disableAutoSignIn();
            }
        })

        return unsubscribe
    },[isUserSignedOut])

    const value = {
        loading,
        currentUser,
        logout,
        oneTapSignIn
    }

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    )
}
