import { Box } from '@mui/material'
import React from 'react'

function NoConsoleOutput() {
    return (
        <Box sx={{display:'flex', justifyContent:'center', alignItems:'center', color:'#8a8a8a'}}>
            You must run your code first
        </Box>
    )
}

export default NoConsoleOutput
