import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import { init } from 'emoji-mart'

init({ data })

function EomjiPicker({setUserChatMessage,userChatMessage,textFieldRef}) {

    const handleOnEmojiSelect = (val) => {
        console.log("handleOnEmojiSelect : ",val);
        setUserChatMessage(userChatMessage+val.native)
        textFieldRef.current.focus();
    }

    return (
        <div> 
            <Picker
            className="emoji-picker"
            onEmojiSelect={(val) => handleOnEmojiSelect(val)}
            noCountryFlags={true}
            previewPosition='none'
            skinTonePosition='none'
            dynamicWidth={true}
        />
        </div>
    )
}

export default EomjiPicker