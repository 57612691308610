import CBCurvedTabSection from '../../../../common/components/CBCurvedTabSection';
import CBWIP from '../../../../common/components/CBWIP';
import ProblemTabPanel from './problemTabPanel';


function ProblemContentSection() {

    const tabs = [
        { key: 'Problems', node: <ProblemTabPanel /> },
        { key: 'Submissions', node: <CBWIP /> },
        { key: 'Solutions', node: <CBWIP /> }]

    return (
        <CBCurvedTabSection tabs={tabs} initalTabIndex={0} />
    )
}

export default ProblemContentSection
