import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    leaderboardUsers: [],
}

export const leaderboardSlice = createSlice({
    name: 'leaderboard',
    initialState,
    reducers: {
        setLeaderboardUsers: (state, action) => {
            console.log("Leaderboard action: ",action);
            if(!action?.payload) return;
            state.leaderboardUsers = [...action?.payload]
        }
    },
})

export const {setLeaderboardUsers} = leaderboardSlice.actions

export default leaderboardSlice.reducer