import { Typography, TypographyProps } from '@mui/material'
import { FC } from 'react'

interface LinkTypographyProps extends TypographyProps {}

const LinkTypography : FC<LinkTypographyProps> = ({ children,sx,...props }) => {
    return (
        <Typography
            sx={{
                cursor: 'pointer',
                "&:hover": {
                    color:'text.primary',
                    fontWeight:500
                },
                ...sx
            }}
            variant="body2"
            {...props}
        >
            {children}
        </Typography>
    )
}

export default LinkTypography 