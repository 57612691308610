import BoltIcon from '@mui/icons-material/Bolt';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import { Box, Card, Skeleton, Stack, Typography } from "@mui/material";
import { ReactComponent as ScoreIcon } from '../../../assets/score_profile.svg';

const UserMetaDataLoading = () => {

    return (
        <Box sx={{ display: 'flex', flexDirection: { lg: 'row', md: 'column', sm: 'column', xs: 'column' }, width: '100%' }}>
            <Card sx={{ borderRadius: '8px', display: 'flex', flexDirection: { lg: 'row', md: 'row', sm: 'row', xs: 'column' }, p: 2, width: '100%' }}>
                <Stack direction='column' sx={{ mr: 1 }}>
                    <Skeleton variant="rounded" height='80px' width='80px' />
                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </Stack>
                <Stack direction='row' sx={{ mt: { lg: 0, md: 0, sm: 0, xs: 2 }, ml: { lg: 2, md: 2, sm: 0, xs: 0 } }} flex={1}>
                    <Stack direction='column' flex={1} sx={{ mr: 1 }}>
                        <Card sx={{ bgcolor: '#3e3e3e', flex: 1, mb: 1, direction: 'row', display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 2 }}>
                            <Stack direction='column' alignItems='start' justifyContent='center' sx={{ height: '100%' }}>
                                <Skeleton variant="text" width={'80%'} sx={{ fontSize: '1rem' }} />
                                <Typography fontSize='16px' fontWeight='100' color='#eff2f699' sx={{ mt: 0.5 }}>Rank</Typography>
                            </Stack>

                            <MilitaryTechIcon sx={{ color: '#282828', height: '40px', width: '40px' }} />
                        </Card>

                        <Card sx={{ bgcolor: '#3e3e3e', flex: 1, mt: 1, direction: 'row', display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 2 }}>
                            <Stack direction='column' alignItems='start' justifyContent='center' sx={{ height: '100%' }}>
                                <Skeleton variant="text" width={'80%'} sx={{ fontSize: '1rem' }} />
                                <Typography fontSize='16px' fontWeight='100' color='#eff2f699' sx={{ mt: 0.5 }}>Streak</Typography>
                            </Stack>

                            <BoltIcon sx={{ color: '#282828', height: '40px', width: '40px' }} />
                        </Card>
                    </Stack>

                    <Stack direction='column' flex={1} sx={{ ml: 1 }}>

                        <Card sx={{ bgcolor: '#3e3e3e', flex: 1, mb: 1, direction: 'row', display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 2 }}>
                            <Stack direction='column' alignItems='start' justifyContent='center' sx={{ height: '100%' }}>
                                <Skeleton variant="text" width={'80%'} sx={{ fontSize: '1rem' }} />
                                <Typography fontSize='16px' fontWeight='100' color='#eff2f699' sx={{ mt: 0.5 }}>Score</Typography>
                            </Stack>

                            <ScoreIcon height='40px' width='40px' />
                        </Card>

                        <Card sx={{ bgcolor: '#3e3e3e', flex: 1, mt: 1, direction: 'row', display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 2 }}>
                            <Stack direction='column' alignItems='start' justifyContent='center' sx={{ height: '100%' }}>
                                <Skeleton variant="text" width={'80%'} sx={{ fontSize: '1rem' }} />
                                <Typography fontSize='16px' fontWeight='100' color='#eff2f699' sx={{ mt: 0.5 }}>Max Streak</Typography>
                            </Stack>

                            <LocalFireDepartmentIcon sx={{ color: '#282828', height: '40px', width: '40px' }} />
                        </Card>
                    </Stack>
                </Stack>
            </Card>

            <Box sx={{ p: 1 }} />

            <Card sx={{ borderRadius: '8px', display: 'flex', flexDirection: 'row', p: 2, width: '100%' }}>
                <Box flex={1}>
                    <Typography fontSize='0.75rem' color='#eff2f699'>Solved Problems</Typography>

                    <Stack>
                        <Stack direction='row' sx={{ mt: 2 }} justifyContent='space-between' alignItems={'end'}>
                            <Typography fontSize='0.75rem' color='#eff2f699'>Easy</Typography>
                        </Stack>
                        <Skeleton height={14} width={'100%'} />

                        <Stack direction='row' sx={{ mt: 2 }} justifyContent='space-between' alignItems={'end'}>
                            <Typography fontSize='0.75rem' color='#eff2f699'>Medium</Typography>
                        </Stack>

                        <Skeleton height={14} width={'100%'} />

                        <Stack direction='row' sx={{ mt: 2 }} justifyContent='space-between' alignItems={'end'}>
                            <Typography fontSize='0.75rem' color='#eff2f699'>HARD</Typography>
                        </Stack>

                        <Skeleton height={14} width={'100%'} />

                    </Stack>
                </Box>

            </Card>
        </Box>
    )
}


export default UserMetaDataLoading