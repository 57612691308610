import { Tab, Tabs, Typography } from '@mui/material';
import React from 'react'
import PropTypes from 'prop-types';

function CBTabs({ value, onChange, tabs, children }) {
    return (
        <Tabs sx={{px:2.5}} value={value} onChange={onChange} TabIndicatorProps={{ sx: { backgroundColor: '#ffffff'}}}>
            {tabs.map((tab, index) => {
                return (
                    <Tab sx={{
                        textTransform: 'none',
                        '&.Mui-selected': {
                            color: '#ffffff',
                        },
                    }} key={index} label={tab} />
                );
            })}
            {children}
        </Tabs>
    )
}

CBTabs.propTypes = {
    value: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    tabs: PropTypes.arrayOf(PropTypes.string).isRequired,
    children: PropTypes.node
}

export default CBTabs