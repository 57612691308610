import Logout from "@mui/icons-material/Logout";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import { Box, IconButton, Typography } from "@mui/material";
import { useState } from "react";
import LinkAvatar from "../../cbApp/components/common/LinkAvatar";
import { ReactComponent as DiscordIcon } from "../../common/assets/discord_icon.svg";
import { ReactComponent as LinkedInIcon } from "../../common/assets/ic_linkedin.svg";
import CBButton from "../components/CBButton";
import CBMenu from "../components/CBMenu";
import { useAuth } from "../context/AuthContext";

export const PATH_ROOMS = "/rooms";
export const PATH_PROBLEMS = "/problems";
export const PATH_LEADERBOARD = "/leaderboard";
export const PATH_ABOUT = "/about";
export const PATH_PROFILE = "/profile/";
export const PATH_EDITOR = "/editor/";
export const PATH_PRACTICE_ROOM = "/practice/room/";
export const PATH_LINKEDIN = "https://in.linkedin.com/company/codebattle";
export const PATH_DISCORD = process.env.REACT_APP_DISCORD_URL;
export const PATH_FEEDBACK = process.env.REACT_APP_FEEDBACK_URL;
export const PATH_JOIN_US = process.env.REACT_APP_JOIN_US_URL;

export const OPTION_ROUTES = [
  {
    name: "Active Rooms 🏆",
    path: PATH_ROOMS,
    color: "white",
    external: false,
  },
  { name: "Problems 📜", path: PATH_PROBLEMS, color: "white", external: false },
  {
    name: "Leaderboard 🎯",
    path: PATH_LEADERBOARD,
    color: "white",
    external: false,
  },
  { name: "About Us ℹ️", path: PATH_ABOUT, color: "white", external: false },
];

export const DRAWER_OPTION_ROUTES = [
  ...OPTION_ROUTES,
  {
    name: "Feedback 📝",
    path: PATH_FEEDBACK,
    color: "white",
    external: true,
  },
  {
    name: "Join Us 📋",
    path: PATH_JOIN_US,
    color: "#11a0a0",
    external: true,
  },
];

export const SOCIAL_OPTION_ROUTES = [
  {
    name: <LinkedInIcon />,
    path: PATH_LINKEDIN,
    color: "#11a0a0",
    external: true,
  },
  {
    name: <DiscordIcon />,
    path: PATH_DISCORD,
    color: "#11a0a0",
    external: true,
  },
];

export const handleOnRouteClick = (path, isExternal = false) => {
  console.log("Path78", path);
  const importantPaths = [PATH_EDITOR, PATH_PRACTICE_ROOM]; // Add more paths as needed
  if (isExternal) {
    window.open(path);
  } else if (
    importantPaths.some((p) => window?.location?.pathname.startsWith(p))
  ) {
    window.open(path);
  } else {
    window.location.pathname = path;
  }
};

const settingOptions = [
  {
    value: "profile",
    label: "Profile",
    startIcon: <PersonOutlineIcon fontSize="medium" />,
  },
  {
    value: "signOut",
    label: "Sign Out",
    startIcon: <Logout fontSize="small" />,
  },
];

export const NavLogin = ({ currentUser, onLoginClick, isMobile }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedSetting, setSelectedSetting] = useState(settingOptions[0]);

  const { logout } = useAuth();

  const onHandleProfileClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleSelectSetting = (option) => {
    switch (option.value) {
      case settingOptions[0].value:
        setSelectedSetting(option);
        handleOnRouteClick(`${PATH_PROFILE}${currentUser?.uid}`, false);
        break;
      case settingOptions[1].value:
        setSelectedSetting(option);
        logout();
        break;
      default:
        break;
    }
    handleCloseMenu();
  };

  return (
    <>
      {currentUser ? (
        <Box style={{ minWidth: "67px" }}>
           {isMobile ? (
              <CBButton
                  color="inherit"
                  variant="text"
                  sx={{ display: "flex", alignItems: "center" }}
                  onClick={(e) => onHandleProfileClick(e)}
              >
                  <LinkAvatar
                      sx={{ width: 32, height: 32 }}
                      alt={currentUser?.displayName}
                      src={currentUser?.photoURL}
                  />
                  {isMobile && (
                      <Typography sx={{ ml: 1 }}>
                          {currentUser?.displayName}
                      </Typography>
                  )}
              </CBButton>
          ) : (
              <IconButton
                  color="inherit"
                  variant="text"
                  sx={{ display: "flex", alignItems: "center" }}
                  onClick={(e) => onHandleProfileClick(e)}
              >
                  <LinkAvatar
                      sx={{ width: 32, height: 32 }}
                      alt={currentUser?.displayName}
                      src={currentUser?.photoURL}
                  />
              </IconButton>
          )}

          <CBMenu
            target={anchorEl}
            onCloseMenu={handleCloseMenu}
            onItemSelect={handleSelectSetting}
            options={settingOptions}
            checkIcon
            selectedOption={selectedSetting}
          />
        </Box>
      ) : (
        <Box>
          <Typography
            component="button"
            sx={{
              ml: 2,
              border: "none",
              background: "transparent",
            }}
            onClick={onLoginClick}
          >
            Login
          </Typography>
        </Box>
      )}
    </>
  );
};

export const NavButton = ({ title, color, isMobile, isSelected }) => {
  return (
    <CBButton
      color="inherit"
      variant="text"
      textTransform="uppercase"
      sx={{
        ml: isMobile ? 1 : 2,
        color: isSelected ? "inherit" : "#999999",
        minWidth: 0,
        fontWeight: "semibold",
      }}
    >
      {title}
    </CBButton>
  );
};
