import { createSlice } from '@reduxjs/toolkit'

const initialState = {};

export const usersSlice = createSlice({
    name: "users",
    initialState,
    reducers: {
        setUserStatus: (state, action) => {
            if (!state[action.payload.uid]) {
                state[action.payload.uid] = {};
            }
            state[action.payload.uid]["userStatus"] = action.payload.userStatus;
        },
        setUserMetadata: (state, action) => {

            console.log("Profile90 userMetaData",action.payload.userMetaData)
            if (!state[action.payload.uid]) {
                state[action.payload.uid] = {};
            }
            state[action.payload.uid]["userMetaData"] =
                action.payload.userMetaData;
        },
        setUserTimeGraph: (state, action) => {
            if (!state[action.payload.uid]) {
                state[action.payload.uid] = {};
            }
            state[action.payload.uid]["userTimeGraph"] =
                action.payload.userTimeGraph;
        },
        setUserRecentSubmissions: (state, action) => {
            if (!state[action.payload.uid]) {
                state[action.payload.uid] = {};
            }
            state[action.payload.uid]["userRecentSubmissions"] =
                action.payload.userRecentSubmissions;
        },
    },
});

export const getUserMetadataByUid = (state, uid) =>
    state.users[uid]?.userMetaData;
export const getUserTimeGraphByUid = (state, uid) =>
    state.users[uid]?.userTimeGraph;
export const getUserRecentSubmissionsUid = (state, uid) =>
    state.users[uid]?.userRecentSubmissions;

export const {
    setUserStatus,
    setUserMetadata,
    setUserTimeGraph,
    setUserRecentSubmissions,
} = usersSlice.actions;

export default usersSlice.reducer