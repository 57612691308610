import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    topics: [],
    topicsLoading: false
}

export const publishedProblemTopicSlice = createSlice({
    name: 'publishedProblemTopic',
    initialState,
    reducers: {
        setPublishedTopicsLoading: (state, action) => {
            state.topicsLoading = action?.payload
        },
        setPublishedTopics: (state, action) => {
            state.topics = [...(action?.payload ?? [])]
            state.topicsLoading = false
        }
    },
})

export const { setPublishedTopics,setPublishedTopicsLoading} = publishedProblemTopicSlice.actions

export default publishedProblemTopicSlice.reducer