import DoneIcon from '@mui/icons-material/Done';
import { Box, Tooltip } from '@mui/material';
import { useState } from 'react';

export const CopyToolTip = ({children,link,copiedLink, setCopiedLink}) => {

    const copyLink = () => {
        navigator.clipboard.writeText(link)
        setCopiedLink(true)
    }

    return (
        <Tooltip title={copiedLink ?
            <Box>
                <DoneIcon fontSize="small" sx={{ mr: 0.5, px: 0.3, }} />
                Copied
            </Box>
            : "Copy Invite Link"} onOpen={() => setCopiedLink(false)}>
            {children}
        </Tooltip>)
}