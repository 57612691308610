import Axios from "axios";
import { setError } from "../redux/error";
import { } from "../redux/question";
import { setScores } from "../redux/scoreboard";
import { store } from '../../common/redux/store';
import { GET_SCOREBOARD } from "../constants";

const headers =  {
    'Content-Type': "application/json; charset=utf-8",
    "accept": "application/json"
}

export const getScoreboard = () => {

    const contestId = store.getState().contest.id;
    if(!contestId){
        console.log("Contest ID not present");
        return;
    }

    const targetUrl = `${GET_SCOREBOARD}/${contestId}`

    const requestOptions = {
        headers: headers,
    };

    Axios.get(targetUrl, requestOptions)
        .then(
            (result) => {
                console.log("getScoreboard result : ", result);
                if (result.status === 200) {
                    store.dispatch(setScores(result.data.userScores))
                }
            },
            (error) => {
                console.log(error);
                store.dispatch(setError({
                    message: error?.response?.data?.message,
                    httpStatus: error?.response?.status,
                    code: error?.response?.data?.code,
                    shouldRedirect: error?.response?.status == 404 ? true : false,
                }));
            }
        )
}