import { useSelector } from 'react-redux';
import CBTabPanel from '../../../../../common/components/CBTabPanel';
import AcceptedConsoleOutput from './acceptedConsoleOutput';
import LoadingConsoleOutput from './loadingConsoleOutput';
import NoConsoleOutput from './noConsoleOutput';
import SuccessConsoleOutput from './successConsoleOutput';
import FailureConsoleOutput from './failureConsoleOutput';
import WrongConsoleOutput from './wrongConsoleOutput';
import { SUBMISSION_TYPE } from '../../../../../common/constants';

export default function ProblemConsoleOutput({size,output,sampleInputs,outputLoading,submissionType}) {

    const showTestOutput = (output) => {
        if(outputLoading) return <LoadingConsoleOutput/>
        if(!output) return <NoConsoleOutput/>
        if(output.status.id <= 2) return <LoadingConsoleOutput/>
        if(output.status.id <= 4) return <SuccessConsoleOutput sampleInputs={sampleInputs} output={output}/>
        else return <FailureConsoleOutput output={output}/>
    }

    const showSubmitOutput = (output) => {
        if(outputLoading) return <LoadingConsoleOutput/>
        if(!output) return <NoConsoleOutput/>
        if(output.status.id <= 2) return <LoadingConsoleOutput/>
        if(output.status.id == 3) return <AcceptedConsoleOutput output={output}/>
        if(output.status.id == 4) return <WrongConsoleOutput output={output}/>
        else return <FailureConsoleOutput output={output}/>
    }

    console.log("runTestOuput : ", output);
    return (
        <CBTabPanel sx={{overflow:'auto', height:'100%', maxHeight:`${size}px`}}>
            {submissionType === SUBMISSION_TYPE.TEST ? showTestOutput(output) : showSubmitOutput(output)}
        </CBTabPanel>
    );
}
