import { useState } from 'react';
import CBCurvedTabSection from '../../../../common/components/CBCurvedTabSection';
import ProblemSolveEditorSection from './problemSolveEditorSection';
import { Box } from '@mui/material';
import { languageModeMap, languageOptions } from '../../../../common/constants';


const ProblemSolveSection = ({selectedQuestionMetaData,selectedQuestion}) => {
    
    const initMetadata = []

    for(var i in selectedQuestionMetaData){
        var item = selectedQuestionMetaData[i]
        const lang = languageModeMap.find(l => l?.value === item?.languageId)
        if(lang){
            const label = languageOptions.find(l => l?.value === item?.languageId)?.label
            initMetadata.push({
                lang:label,
                mode:lang?.mode,
                code:item?.userCode
            })
        }
    }

    const [metadata, setMetadata] = useState(initMetadata)


    const onCodeChange = (e,idx) => {
        console.log("onCodeChange", e)
        const langCopy = [...metadata]
        langCopy[idx].code = e
        setMetadata(langCopy)
    }

    const languageSectionTabs = []

    for(i in metadata){
        languageSectionTabs.push(
            {
                key: metadata[i]?.lang,
                node: <ProblemSolveEditorSection code={metadata[i]?.code} mode={metadata[i]?.mode} onChange={(e,idx) => onCodeChange(e,idx)} selectedQuestion={selectedQuestion} index={i} />
            }
        )
    }

    return (
        <CBCurvedTabSection tabs={languageSectionTabs} initalTabIndex={0}/>
    )

}

export default ProblemSolveSection;
