import React from 'react'
import { Box } from '@mui/material'
import {ReactComponent as Loading} from '../assets/loading.svg';

function CBLoader() {
    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '100vh',
        }}>
            <Loading />
        </Box>
    )
}

export default CBLoader