import { Box } from "@mui/material";

const ProfileCard = ({sx,children}) => {
    return <Box sx={{
        bgcolor: '#282828',
        borderRadius: 2,
        display: 'flex',
        p: 2,
        width: '100%',
        ...sx
    }}>
        {children}
    </Box>
}

export default ProfileCard;