import { createSlice } from '@reduxjs/toolkit'
import { initialProblemMetadata } from '../constants'

const initialState = {
    newQuestion: {
        "id":null,
        "problemTitle":"",
        "problemStatement":"",
        "inputSpecification":"",
        "outputSpecification":"",
        "constraints":"",
        "sampleInput":[],
        "sampleOutput":[],
        "difficultyLevel":"",
        "tags":[],
        "timeLimit":2,
        "memoryLimit":128,
        "ioExplanation":"",
        "status":"DRAFT",
        "idealSolution":"",
        "idealSolutionLanguageId":54,
        "fileInputTestCases":[],
        "fileOutputTestCases":[],
        "testCaseGenerator":""
    },
    newQuestionMetaData: initialProblemMetadata
}

export const questionSlice = createSlice({
    name: 'createProblem',
    initialState,
    reducers: {

        setNewQuestion: (state, action) => {
            state.newQuestion = action?.payload
        },

        setNewQuestionMetaData: (state, action) => {
            state.newQuestionMetaData = action?.payload
        },
    },
})

export const { setNewQuestion,setNewQuestionMetaData} = questionSlice.actions

export default questionSlice.reducer