import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CBButton from '../../common/components/CBButton';
import CBMenu from '../../common/components/CBMenu';
import { getProblemMetadata } from '../apis/problemApis';
import { DEFAULT } from '../constants';
import { setSelectedLanguage } from '../redux/editor';
import { languageOptions } from '../../common/constants';

const LanguageMenu = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const {selectedLanguage,questionLang,questionCode} = useSelector(state => state.editor)
    const activeQuestion = useSelector(state => state.contest.activeQuestion)
    const dispatch = useDispatch();

    useEffect(() => {
      if(!questionLang[activeQuestion?.id]){
        handleSelectLanguage({label: selectedLanguage?.label,value: selectedLanguage?.value})
      } else {
        const lang = languageOptions.find(l => l?.value === questionLang[activeQuestion?.id])
        handleSelectLanguage({label: lang?.label,value: lang?.value})
      }
    }, [activeQuestion?.id])
    

    const handleOpenMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleSelectLanguage = ({label, value}) => {
        try {
            if(activeQuestion?.id){
                if(!questionCode[activeQuestion?.id + value] && questionCode[activeQuestion?.id + value] != ""){
                    getProblemMetadata({languageId: value})
                }
                dispatch(setSelectedLanguage({ value: value, label: label, activeQuestionId: activeQuestion?.id }))
            } else {
                dispatch(setSelectedLanguage({ value: value, label: label, activeQuestionId: DEFAULT }))
            }
        } catch(error) {
            console.log("Something went wrong : ",error);
        }
        handleCloseMenu();
    };

    return (
        <Box>
            <CBButton
                variant="text"
                size="small"
                onClick={handleOpenMenu} sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                {selectedLanguage?.label}
                <KeyboardArrowUpIcon fontSize='small'/>
            </CBButton>
            <CBMenu
                target={anchorEl}
                options={languageOptions}
                onCloseMenu={handleCloseMenu}
                onItemSelect={handleSelectLanguage}
                checkIcon
                selectedOption={selectedLanguage}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
            />
        </Box>
    );
};

export default LanguageMenu;
