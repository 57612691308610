import MenuIcon from "@mui/icons-material/Menu";
import {
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
} from "@mui/material";
import Divider from "@mui/material/Divider";

import { useState } from "react";
import { useAuth } from "../context/AuthContext";
import {
  DRAWER_OPTION_ROUTES,
  NavButton,
  NavLogin,
  SOCIAL_OPTION_ROUTES,
  handleOnRouteClick,
} from "../utils/NavOptionRoutes";
import theme from "../../theme";

function AppDrawer({ setShowLogin }) {
  const [openDrawer, setOpenDrawer] = useState(false);
  const { currentUser } = useAuth();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  return (
    <>
      <Drawer
        anchor="right"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        PaperProps={{
          sx: {
            backgroundColor: "#1a1a1a",
            minWidth: "180px",
          },
        }}
      >
        <List>
          <ListItem
            // onClick={() => setOpenDrawer(false)}
            sx={{ paddingBottom: 2 }}
          >
            <NavLogin
              currentUser={currentUser}
              onLoginClick={() => setShowLogin(true)}
              isMobile={isMobile}
            />
          </ListItem>
          <Divider />
          {DRAWER_OPTION_ROUTES.map((route) => (
            <ListItem
              key={route.name}
              onClick={() => handleOnRouteClick(route.path, route.external)}
              sx={{ marginTop: 0.5 }}
            >
              <ListItemText>
                <NavButton
                  title={route.name}
                  color={route.color}
                  isMobile={isMobile}
                  isSelected={window?.location?.pathname === route.path}
                />
              </ListItemText>
            </ListItem>
          ))}
          <Divider />
          <Box sx={{ mt: 2, ml: 3 }}>
            {SOCIAL_OPTION_ROUTES.map((route) => (
              <IconButton
                onClick={() => handleOnRouteClick(route.path, route.external)}
              >
                {route.name}
              </IconButton>
            ))}
          </Box>
        </List>
      </Drawer>

      <IconButton onClick={toggleDrawer}>
        <MenuIcon />
      </IconButton>
    </>
  );
}

export default AppDrawer;
