import { Box, List, ListItem, Switch, Typography, Button } from '@mui/material';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import CBButton from '../../common/components/CBButton';
import CBListTabs from '../../common/components/CBListTabs';
import CBMenuButton from '../../common/components/CBMenuButton';
import CBModel from '../../common/components/CBModel';
import { EDITOR_FONT_SIZE_OPTIONS, EDITOR_KEY_BINDING_OPTIONS, EDITOR_TAB_SIZE_OPTIONS } from '../../common/constants';
import { RootState } from '../../common/redux/store';
import { resetEditorSettings, setEditorSettings } from '../redux/editor';
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';
import CodeIcon from '@mui/icons-material/Code';
import ShortcutsIcon from '@mui/icons-material/Keyboard';
import { leaveContest } from '../apis/contestApis';
import CopyIcon from '@mui/icons-material/CopyAll';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

interface SettingsPopupProps {
    showSettingsPopup: boolean;
    setShowSettingsPopup: (show: boolean) => void;
}

const SettingsPopup: React.FC<SettingsPopupProps> = ({ showSettingsPopup, setShowSettingsPopup }) => {
    const [selectedOption, setSelectedOption] = useState('Editor');
    const { fontSize, keyBinding, tabSize, wordWrap } = useSelector((state: RootState) => state.editor);
    const dispatch = useDispatch();

    const handleOptionClick = (option: string) => {
        setSelectedOption(option);
    };

    const handleReset = () => {
        dispatch(resetEditorSettings({}));
    };

    const handleCopyInviteLink = () => {
        navigator.clipboard.writeText(window.location.href);
        toast.success("Link is Copied", { hideProgressBar: true, autoClose: 1000 });
    };

    const handleLeaveRoom = () => {
        const url = new URL(window.location.href);
        const contestId = url.pathname.split('/')[2];
        leaveContest(contestId);
    };

    const GeneralSettings = () => (
        <Box sx={{ mt: 1 }}>
            <List>
                <ListItem sx={{ display: 'flex', justifyContent: 'space-between', px: 0 }}>
                    <Typography>Invite Link</Typography>
                    <CBButton variant='outlined' onClick={handleCopyInviteLink}>📋 Copy Invite Link</CBButton>
                </ListItem>
                <ListItem sx={{ display: 'flex', justifyContent: 'space-between', px: 0 }}>
                    <Button variant='outlined' color='error' onClick={handleLeaveRoom}>
                        👋 Leave Room
                    </Button>
                </ListItem>
            </List>
        </Box>
    );

    const EditorSettings = () => (
        <Box sx={{ mt: 1 }}>
            <List>
                <ListItem sx={{ display: 'flex', justifyContent: 'space-between', px: 0 }}>
                    <Typography>Font Size</Typography>
                    <CBMenuButton
                        variant='contained'
                        options={EDITOR_FONT_SIZE_OPTIONS}
                        selectedOption={fontSize}
                        setSelectedOption={({ label, value }) => dispatch(setEditorSettings({ fontSize: { label, value } }))}
                    />
                </ListItem>
                <ListItem sx={{ display: 'flex', justifyContent: 'space-between', px: 0 }}>
                    <Typography>Key Binding</Typography>
                    <CBMenuButton
                        variant='contained'
                        options={EDITOR_KEY_BINDING_OPTIONS}
                        selectedOption={keyBinding}
                        setSelectedOption={({ label, value }) => dispatch(setEditorSettings({ keyBinding: { label, value } }))}
                    />
                </ListItem>
                <ListItem sx={{ display: 'flex', justifyContent: 'space-between', px: 0 }}>
                    <Typography>Tab Size</Typography>
                    <CBMenuButton
                        variant='contained'
                        options={EDITOR_TAB_SIZE_OPTIONS}
                        selectedOption={tabSize}
                        setSelectedOption={({ label, value }) => dispatch(setEditorSettings({ tabSize: { label, value } }))}
                    />
                </ListItem>
                <ListItem sx={{ display: 'flex', justifyContent: 'space-between', px: 0 }}>
                    <Typography>Word Wrap</Typography>
                    <Switch
                        checked={wordWrap}
                        onChange={(event) => dispatch(setEditorSettings({ wordWrap: event.target.checked }))}
                    />
                </ListItem>
            </List>
            <CBButton variant='text' onClick={handleReset}>Reset</CBButton>
        </Box>
    );

    const ShortcutsSettings = () => (
        <Box sx={{ mt: 1 }}>
            <List>
                <ListItem sx={{ display: 'flex', justifyContent: 'space-between', px: 0 }}>
                    <Typography>Run Code</Typography>
                    <Box className="questionContent">
                        <code>Ctrl</code> + <code>'</code>
                    </Box>
                </ListItem>
                <ListItem sx={{ display: 'flex', justifyContent: 'space-between', px: 0 }}>
                    <Typography>Submit</Typography>
                    <Box className="questionContent">
                        <code>Ctrl</code> + <code>Enter</code>
                    </Box>
                </ListItem>
            </List>
        </Box>
    );

    const listItems = [
        { text: 'Editor', icon: <CodeIcon sx={{ mr: 1, color: '#fff' }} />, onClick: () => handleOptionClick('Editor'), selected: selectedOption === 'Editor' },
        { text: 'Shortcuts', icon: <ShortcutsIcon sx={{ mr: 1, color: '#fff' }} />, onClick: () => handleOptionClick('Shortcuts'), selected: selectedOption === 'Shortcuts' },
        { text: 'General', icon: <SpaceDashboardIcon sx={{ mr: 1, color: '#fff' }} />, onClick: () => handleOptionClick('General'), selected: selectedOption === 'General' },
    ];

    return (
        <CBModel
            open={showSettingsPopup}
            onClose={() => setShowSettingsPopup(false)}
            sx={{ display: 'flex', p: 0 }}
        >
            <Box sx={{ width: '30%', p: 2, bgcolor: '#1a1a1a' }}>
                <Typography variant="h6" gutterBottom sx={{ color: '#fff' }}>
                    Settings
                </Typography>
                <CBListTabs items={listItems} />
            </Box>

            <Box sx={{ flex: 1, p: 4, bgcolor: '#373737' }}>
                {selectedOption === 'Editor' && <EditorSettings />}
                {selectedOption === 'Shortcuts' && <ShortcutsSettings />}
                {selectedOption === 'General' && <GeneralSettings />}
            </Box>
        </CBModel>
    );
};

export default SettingsPopup;
