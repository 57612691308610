import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import { Avatar, Box, Dialog, DialogTitle, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getScoreboard } from '../../../apis/scoreboardApi';
import { ReactComponent as FirstIcon } from '../../../assets/first_rank.svg';
import { ReactComponent as SecondIcon } from '../../../assets/second_rank.svg';
import CBModel from '../../../../common/components/CBModel';


function createData(rank, photoURL, participantName, questionsMetadata, totalScore, totalBonus) {
    return { rank, photoURL, participantName, questionsMetadata, totalScore, totalBonus };
}

const ScoreboardCard = ({ showScoreboard, setShowScoreboard }) => {
    const participants = useSelector(state => state.participant.participants)
    const questions = useSelector(state => state.question.questions)
    const scores = useSelector(state => state.scoreboard.scores) || []
    const status = useSelector(state => state.contest.status)

    useEffect(() => {
        if (showScoreboard) getScoreboard()
    }, [showScoreboard])

    const headers = ['Rank', 'Players', ...questions.map(question => question.problemTitle), 'Total Score', 'Total Bonus'];

    const rows = []
    try {
        rows.push(...scores.map((userScore) => {
            const participant = participants.find(participant => participant.uid === userScore.userId);
            const questionsMetadata = questions.map(q => {
                const questionScore = userScore.questionScores.find(questionScore => questionScore.questionId === q.id)
                return ({ time: questionScore.timeTaken, status: questionScore.status })
            })
            return createData(userScore.rank, participant.photoURL, participant.displayName, questionsMetadata, userScore.totalScore, userScore.totalBonus,);
        }));
    } catch (error) {
        console.log("Something went wrong while populating rows : ", error);
    }

    return (
        <CBModel
            open={showScoreboard}
            onClose={() => setShowScoreboard(false)}
            title='Scoreboard'
            sx={{ p: 0, minHeight: 0, maxWidth: 'md', width: '100%' }}>
            
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow sx={{
                            '& td, & th': {
                                border: 'none',
                                whiteSpace: 'nowrap',
                                maxWidth: '160px'
                            }
                        }}>
                            {headers && headers.map(header => (
                                <TableCell align="center" key={header}><Typography sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }} variant="body2" color="text.secondary">{header}</Typography></TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows && rows.length > 0 && rows.map((row, index) => (
                            <TableRow key={index} sx={{ '& td, & th': { border: 'none', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', maxWidth: '160px' } }}>
                                <TableCell align="center" component="th" scope="row">
                                    {row.rank === 1 ? <FirstIcon />
                                        : row.rank === 2 ? <SecondIcon /> : row.rank
                                    }
                                </TableCell>
                                <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Avatar sx={{ width: 24, height: 24, mr: 1 }} src={row.photoURL} />
                                    <Typography variant="body2">{row.participantName}</Typography>
                                </TableCell>
                                {row.questionsMetadata.map((questionsMetadata, index) => (
                                    <TableCell align="center" key={headers[index + 2]}>
                                        {questionsMetadata.status === "ACCEPTED" ? (
                                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <Typography variant="body2">{questionsMetadata.time}</Typography>
                                                <CheckIcon sx={{ ml: 1 }} fontSize="small" color="success" />
                                            </Box>
                                        ) : questionsMetadata.status === "ATTEMPTED" ? (
                                            <ClearIcon fontSize="small" color="error" />
                                        ) : (
                                            <HorizontalRuleIcon fontSize="small" color="gray" />
                                        )}
                                    </TableCell>
                                ))}
                                <TableCell align="center"><Typography variant="body2" sx={{ fontWeight: 'bold' }}>{row.totalScore}</Typography></TableCell>
                                <TableCell align="center"><Typography variant="body2" sx={{ fontWeight: 'bold' }}>{row.totalBonus}</Typography></TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </CBModel>
    )
}

export default ScoreboardCard
