import { createSlice } from '@reduxjs/toolkit'
import { SUBMISSION_TYPE } from '../constants'

const initialState = {
    activeInputCases: [],
    runTestOuput: null,
    outputLoading: false,
    submissionType: SUBMISSION_TYPE.TEST,
}

export const submissionSlice = createSlice({
    name: 'submission',
    initialState,
    reducers: {
        setActiveInputCases: (state, action) => {
            state.activeInputCases = action.payload
            state.runTestOuput = null
            state.outputLoading = false
        },
        setRunTestOutput: (state, action) => {
            state.runTestOuput = action.payload
        },
        resetOutput: (state, action) => {
            state.runTestOuput = null
            state.outputLoading = false
        },
        setOutputLoading: (state, action) => {
            state.outputLoading = action.payload
        },
        setSubmissionType: (state, action) => {
            state.submissionType = action.payload
        },
    },
})

export const { setActiveInputCases,setRunTestOutput,resetOutput,setOutputLoading,setSubmissionType } = submissionSlice.actions

export default submissionSlice.reducer