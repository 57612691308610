import LinkIcon from '@mui/icons-material/Link';
import { Box, Stack, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { ReactComponent as CBLogo } from '../../../../common/assets/cb_logo.svg';
import { ReactComponent as IconDot } from '../../../../common/assets/icon_dot.svg';
import CBButton from "../../../../common/components/CBButton";
import { useAuth } from "../../../../common/context/AuthContext";
import LobbyActionButton from "../lobbySections/lobbyActionButton";

export default function ReadySection({ contest_id }) {
    const contest = useSelector(state => state.contest);
    const participants = useSelector(state => state.participant.participants);
    const { currentUser } = useAuth();

    const handleOnClickCopy = () => {
        navigator.clipboard.writeText(window.location.href);
        toast.success("Link is Copied", { hideProgressBar: true, autoClose: 1000 });
    };

    const getMyUserStatus = () => {
        if (!currentUser || !participants) return;
        const myUser = participants.find(participant => participant.uid === currentUser.uid);
        if (myUser) return myUser.status;
    };

    return (
        <Box width={'100%'} alignItems={'center'} justifyContent={'center'} display="flex" backgroundColor='#282828' sx={{ mt: 1 }}>
            <Stack
                direction={'column'}
                alignItems={'center'}
                justifyContent={'center'}
                sx={{ backgroundColor: '#2D2C2C', p: 2.5, border: '1px solid #3e3e3e' }}
                borderRadius={2.5}
                maxWidth={500}
                minWidth={100}
            >
                <CBLogo style={{ height: '86px', width: '95px' }} />
                <Typography fontSize={'26px'} sx={{ mt: 3 }} fontWeight={600}>
                    Welcome 🎉 to CodeBattle!
                </Typography>
                <Stack sx={{ mt: 1.5 }} flexDirection={"row"} alignItems={"center"} gap={1.5}>
                    <Typography sx={{ color: '#999999' }}>
                        Invite your Friends 🤝
                    </Typography>
                    <IconDot />
                    <Typography sx={{ color: '#999999' }}>
                        Mark yourself Ready 🚀
                    </Typography>
                </Stack>
                <Box sx={{ mt: 6, width: '100%' }}>
                    <Typography sx={{ color: '#999999' }}>
                        Room Link
                    </Typography>

                    <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', mt: 0.5, flexDirection: { xs: 'column', sm: 'row' } }} >
                        {/* Room Link Section with 70% width */}
                        <Box
                            sx={{
                                flex: '0 1 70%',
                                px: 1.5,
                                display: 'flex',
                                alignItems: 'center',
                                backgroundColor: "#282828",
                                borderRadius: 2,
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                cursor: 'pointer',
                                mr: 1
                            }}
                            onClick={handleOnClickCopy}
                        >
                            <Typography noWrap>
                                {window.location.href}
                            </Typography>
                        </Box>

                        {/* Invite Button Section with 30% width */}
                        <CBButton
                            color="hover"
                            onClick={handleOnClickCopy}
                            sx={{ flex: '0 1 30%', px: 3, mt: { xs: 2, sm: 0 } }}
                        >
                            <Box><LinkIcon sx={{ mr: 1 }} /></Box> Invite
                        </CBButton>
                    </Box>

                    <LobbyActionButton userStatus={getMyUserStatus()} contest_id={contest_id} contestStatus={contest.status} />
                    <Stack flexDirection={"row"} sx={{ mt: 2.5 }}>
                        <Typography fontSize={'12px'}>👉 &nbsp;</Typography>
                        <Typography fontSize={'12px'} color={"#999999"}>
                            <span style={{ color: 'white' }}> NOTE: &nbsp;</span>
                            The contest will start 2 minutes after over 50% of participants mark as ready.
                        </Typography>
                    </Stack>
                </Box>
            </Stack>
        </Box>
    )
}
