import { StandardTextFieldProps, TextField, TextFieldProps } from '@mui/material';
import React, { FC } from 'react';

interface CBTextFieldProps extends StandardTextFieldProps { }

const CBTextField: FC<CBTextFieldProps> = ({ ...props }) => {
    return (
        <TextField
            multiline
            fullWidth
            sx={{ mt: 2, bgcolor: '#2d2d2d', borderRadius: 2 }}
            InputProps={{
                sx: {
                    borderRadius: 2,
                    typography: 'body2',
                    px: 2,
                    py: 2,
                    "& fieldset": { border: 'none' },
                },
                disableUnderline: true
            }}
            {...props}
        />
    );
};

export default CBTextField;
