import InfoIcon from "@mui/icons-material/InfoOutlined";
import {
  Box,
  Card,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AdvanceControl from "../../common/components/AdvanceControl";
import CBButton from "../../common/components/CBButton";
import NavBar from "../../common/components/NavBar";
import Login from "../../common/components/login";
import { useAuth } from "../../common/context/AuthContext";
import { resetStateAction } from "../../common/redux/reducer";
import { persistor, store } from "../../common/redux/store";
import { PATH_ROOMS, handleOnRouteClick } from "../../common/utils/NavOptionRoutes";
import DifficultyMenu from "../components/DifficultyMenu";
import { problemCountByContestDifficulty } from "../constants";
import { DIFFICULTY_SMALL, IMAGE_CODEBATTLE_SCREENSHOT } from "../../common/constants";
import CBSeo from "../../common/components/CBSeo";

const difficultyMap = [
  { label: "Easy", value: "EASY", defaultContestTime: 30, problemNumsByDiff: problemCountByContestDifficulty(DIFFICULTY_SMALL.EASY) },
  { label: "Medium", value: "MEDIUM", defaultContestTime: 40, problemNumsByDiff: problemCountByContestDifficulty(DIFFICULTY_SMALL.MEDIUM) },
  { label: "Hard", value: "HARD", defaultContestTime: 50, problemNumsByDiff: problemCountByContestDifficulty(DIFFICULTY_SMALL.HARD) },
];

function HomePage() {
  const [difficultyLevel, setDifficultyLevel] = useState(difficultyMap[0]);
  const [showAdvance, setShowAdvance] = useState(false);
  const { currentUser } = useAuth();
  const [showLogin, setShowLogin] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const infoText = {
    EASY: `You will get ${difficultyLevel.defaultContestTime} mins to solve 3 easy problems.`,
    MEDIUM: `You will get ${difficultyLevel.defaultContestTime} mins to solve 1 easy, 2 medium problems.`,
    HARD: `You will get ${difficultyLevel.defaultContestTime} mins to solve 2 medium, 1 hard problems.`,
  };

  useEffect(() => {
    console.log("I am triggering");
    persistor
      .purge()
      .then(() => {
        console.log("Reset Everything");
        store.dispatch(resetStateAction());
      })
      .catch(() => {
        console.log("Something went wrong");
      });
  }, []);

  const handleOnClickCreateContest = () => {
    if (!currentUser) {
      setShowLogin(true, () => {
        setShowAdvance(true)
      });
    } else {
      setShowAdvance(true)
    }
  };

  return (
    <>
      <CBSeo
        title="CodeBattle - Code & Compete with your friends."
        description="Welcome to CodeBattle.in, the ultimate platform for practicing coding and DSA problems with Friends! Join coding contests, tackle real interview questions, and collaborate with friends in a vibrant community. Level up your coding skills with CodeBattle."
      />
      <Login showLogin={showLogin} />
      <AdvanceControl showAdvance={showAdvance} setShowAdvance={setShowAdvance} difficultyLevel={difficultyLevel} setDifficultyLevel={setDifficultyLevel} difficultyMap={difficultyMap} />
      <Box
        sx={{
          height: "100vh",
          width: "100%",
          backgroundColor: "#1a1a1a",
          overflow: "auto",
        }}
      >
        <Box>
          <NavBar sx={{ background: "transparent", boxShadow: "none", p: 3 }} />
          <Grid
            container
            direction="row"
            justifyContent="center"
            sx={{ paddingTop: 5, marginBottom: { xs: 5 } }}
          >
            <Grid
              item
              md={5}
              sm={12}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                marginTop: 3,
                maxWidth: "80%",
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Box sx={{ mb: 5 }}>
                  <Typography
                    letterSpacing="0.1rem"
                    lineHeight={0.5}
                    textTransform="uppercase"
                    fontWeight={400}
                    sx={{
                      fontSize: {
                        lg: "2rem",
                        md: "2rem",
                        sm: "1.25rem",
                        xs: "1.25rem",
                      },
                    }}
                  >
                    Code & Compete
                  </Typography>
                  <Typography
                    letterSpacing="0.4rem"
                    textTransform="uppercase"
                    fontWeight={500}
                    sx={{
                      fontSize: {
                        lg: "3.5rem",
                        md: "3.5rem",
                        sm: "2.75rem",
                        xs: "2rem",
                      },
                    }}
                  >
                    With Your
                  </Typography>
                  <Typography
                    lineHeight={1.2}
                    textTransform="uppercase"
                    fontWeight={700}
                    sx={{
                      fontSize: {
                        lg: "5rem",
                        md: "5rem",
                        sm: "3.5rem",
                        xs: "3rem",
                      },
                      letterSpacing: {
                        lg: "0.6rem",
                        md: "0.6rem",
                        sm: "1.2rem",
                        xs: "1.2rem",
                      },
                    }}
                    color="#10a0a0"
                  >
                    Friends
                  </Typography>
                </Box>
                <Box>
                  <Card
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      borderRadius: 8,
                      p: 1,
                      minWidth: "300px",
                    }}
                  >
                    <DifficultyMenu
                      difficultyMap={difficultyMap}
                      difficultyLevel={difficultyLevel}
                      setDifficultyLevel={setDifficultyLevel}
                    />
                    <CBButton
                      size="large"
                      sx={{ ml: 2, borderRadius: 8 }}
                      variant="contained"
                      onClick={() => handleOnClickCreateContest()}
                    >
                      Create Room
                    </CBButton>
                  </Card>
                  <Box
                    sx={{
                      display: "flex",
                      mt: 3,
                    }}
                  >
                    <InfoIcon
                      fontSize="small"
                      sx={{ mr: 1, color: "#10a0a0" }}
                    />
                    <Typography
                      sx={{
                        color: "text.secondary",
                      }}
                      variant="body2"
                    >
                      {infoText[difficultyLevel?.value]}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item md={7} sm={12} sx={{ zIndex: 100 }}>
              <img src={IMAGE_CODEBATTLE_SCREENSHOT} alt="CodeBattle Screenshot" style={{ width: "100%" }} />
            </Grid>
          </Grid>
        </Box>

        {isMobile && (
          <CBButton
            size="large"
            sx={{
              position: "fixed",
              bottom: 0,
              width: "100%",
              borderRadius: 0,
              zIndex: 100,
            }}
            variant="contained"
            onClick={() => handleOnRouteClick(PATH_ROOMS)}
          >
            Join Active Rooms
          </CBButton>
        )}
      </Box>
    </>
  );
}

export default HomePage;
