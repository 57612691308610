import CBCurvedTabSection, { CBSectionTabProps } from "../../common/components/CBCurvedTabSection";
import ProblemTabPanel from "./ProblemTabPanel";



const ProblemSection = ({ selectedQuestion}) => {

    const problemSectionTabs = [
        {
            key: 'Problem',
            node: <ProblemTabPanel selectedQuestion={selectedQuestion}/>
        }
    ]

    return (
           <CBCurvedTabSection tabs={problemSectionTabs} initalTabIndex={0}/>   
    )
    
}

export default ProblemSection;
