import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'
import HomePage from './pages/homePage'
import MainPages from './pages/mainPages'
import Axios from 'axios';
import { getGeoInfo, postUserGeoInfo } from './apis/userApis';
import { useEffect } from 'react';
import { useAuth } from '../common/context/AuthContext';
import { usePostHog } from 'posthog-js/react'

function CBApp() {
    const { currentUser, loading } = useAuth();
    const posthog = usePostHog()

    useEffect(() => { 
        if(!loading && currentUser) {
            getGeoInfo()
            posthog?.identify(currentUser?.uid, {
                email: currentUser?.email,
                uid: currentUser?.uid,
                name: currentUser?.displayName
            })
        }
    }, [currentUser,loading,posthog])
    


    return (
        <div className="CBApp">
                <Routes>
                    <Route path='/' element={<HomePage/>} />
                    <Route path='/*' element={<MainPages/>} />
                </Routes>
        </div>
    )
}

export default CBApp