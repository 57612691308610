import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    topics: []
}

export const problemTopicSlice = createSlice({
    name: 'problemTopic',
    initialState,
    reducers: {
        setTopics: (state, action) => {
            state.topics = [...(action?.payload ?? [])]
        }
    },
})

export const { setTopics} = problemTopicSlice.actions

export default problemTopicSlice.reducer