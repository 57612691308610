import Showdown from 'showdown';

Showdown.setFlavor('github');
const converter = new Showdown.Converter();
converter.setFlavor('github');

export function convertToMarkDown(html: string): string {
  if(html == null || html == undefined || html === "") return ""
  const markdown = converter.makeMarkdown(html)?.replaceAll("&lt;","<")?.replaceAll("&gt;",">").replaceAll("<br>\n","");
  console.log(`convertToMarkDown ${markdown}`);
  return markdown
}

export function convertToHtml(markDown: string): string {
  if(markDown == null || markDown == undefined || markDown === "") return ""
  console.log("convertToHtml ",converter.getOptions());
  const html = converter.makeHtml(markDown)
  console.log(`convertToHtml ${html}`);
  return html;
}
