import { Tab, Tabs, Typography } from '@mui/material';
import React from 'react'
import PropTypes from 'prop-types';

function CBCurvedTabs({ value, onChange, tabs, children }) {
    return (
        <Tabs value={value} onChange={onChange}
            TabIndicatorProps={{ sx: { display: 'none' } }}
            sx={{
                bgcolor: '#1a1a1a',
                minHeight: '36px',
            }}
        >
            {tabs.map((tab, index) => {
                return (
                    <Tab sx={{
                        textTransform: 'none',
                        '&.Mui-selected': {
                            backgroundColor: '#282828',
                            borderRadius: '6px 6px 0px 0px',
                            color: '#ffffff',
                        },
                        minHeight: '36px',
                        marginTop: '8px',
                        px: 2.5,
                        py: 0
                    }} key={index} label={<Typography variant='caption' fontWeight={value === index ? 600 : 400}>{tab}</Typography>} />
                );
            })}
            {children}
        </Tabs>
    )
}

CBCurvedTabs.propTypes = {
    value: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    tabs: PropTypes.arrayOf(PropTypes.string).isRequired,
    children: PropTypes.node
}

export default CBCurvedTabs