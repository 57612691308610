import { Ace } from "ace-builds";
import { FC, useRef } from 'react';
import AceEditor, { IAceEditorProps } from "react-ace";
require("ace-builds/src-noconflict/mode-c_cpp");
require("ace-builds/src-noconflict/theme-dracula");
require("ace-builds/src-noconflict/mode-java");
require("ace-builds/src-noconflict/mode-python");
require("ace-builds/src-noconflict/snippets/c_cpp");
require("ace-builds/src-noconflict/snippets/java");
require("ace-builds/src-noconflict/snippets/python");
require("ace-builds/src-noconflict/theme-monokai");
require("ace-builds/src-min-noconflict/ext-searchbox")
require("ace-builds/src-min-noconflict/ext-language_tools");
require("ace-builds/src-noconflict/keybinding-vim")
require("ace-builds/src-noconflict/keybinding-emacs")


interface CBEditorProps extends IAceEditorProps {}

const CBEditor: FC<CBEditorProps> = ({ ...props }) => {

    const editorRef = useRef<Ace.Editor | null>(null);


    const handleEditorLoad = (editor: Ace.Editor) => {
        editorRef.current = editor;
        // Beautify.beautify(editor.session)
    };



    return (
        <AceEditor
            style={{
                backgroundColor: '#282828',
            }}
            placeholder="Write your code from here"
            width="100%"
            height="100%"
            mode="c_cpp"
            theme='dracula'
            onLoad={handleEditorLoad}
            name="blah2"
            fontSize="0.875rem"
            showPrintMargin={true}
            showGutter={true}
            highlightActiveLine={true}
            // commands={Beautify.commands}
            editorProps={{
                $blockScrolling: true,
            }}
            setOptions={{
                enableBasicAutocompletion: true,
                enableLiveAutocompletion: true,
                enableSnippets: true,
                showLineNumbers: true,
                tabSize: 4,
                autoScrollEditorIntoView: true
            }}
            {...props}
        />
    )
}

export default CBEditor
