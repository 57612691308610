import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    publishedProblems: [],
    slugProblemIdMap: {},
}

export const publishedProblemsSlice = createSlice({
    name: 'publishedProblems',
    initialState,
    reducers: {
        setAllPublishedProblems: (state, action) => {
            state.publishedProblems = [...(action?.payload ?? [])]
            const newSlugToIdMap = {};
            action?.payload?.forEach((problem) => {
              newSlugToIdMap[problem.titleSlug] = problem.id;
            });

            // Replace the old slugToIdMap with the new one
            state.slugProblemIdMap = newSlugToIdMap;
        },
    },
})

export const { 
    setAllPublishedProblems
} = publishedProblemsSlice.actions

export default publishedProblemsSlice.reducer