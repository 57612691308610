import { Box } from '@mui/material';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Split from 'react-split';
import { useAuth } from '../../common/context/AuthContext';
import { getMetaDataByProblem, getProblemByID } from '../apis/adminApis';
import ProblemSection from '../components/ProblemSection';
import RightPanelSection from '../components/sections/rightPanelSection';

import CBLoader from '../../common/components/CBLoader';
import { setSelectedQuestion } from '../redux/adminQuestions';

const ProblemPage = () => {
    const { problem_id } = useParams();
    const { selectedQuestion, selectedQuestionMetaData } = useSelector(state => state.adminQuestion)
    const { currentUser, loading } = useAuth();

    console.log("AdminProblemPage",selectedQuestionMetaData)

    useEffect(() => {
        if (!loading && currentUser) {
            getProblemByID(problem_id)
            getMetaDataByProblem(problem_id)
        }
    }, [loading, currentUser, problem_id])


    return (
        <div>
            {(loading && selectedQuestion) ? <CBLoader /> :
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Split minSize={300} style={{ display: 'flex', flexDirection: 'row', backgroundColor: '#1a1a1a' }} sizes={[30, 70]} gutterSize={8}>
                        <ProblemSection selectedQuestion={selectedQuestion} />
                        <RightPanelSection currentUser={currentUser} selectedQuestionMetaData={selectedQuestionMetaData} selectedQuestion={selectedQuestion} setSelectedQuestion={setSelectedQuestion}/>
                    </Split>
                </Box>
            }
        </div>
    )
}

export default ProblemPage;

