import { Box, Typography } from '@mui/material'
import CBCard from '../../../../../common/components/CBCard'
import { base64decode } from '../../../../../utils'

function FailureConsoleOutput({output}) {
    return (
        <Box sx={{ justifyContent: 'space-around' }}>
            <Typography sx={{}} variant="h6" color="error.main">{output.status.description + '!'}</Typography>
            {output.status.message && <CBCard label="Error" sx={{ mt: 3, overflow:'auto', maxHeight:'400px'  }}>{base64decode(output.status.message)}</CBCard>}
        </Box >
    )
}

export default FailureConsoleOutput
