import { Paper, Box, Divider } from '@mui/material';
import { FC, ReactNode, SetStateAction, useState } from 'react';
import CBCurvedTabs from './CBCurvedTabs';
import TabPanel from './TabPanel';
import CBTabs from './CBTabs';

export interface CBSectionProps {
    tabs: CBSectionTabProps[];
    initalTabIndex: number;
    end?: ReactNode[];
    selectedKey?: number;
    setSelectedKey?: (_e: any,newKey: SetStateAction<number>) => void; 
}

export interface CBSectionTabProps {
    key: string
    node: ReactNode
}

const CBFlatTabSection:FC<CBSectionProps> = ({tabs,initalTabIndex,end,selectedKey,setSelectedKey}) => {

    const [key, setKey] = useState(initalTabIndex)

    const onChange = (_e: any,newKey: SetStateAction<number>) => {
        console.log(newKey);
        setKey(newKey);
    }

    return (
        <Box>
            <CBTabs value={selectedKey ? selectedKey : key} onChange={setSelectedKey ? setSelectedKey : onChange} tabs={tabs.map(tab => tab.key)}>
                <Box sx={{ flex: 1, display: 'flex', justifyContent: 'end',alignItems:'center' }}>
                    {end}
                </Box>
            </CBTabs>
            <Divider />
            {tabs.map((tab, index) => {
                return (<TabPanel key={index} value={selectedKey ? selectedKey : key} index={index}>
                    {tab.node}
                </TabPanel>)
            })}
        </Box>
    )
}

export default CBFlatTabSection