import { Avatar, Box, Typography } from '@mui/material';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import cx from 'clsx';
import PropTypes from 'prop-types';
import defaultChatMsgStyles from './defaultChatMsgStyles';
import { updateTimeZone } from '../../../utils';
import LinkTypography from './LinkTypography';
import LinkAvatar from './LinkAvatar';
import { PATH_PROFILE, handleOnRouteClick } from '../../../common/utils/NavOptionRoutes';

const styles = defaultChatMsgStyles;

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(4.5),
  height: theme.spacing(4.5),
  borderRadius: '12px',
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(1, 2),
  borderRadius: 4,
  display: 'inline-block',
  wordBreak: 'break-word',
  fontFamily:
    '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
  fontSize: '0.875rem',
}));

const ChatMsg = (props) => {
  const {
    avatar,
    messages,
    color,
    side,
    author,
    GridContainerProps,
    GridItemProps,
    AvatarProps,
    getTypographyProps,
  } = props;

  const attachClass = (index) => {
    if (index === 0) {
      return `${side}First`;
    }
    if (index === messages.length - 1) {
      return `${side}Last`;
    }
    return '';
  };

  return (
    <Box
      sx={{ mb: 1.5 }}
      spacing={1}
      justifyContent={side === 'right' ? 'end' : 'start'}
      alignItems='end'
      flexDirection='row'
      display='flex'
      {...GridContainerProps}
    >
      {side === 'right' && (
        <Box>
          <Typography component='p' variant="caption" textAlign='start' sx={{ color: "text.secondary", mx: 1, width: 'max-content' }}>
            {updateTimeZone(messages[0]?.time)}
          </Typography>
        </Box>
      )}
      <Box justifyContent={side === 'right' ? 'end' : 'start'} sx={{ display: 'flex' }}>
        {side === 'left' && (
          <Box {...GridItemProps} sx={{ mr: 1 }}>
            <LinkAvatar
              src={avatar}
              {...AvatarProps}
              className={cx(AvatarProps.className)}
              onClick={() => messages?.[0]?.author && handleOnRouteClick(PATH_PROFILE + messages?.[0]?.author)}
              css={css(styles.avatar)}
            />
          </Box>
        )}
        <Box>
          {messages.map((msg, i) => {
            const TypographyProps = getTypographyProps(msg, i, props);
            return (
              <div key={msg.id || i} css={css(styles[`${side}Row`])}>
                <StyledTypography
                  align={'left'}
                  {...TypographyProps}
                  className={cx(
                    styles.msg,
                    styles[side],
                    styles[attachClass(i)],
                    TypographyProps.className
                  )}
                  sx={{ display: 'flex', flexDirection: 'column' }}
                >
                  {(i === 0 && side === 'left') && (
                    <LinkTypography
                      onClick={() => handleOnRouteClick(PATH_PROFILE + msg?.author)}
                      variant='body2'
                      color={color}
                      fontSize={'0.75rem'}
                      fontWeight={700}
                      sx={{
                        "&:hover": {
                          opacity: 0.7,
                        },
                      }}
                    >
                      {author}
                    </LinkTypography>
                  )}
                  <Typography variant='body2' style={{ whiteSpace: 'pre-line' }}>
                    {msg?.message}
                  </Typography>
                </StyledTypography>
              </div>
            );
          })}
        </Box>
      </Box>
      {side === 'left' && (
        <Box>
          <Typography component='p' variant="caption" textAlign='end' sx={{ color: "text.secondary", mx: 1, width: 'max-content' }}>
            {updateTimeZone(messages[0]?.time)}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

ChatMsg.propTypes = {
  avatar: PropTypes.string,
  author: PropTypes.string,
  messages: PropTypes.arrayOf(PropTypes.object),
  color: PropTypes.string,
  side: PropTypes.oneOf(['left', 'right']),
  GridContainerProps: PropTypes.shape({}),
  GridItemProps: PropTypes.shape({}),
  AvatarProps: PropTypes.shape({}),
  getTypographyProps: PropTypes.func,
};

ChatMsg.defaultProps = {
  avatar: '',
  author: '',
  color: 'white',
  messages: [],
  side: 'left',
  GridContainerProps: {},
  GridItemProps: {},
  AvatarProps: {},
  getTypographyProps: () => ({}),
};

export default ChatMsg;