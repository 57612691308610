import { useSelector } from 'react-redux';
import CBCurvedTabSection from '../../../../common/components/CBCurvedTabSection';
import { useAuth } from '../../../../common/context/AuthContext';
import EditorTabPanel from './editorTabPanel';


function EditorSection() {
    const participants = useSelector(state => state.participant.participants)
    const { currentUser } = useAuth();

    const currentParticipant = participants.find(p => p.uid === currentUser?.uid)?.displayName;

    const tabs = [
        { key: currentParticipant, node: <EditorTabPanel /> }
    ]

    return (
        <CBCurvedTabSection tabs={tabs} initalTabIndex={0} />
    )
}

export default EditorSection
