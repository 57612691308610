import { createContext, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import SockJS from 'sockjs-client';
import Stomp from 'stompjs';
import { useAuth } from '../../common/context/AuthContext';
import { SOCK_JS } from '../constants';

const WebSocketContext = createContext(null);

export { WebSocketContext };

export default ({ children }) => {
  const [connected, setConnected] = useState(false);
  const stompClient = useRef(null);

  useEffect(() => {
    console.log("WebSocketContext connecting to : websocket");
    const socket = new SockJS(SOCK_JS);
    const client = Stomp.over(socket);
    client.connect({}, () => {
      setConnected(true)
      console.log("WebSocketContext connected to : ", client);
      stompClient.current = client;
    });

    stompClient.current = client;

    return () => {
      if (client && client.connected) {
        client.disconnect();
        setConnected(false)
      }
    };
  }, []);

  const subscribe = (destination, callback) => {
    console.log("WebSocketContext Subscribing to : ", destination, stompClient.current);
    if (stompClient.current && stompClient.current.connected) {
      stompClient.current.subscribe(destination, (msg) => {
        const message = JSON.parse(msg.body);
        console.log("WebSocketContext Subscribed to : ", destination, stompClient.current);
        callback(message);
      });
    }
  };

  const sendMessage = (destination, message) => {
    console.log("WebSocketContext sendMessage : ", destination, message, stompClient.current);
    if (stompClient.current && stompClient.current.connected) {
      stompClient.current.send(destination, {}, JSON.stringify(message));
    }
  };

  const ws = {
    stompClient: stompClient,
    sendMessage,
    subscribe,
    connected
  };

  return (
    <WebSocketContext.Provider value={ws}>{children}</WebSocketContext.Provider>
  );
};
