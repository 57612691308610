import { Box, ButtonProps, SxProps, Theme } from '@mui/material'
import React, { useState } from 'react'
import CBButton from './CBButton'
import CBMenu, { CBMenuOption } from './CBMenu'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';


interface CBMenuButtonProps extends ButtonProps {
    options: CBMenuOption[];
    selectedOption: CBMenuOption;
    setSelectedOption: (option : CBMenuOption) => void;
    buttonSx?: SxProps<Theme>;
}

const CBMenuButton : React.FC<CBMenuButtonProps> = ({
    options,
    selectedOption,
    setSelectedOption,
    buttonSx,
    ...props
}) => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const handleOpenMenu = (event : React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleSelectOption = (option : CBMenuOption) => {
        setSelectedOption(option)
        handleCloseMenu();
    };

  return (
    <Box>
            <CBButton
                variant="text"
                onClick={handleOpenMenu} 
                sx={{ ml:2,color: '#f6f6f6', ...buttonSx }}
                {...props}
                >
                {selectedOption?.label}
                <KeyboardArrowDownIcon/>
            </CBButton>
            <CBMenu
                target={anchorEl}
                options={options}
                onCloseMenu={handleCloseMenu}
                onItemSelect={handleSelectOption}
                checkIcon
                selectedOption={selectedOption}
            />
        </Box>
  )
}

export default CBMenuButton