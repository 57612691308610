export default ({ palette, spacing }) => {
    const radius = spacing(2);
    const size = spacing(4.5);
    const rightBgColor = "#10a0a07f";//palette.primary.main;
    const leftBgColor = "#373737";
    // if you want the same as facebook messenger, use this color '#09f'
    return {
      avatar: {
        width: size,
        height: size,
        borderRadius: '12px'
      },
      leftRow: {
        textAlign: 'left',
      },
      rightRow: {
        textAlign: 'right',
      },
      msg: {
        padding: spacing(1, 2),
        borderRadius: 4,
        // marginBottom: 4,
        display: 'inline-block',
        wordBreak: 'break-word',
        fontFamily:
          // eslint-disable-next-line max-len
          '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
        fontSize: '0.875rem',
        // color: palette.text.secondary
      },
      left: {
        borderTopRightRadius: radius,
        borderBottomRightRadius: radius,
        backgroundColor: leftBgColor,
      },
      right: {
        borderTopLeftRadius: radius,
        borderBottomLeftRadius: radius,
        backgroundColor: rightBgColor,
        color: palette.common.white,
      },
      leftFirst: {
        borderTopLeftRadius: radius,
      },
      leftLast: {
        borderBottomLeftRadius: radius,
      },
      rightFirst: {
        borderTopRightRadius: radius,
      },
      rightLast: {
        borderBottomRightRadius: radius,
      },
    };
  };