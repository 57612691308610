import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    questions: [],
    questionsMetadata: {}
}

export const questionSlice = createSlice({
    name: 'question',
    initialState,
    reducers: {
        setQuestions: (state, action) => {
            state.questions = [...(action?.payload ?? [])]
        },
        clearQuestionRedux: (state, action) => {
            state.questions = []
        },
        setQuestionAccepted: (state, action) => {
            console.log("setQuestionAccepted : ",action.payload);
            if(!action.payload || !state.questionsMetadata) return
            if(state.questionsMetadata[action.payload]){
                state.questionsMetadata[action.payload]['accepted'] = true
            } else {
                state.questionsMetadata[action.payload] = {accepted:true}
            }
            const dummyList = [...state.questions]
            dummyList.map(q => {
                if(q.id === action.payload){
                    q['accepted'] = true
                }
                return q;
            })
            state.questions = [...dummyList]
        }
    },
})

export const { 
    setQuestions,
    clearQuestionRedux, 
    setQuestionAccepted,
} = questionSlice.actions

export default questionSlice.reducer