import { Box, Skeleton, TableCell } from "@mui/material"


const RecentSubmissionsLoading = () => {

    return(
        <TableCell>
            <Skeleton variant="text" width={'20px'} sx={{ fontSize: '1rem' }} />
        </TableCell>
    )

}

export default RecentSubmissionsLoading;