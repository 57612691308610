import { Box } from "@mui/material";
import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import Split from 'react-split';
import { createTestSubmission } from "../../../../cbApp/apis/submissionApis";
import ConsoleControl from "../../../../cbApp/components/sections/editorSections/consoleControl";
import ProblemConsole from "../../../../cbApp/components/sections/editorSections/consoleSections/ProblemConsole";
import CBEditor from "../../../../common/components/CBEditor";
import CBTabPanel from "../../../../common/components/CBTabPanel";
import { languageModeMap } from "../../../../common/constants";
import { useAuth } from "../../../../common/context/AuthContext";
import { createAdminSubmitSubmission } from "../../../apis/adminSubmissionApis";
import { resetQuestionCode } from "../../../../cbApp/redux/editor";

function ProblemSolveEditorSection({ ...props }) {
    const splitRef = useRef();
    const [isConsoleOpen, setIsConsoleOpen] = useState(false)
    const [sizes, setSizes] = useState([100, 0]);
    const [collapsedIndex, setCollapsedIndex] = useState(null)
    const [output, setOutput] = useState("")
    const {selectedQuestion} = props
    const {currentUser} = useAuth();
    const {activeQuestion, status: contestStatus } = useSelector(state => state.contest)
    const { outputLoading, activeInputCases, runTestOuput, submissionType} = useSelector(state => state.submission)

    const handleConsole = () => {
        handleGetSizes()
        if (isConsoleOpen) setSizes([100, 0])
        else setSizes([50, 50])
        setIsConsoleOpen(!isConsoleOpen)
    }

    const handleGetSizes = () => {
        console.log("split ref", splitRef);
        if (splitRef.current && splitRef.current.split) {
            const sizes = splitRef.current.split.getSizes();
            console.log(sizes);
        }
    };

    const handleDrag = (newSizes) => {
        setSizes(newSizes);
    };

    const handleCompile = (e) => {
        console.log("handleCompile : ", props?.code);
        if (!isConsoleOpen) {
            handleConsole()
        }
        console.log("handleCompile : ", selectedQuestion?.sampleInput);
        createTestSubmission({
            problemId: selectedQuestion.id,
            languageId: languageModeMap?.find(item => item?.mode === props?.mode)?.value,
            "sourceCode": props?.code,
            "sampleInput": activeInputCases
        },
        "016eada8-9e07-4fbc-8ba7-b282da2fdd3e"
        )
    }

    const handleSubmit = (e) => {
        console.log("handleSubmit : ", props?.code);
        if (!isConsoleOpen) {
            handleConsole()
        }
        createAdminSubmitSubmission({
            problemId: selectedQuestion?.id,
            languageId: languageModeMap?.find(item => item?.mode === props?.mode)?.value,
            "sourceCode": props?.code,
        },
        "016eada8-9e07-4fbc-8ba7-b282da2fdd3e"
        )

    }


    return (
        <CBTabPanel sx={{ px: 0,width:'100%'}}>
            <Split gutterSize={(isConsoleOpen ? 8 : 0)} ref={splitRef} minSize={0} direction="vertical" style={{ height: 'calc(100vh - 220px)',width:'100%'}} sizes={sizes} onDrag={handleDrag}>
                <CBEditor
                    height={`${sizes[0]}%`}
                    width="100%"
                    mode={props?.mode ? props?.mode : "c_cpp"}
                    theme={props?.theme ? props?.theme : 'dracula'}
                    onChange={(e) => props?.onChange(e,props?.index)}
                    value={props?.code}
                />
                {isConsoleOpen ? <ProblemConsole output={runTestOuput} outputLoading={outputLoading} handleConsole={handleConsole} submissions={activeQuestion.submissions} activeInputCases={activeInputCases}  submissionType={submissionType}/> : <Box />}
            </Split>
            <ConsoleControl
                isConsoleOpen={isConsoleOpen}
                onClickConsole={handleConsole}
                onClickRun={handleCompile}
                onClickSubmit={handleSubmit}
                runDisabled={outputLoading}
                submitDisabled={outputLoading}
            />
        </CBTabPanel>
    )
}

export default ProblemSolveEditorSection
