import React from 'react';
import { Helmet } from 'react-helmet-async';
import { IMAGE_CODEBATTLE_META_LOGO } from '../constants';

interface SEOProps {
    title?: string;
    description?: string;
    name?: string;
    type?: string;
    url?: string;
    image?: string;
}

const CBSeo: React.FC<SEOProps> = ({
    title = "CodeBattle - Practice DSA with Friends",
    description = "Join CodeBattle and enhance your DSA coding skills with Friends. Compete, learn, and become a better programmer.",
    name = "@CodeBattle",
    type = "website",
    url = `${window.location.href}` || "https://codebattle.in",
    image = `${IMAGE_CODEBATTLE_META_LOGO}`
}) => {
    return (
        <Helmet>
            {/* Standard metadata tags */}
            <title>{title}</title>
            <meta name="description" content={description} />
            <meta name="keywords" content="CodeBattle, DSA, coding challenges, programming, algorithms, friends, problem solving" />
            <meta name="author" content="CodeBattle Team" />

            {/* Open Graph / Facebook tags */}
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:url" content={url} />
            <meta property="og:image" content={image} />
            <meta property="og:site_name" content='CodeBattle' />
            <meta property="og:type" content={type} />


            {/* Twitter tags */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:site" content={name} />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:image" content={image} />

            {/* Additional SEO enhancements */}
            <meta name="robots" content="index, follow" />
            <link rel="canonical" href={url} />
        </Helmet>
    );
};

export default CBSeo;
