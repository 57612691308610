import { PostHogConfig } from "posthog-js";
import { PostHogProvider } from 'posthog-js/react';
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import App from "./App";
import { store } from "./common/redux/store";
import "./index.css";
import * as serviceWorker from "./serviceWorker";

const options: Partial<PostHogConfig> = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
  person_profiles: "identified_only",
  capture_pageview: false
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

if (process.env.REACT_APP_NODE_ENV === "production")
  console.log = function no_console() { };

root.render(
  <Provider store={store}>
    <PostHogProvider
      apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
      options={options}
    >
      <App />
    </PostHogProvider>
  </Provider>
);

serviceWorker.register();
