import { Box, Typography } from '@mui/material';

const CBWIP = () => {
    return (
        <Box sx={{ overflow: "auto", height: 'calc(100vh - 94px)', typography: 'body2', px: 2.5, py: 2, fontWeight: 500, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Typography>Work in progress 🚧</Typography>
        </Box>
    )
}

export default CBWIP