import { createSlice } from '@reduxjs/toolkit'
import { DEFAULT_ACTIVE_ROOM_SPECIAL_BANNER } from '../remoteConstants'

const initialState = {
    activeRoomBanner: DEFAULT_ACTIVE_ROOM_SPECIAL_BANNER
}

export const remoteConfig = createSlice({
    name: 'remoteConfig',
    initialState,
    reducers: {
        setActiveRoomBanner: (state, action) => {
            if(action?.payload)
                state.activeRoomBanner = action?.payload
        }
    },
})

export const { setActiveRoomBanner } = remoteConfig.actions

export default remoteConfig.reducer