import LeaderboardIcon from '@mui/icons-material/Leaderboard'
import { Box } from '@mui/material'
import { useState } from 'react'
import CBButton from '../../../../common/components/CBButton'
import { glowingAnimation } from '../../../../common/constants'
import { useAuth } from '../../../../common/context/AuthContext'
import { nextContest, readyUserContest } from '../../../apis/contestApis'
import { CONTEST_STATUS, ROOM_TYPE, USER_STATUS } from '../../../constants'
import ScoreboardCard from './ScoreboardCard'
import { useSelector } from 'react-redux'


function LobbyActionButton({ userStatus, contest_id, contestStatus }) {
  const [showScoreboard, setShowScoreboard] = useState(false);
  const { currentUser } = useAuth();
  const { roomType } = useSelector((state) => state.contest);

  const onReadyClick = () => {
    readyUserContest(contest_id)
  }

  const onUnReadyClick = () => {

  }

  const onScoreboardClick = () => {
    setShowScoreboard(true)
  }

  const onReJoinClick = () => {
    nextContest(currentUser)
  }

  const onAllProblemsClick = () => {
    window.open("/problems","_blank")
  }

  return (
    <Box sx={{ display: 'flex', alignSelf: 'stretch' }}>

      {(contestStatus === CONTEST_STATUS.STARTED || contestStatus === CONTEST_STATUS.ENDED) ?
        <Box sx={{ width: '100%', display: 'flex', mt: 2 }}>
          <CBButton sx={{ alignSelf: 'normal', flex: 1 }} variant="outlined" onClick={() => onScoreboardClick()} endIcon={<LeaderboardIcon />}>Scoreboard </CBButton>
          {contestStatus === CONTEST_STATUS.ENDED ? ( roomType === ROOM_TYPE.CONTEST? <CBButton sx={{ alignSelf: 'normal', flex: 1, ml: 2 }} onClick={() => onReJoinClick()}>ReJoin 🔥</CBButton>: <CBButton sx={{ alignSelf: 'normal', flex: 1, ml: 2 }} onClick={() => onAllProblemsClick()}>All Problems 🔥</CBButton>) : null}
        </Box>
        :
        (contestStatus === CONTEST_STATUS.CREATED || contestStatus === CONTEST_STATUS.STARTING) ?
          (userStatus === USER_STATUS.IN_CONTEST_READY ?
            <CBButton disabled sx={{ alignSelf: 'normal', mt: 2, width: '100%' }} variant="contained">You are Ready ✅</CBButton>
            :
            <CBButton sx={{ alignSelf: 'normal', mt: 2, width: '100%' }} variant="contained" onClick={() => onReadyClick()}>Ready!!!!! 💪</CBButton>)
          :
          null
      }
      <ScoreboardCard showScoreboard={showScoreboard} setShowScoreboard={setShowScoreboard} />
    </Box>
  )
}

export default LobbyActionButton
