import {
  AppBar,
  Box,
  IconButton,
  Toolbar,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import { useState } from "react";
import { ReactComponent as CodeBattleIcon } from "../assets/cb_full_logo.svg";
import { useAuth } from "../context/AuthContext";

import {
  NavButton,
  NavLogin,
  OPTION_ROUTES,
  SOCIAL_OPTION_ROUTES,
  handleOnRouteClick,
} from "../utils/NavOptionRoutes";
import AppDrawer from "./AppDrawer";
import Login from "./login";

export default function NavBar({ sx }) {
  const [showLogin, setShowLogin] = useState(false);
  const { currentUser } = useAuth();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));

  const onLoginClick = () => {
    setShowLogin(true);
  };

  const handleCloseLogin = () => {
    setShowLogin(false);
  };

  return (
    <>
      <Login showLogin={showLogin} handleCloseLogin={handleCloseLogin} />

      <AppBar
        sx={{ maxHeight: "56px", bgcolor: "#282828", ...sx }}
        position="static"
      >
        <Toolbar
          variant="dense"
          sx={{
            height: "50px",
            bgcolor: "#282828",
            display: "flex",
            justifyItems: "center",
            justifyContent: "space-between",
            ...sx,
          }}
        >
          <CodeBattleIcon
            onClick={() => window.location?.replace("/")}
            style={{ cursor: "pointer" }}
          />

          {isMobile ? (
            <AppDrawer setShowLogin={setShowLogin} />
          ) : (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              {OPTION_ROUTES.map((route) => (
                <div
                  onClick={() => handleOnRouteClick(route.path, route.external)}
                >
                  <NavButton
                    title={route.name}
                    isMobile={isMobile}
                    isSelected={window?.location?.pathname === route.path}
                  />
                </div>
              ))}
              <Divider
                orientation="vertical"
                flexItem
                variant="middle"
                sx={{ ml: 3 }}
              />

              <Box sx={{ ml: 3 }}>
                {SOCIAL_OPTION_ROUTES.map((route) => (
                  <IconButton
                    onClick={() =>
                      handleOnRouteClick(route.path, route.external)
                    }
                  >
                    {route.name}
                  </IconButton>
                ))}
              </Box>

              <NavLogin
                currentUser={currentUser}
                onLoginClick={onLoginClick}
                isMobile={isMobile}
              />
            </Box>
          )}
        </Toolbar>
      </AppBar>
    </>
  );
}
