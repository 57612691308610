import { createSlice } from '@reduxjs/toolkit'
import { initialProblemMetadata } from '../constants'

const initialState = {
    questions: [],
    selectedQuestion: null,
    selectedQuestionMetaData: initialProblemMetadata
}


export const questionSlice = createSlice({
    name: 'adminQuestion',
    initialState,
    reducers: {
        setAdminQuestions: (state, action) => {
            state.questions = [...(action?.payload ?? [])]
            state.selectedQuestion = initialState.selectedQuestion
            state.selectedQuestionMetaData = initialState.selectedQuestionMetaData
        },

        setSelectedQuestion: (state, action) => {
            state.selectedQuestion = action?.payload
        },

        setSelectedQuestinStatus: (state,action) => {
            if(state.selectedQuestion && state.selectedQuestion?.status){
                state.selectedQuestion.status = action?.payload
            }
        },

        setSelectedQuestionMetaData: (state, action) => {
            if(action?.payload?.length > 0){
                state.selectedQuestionMetaData = action?.payload
            }
        },
    },
})

export const { setAdminQuestions,setSelectedQuestion,setSelectedQuestionMetaData, setSelectedQuestinStatus} = questionSlice.actions

export default questionSlice.reducer