import { createSlice } from '@reduxjs/toolkit'
import { CONTEST_STATUS, SUBMISSION_TYPE } from '../constants'

const initialState = {
    selectedQuestionId: "",
    activeQuestion: {},
    status: CONTEST_STATUS.IDLE,
    name: "",
    id: "",
    difficultyLevel: "",
    contestLength: 0,
    roomType: ""
}

export const contestSlice = createSlice({
    name: 'contest',
    initialState,
    reducers: {
        setSelectedQuestionId: (state, action) => {
            state.selectedQuestionId = action.payload
        },
        setActiveQuestion: (state, action) => {
            state.activeQuestion = action.payload
        },
        setStatus: (state, action) => {
            state.status = action.payload
        },
        setCreateContest: (state, action) => {
            state.id = action.payload.id
            state.name = action.payload.name
            state.status = action.payload.status
            state.difficultyLevel = action.payload.difficultyLevel
            state.contestLength = action.payload.contestLength
        },
        setJoinContest: (state, action) => {
            state.id = action.payload.id
            state.name = action.payload.name
            state.status = action.payload.status
            state.difficultyLevel = action.payload.difficultyLevel
            state.contestLength = action.payload.contestLength
        },
        setContestStarting: (state, action) => {
            state.status = CONTEST_STATUS.STARTING
        },
        setContestStarted: (state, action) => {
            state.status = action.payload.status
        },
        setContestEnded: (state, action) => {
            state.status = action.payload
        },
        clearContest: (state, action) => {
            state = initialState
        },
        setRoomType: (state,action) => {
            state.roomType = action.payload
        }
    },
})

export const { setSelectedQuestionId, setActiveQuestion, setStatus, setCreateContest, setContestStarting, setContestStarted, setJoinContest, clearContest, setContestEnded, setRoomType } = contestSlice.actions

export default contestSlice.reducer