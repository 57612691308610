import moment from 'moment-timezone';
import * as colors from '@mui/material/colors'

export const base64decode = (str) => {
    if (!str) return "";
    let decode = atob(str)?.replace(/[\xC0-\xFF][\x80-\xBF]*/g, (m) => {
      let code = m.charCodeAt(0) & 0x1F;
      for (let i = 1; i < m.length; i++) {
        code = (code << 6) | (m.charCodeAt(i) & 0x3F);
      }
      return String.fromCharCode(code);
    });
    try {
      decode = decodeURIComponent(escape(decode));
    } catch (error) {
      // Handle decoding errors gracefully
      console.error("Error decoding URI component:", error);
    }
    return decode;
  };
  

export const findValue = (obj, key) => Object.keys(obj).find(k => k === key);

export const updateTimeZone = (timeString) => {
  const utcTime = moment.utc(`01/01/2000 ${timeString}`, 'MM/DD/YYYY hh:mm A');
  const localTime = utcTime.local();
  const formattedTime = localTime.format('hh:mm A');
  return formattedTime;
};

export const getCurrentTime = () => {
  const localTime = moment.utc(); // Get the current local time
  const formattedTime = localTime.format('hh:mm A'); // Format the time
  return formattedTime;
}

export const getObjectColor = (index) => {
  index = index+1
  const colorKeys = Object.keys(colors);
  const colorIndex = index % colorKeys.length;
  const colorKey = colorKeys[colorIndex];
  return colors[colorKey][200];
};