import { BASE_ADDRESS, DIFFICULTY_SMALL, difficultyLevels } from "../common/constants";


//URLS
export const WS_BASE_ADDRESS = process.env.REACT_APP_WS_BASE_ADDRESS;
export const SOCK_JS = WS_BASE_ADDRESS+"/ws";

export const SUBMIT_PROBLEM = BASE_ADDRESS+"/api/solution/submit";
export const TEST_PROBLEM = BASE_ADDRESS+"/api/solution/test";
export const GET_SUBMISSIONS = BASE_ADDRESS+"/api/solution";
export const GET_USER_TIME_GRAPH = BASE_ADDRESS+"/api/solution/user/timeGraph";
export const GET_USER_RECENT_SUBMISSIONS = BASE_ADDRESS+"/api/solution/user/recent";
export const CREATE_CONTEST = BASE_ADDRESS+"/api/contest/create";
export const CREATE_PRACTICE_CONTEST = BASE_ADDRESS+"/api/contest/practice/create";
export const NEXT_CONTEST = BASE_ADDRESS+"/api/contest/next";
export const JOIN_CONTEST = BASE_ADDRESS+"/api/contest/join";
export const READY_CONTEST = BASE_ADDRESS+"/api/contest/ready";
export const LEAVE_CONTEST = BASE_ADDRESS+"/api/contest/leave";
export const UPDATE_USER_STATUS = BASE_ADDRESS+"/api/user/status";
export const GET_SCOREBOARD = BASE_ADDRESS+"/api/scoreboard";
export const GET_PROBLEM_METADATA = BASE_ADDRESS+"/api/problem/metadata";
export const GET_PRACTICE_PROBLEM_METADATA = BASE_ADDRESS+"/api/problem/published/metadata";
export const GET_PROBLEM_BY_ID = BASE_ADDRESS + "/api/problem/published";
export const GET_PUBLISHED_PROBLEMS = BASE_ADDRESS+"/api/problem/published/list";
export const GET_ALL_CONTESTS = BASE_ADDRESS+"/api/contest/getAll";
export const GET_ALL_LEADERBOARD_USERS = BASE_ADDRESS+"/api/leaderboard";
export const GET_USER_METADATA = BASE_ADDRESS+"/api/user/metadata";
export const POST_USER_GEO_INFO = BASE_ADDRESS+"/api/user/geo";
export const GET_CONTEST_CHAT = BASE_ADDRESS+"/api/chat/getAll";
export const GET_ALL_PUBLISHED_TOPIC_TAGS = BASE_ADDRESS+"/api/topic/all/published";


export const DEFAULT = 'default'



export const TAGS = [
    "implementation",
	"dp",
    "math",
    "greedy",
    "brute force",
    "data structures",
    "constructive algorithms",
    "dfs and similar",
    "sortings",
    "binary search",
    "graphs",
    "trees",
    "strings",
    "number theory",
    "geometry",
    "combinatorics",
    "two pointers",
    "dsu",
    "bitmasks",
    "probabilities",
    "shortest paths",
    "hashing",
    "divide and conquer",
    "games",
    "matrices",
    "flows",
    "string suffix structures",
    "expression parsing",
    "graph matchings",
    "ternary search",
    "meet-in-the-middle",
    "fft",
    "2-sat",
    "chinese remainder theorem",
    "schedules"
]


export const MESSAGE_ACTION = {
    ADD_PARTICIPANT: 'ADD_PARTICIPANT',
    UPDATE_PARTICIPANT: 'UPDATE_PARTICIPANT',
    CONTEST_STARTING: 'CONTEST_STARTING',
    CONTEST_STARTED: 'CONTEST_STARTED',
    CONTEST_ENDED: 'CONTEST_ENDED',
    CONTEST_CREATED: 'CONTEST_CREATED',
    SUBMITTED: 'SUBMITTED',
    SUBMISSION_ACCEPTED: 'SUBMISSION_ACCEPTED',
    LOAD_MESSAGES: 'LOAD_MESSAGES',
    NEW_MESSAGE:'NEW_MESSAGE'
}

export const CONTEST_STATUS = {
    IDLE: 'IDLE',
    CREATED: 'CREATED',
    STARTING: 'STARTING',
    STARTED: 'STARTED',
    ENDED: 'ENDED'
}

export const USER_STATUS = {
    IDLE: 'IDLE',
    IN_CONTEST: 'IN_CONTEST',
    IN_CONTEST_READY: 'IN_CONTEST_READY',
    IN_CONTEST_STARTED: 'IN_CONTEST_STARTED'
}

export const ROOM_TYPE = {
    PRACTICE: 'PRACTICE',
    CONTEST: 'CONTEST'
}

export const problemCountByContestDifficulty = (contestDifficulty) => {
    const problemCountMap = { 'EASY': 0, 'MEDIUM': 0, 'HARD': 0 }
    switch (contestDifficulty) {
        case DIFFICULTY_SMALL.EASY:
            return { 'EASY': 3, 'MEDIUM': 0, 'HARD': 0 }
        case DIFFICULTY_SMALL.MEDIUM:
            return { 'EASY': 1, 'MEDIUM': 2, 'HARD': 0 }
        case DIFFICULTY_SMALL.HARD:
            return { 'EASY': 0, 'MEDIUM': 2, 'HARD': 1 }
        default:
            return problemCountMap;
    }
}

export const DIFFICULTY_BY_VALUE = {
    EASY : 1,
    MEDIUM : 2,
    HARD : 3,
}