import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'
import NavBar from '../common/components/NavBar'
import AdminPage from './pages/adminPage'
import ProblemPage from './pages/problemPage'
import CreateProblemPage from './pages/createProblemPage'

function AdminApp() {
    return (
        <div className="AdminApp">
                <NavBar sx={undefined} />
                <Routes>
                    <Route path='/' element={<AdminPage/>} />
                    <Route path="/problems/:problem_id" element={<ProblemPage/>} />
                    <Route path="/createProblem" element={<CreateProblemPage/>} />
                </Routes>
        </div>
    )
}

export default AdminApp