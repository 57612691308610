import { Paper, Box } from '@mui/material';
import { FC, ReactNode, SetStateAction, useState } from 'react';
import CBCurvedTabs from './CBCurvedTabs';
import TabPanel from './TabPanel';

export interface CBSectionProps {
    tabs: CBSectionTabProps[]
    initalTabIndex: number
    end?: ReactNode[]
}

export interface CBSectionTabProps {
    key: string
    node: ReactNode
}

const CBCurvedTabSection:FC<CBSectionProps> = ({tabs,initalTabIndex,end}) => {

    const [key, setKey] = useState(initalTabIndex)

    const onChange = (_e: any,newKey: SetStateAction<number>) => {
        console.log(newKey);
        setKey(newKey);
    }

    return (
        <Box sx={{backgroundColor:'#282828'}}>
            <CBCurvedTabs value={key} onChange={onChange} tabs={tabs.map(tab => tab.key)}>
                <Box sx={{ flex: 1, display: 'flex', justifyContent: 'end',alignItems:'center' }}>
                    {end}
                </Box>
            </CBCurvedTabs>
            {tabs.map((tab, index) => {
                return (<TabPanel key={index} value={key} index={index}>
                    {tab.node}
                </TabPanel>)
            })}
        </Box>
    )
}

export default CBCurvedTabSection