import { SearchOutlined } from "@mui/icons-material";
import { Box, IconButton, InputBase, TextField } from "@mui/material";
import { useState } from "react";

export default function CBSearch({
  placeholder,
  onQueryChange,
  searchQuery,
  sx,
}) {
  return (
    <Box
      component="form"
      sx={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        backgroundColor: "#282828",
        borderRadius: 2,
        ...sx,
      }}
    >
      <SearchOutlined  sx={{ color: "#eff2f699",ml:1 }}/>
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder={placeholder || "Search"}
        onChange={(e) => onQueryChange(e.target.value)}
        value={searchQuery}
        size="small"
      />
    </Box>
  );
}
