import React, { useState, useEffect } from 'react'
import { Box, Chip, IconButton, ListItem, Stack, TextField, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
import { useSelector, useDispatch } from 'react-redux'
import CBTabPanel from '../../../../../common/components/CBTabPanel';
import CBChip from '../../../../../common/components/CBChip';
import CBTextField from '../../../../../common/components/CBTextField';
import { setActiveInputCases } from '../../../../../common/redux/submission';

export default function TestcaseTabPanel({size,sampleInputs}) {
    const dispatch = useDispatch();
    const [selectedCase, setSelectedCase] = useState(0);


    const handleDelete = (index) => {
        const newSampleInputs = [...sampleInputs];
        newSampleInputs.splice(index, 1);
        dispatch(setActiveInputCases(newSampleInputs))
        const nextFocus = (index % newSampleInputs.length)
        console.log("handleDelete nextFocus ", nextFocus);
        setSelectedCase(nextFocus)
    }

    const handleOnClick = (index) => {
        setSelectedCase(index)
    }

    const handleAddCases = () => {
        const nextFocus = sampleInputs.length
        dispatch(setActiveInputCases([...sampleInputs, sampleInputs[selectedCase]]))
        setSelectedCase(nextFocus)
    }

    const handleOnChangeValue = (e) => {
        const cases = [...sampleInputs]
        cases[selectedCase] = e?.target?.value;
        dispatch(setActiveInputCases(cases))
    }

    return (
        <CBTabPanel sx={{overflow:'auto', height:'100%', maxHeight:`${size}px`}}>
        <Box sx={{ justifyContent: 'space-around', overflow:'auto' }}>
            <Stack direction="row" spacing={1} alignItems="center">
                {sampleInputs && sampleInputs.map((data, index) => {
                    return (
                        <ListItem key={index} sx={{ width: "auto", p: 0 }}>
                            <CBChip
                                sx={{ bgcolor: selectedCase !== index && 'transparent' }}
                                label={"Case " + index}
                                clickable
                                onDelete={() => handleDelete(index)}
                                onClick={() => handleOnClick(index)}
                            />
                        </ListItem>
                    );
                })}
                <IconButton onClick={(e) => handleAddCases(e)}><AddIcon fontSize="small" /></IconButton>
            </Stack>
            <CBTextField
                value={sampleInputs[selectedCase]}
                onChange={(e) => handleOnChangeValue(e)}
            />
        </Box>
        </CBTabPanel>
    );
}
