import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, IconButton, Tooltip } from '@mui/material';
import { FC } from 'react';
import CBButton from '../../../../common/components/CBButton';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import LanguageMenu from '../../LanguageMenu';
import { resetQuestionCode } from '../../../redux/editor';

interface ConsoleControlProps {
    isConsoleOpen: boolean;
    onClickConsole: () => void;
    onClickRun: () => void;
    onClickSubmit: () => void;
    handleResetCode: () => void;
    runDisabled: boolean;
    submitDisabled: boolean;
}

const ConsoleControl: FC<ConsoleControlProps> = ({ isConsoleOpen, onClickConsole, onClickRun, onClickSubmit, handleResetCode, runDisabled, submitDisabled }) => {

    return (
        <Box sx={{ bgcolor: '#282828', display: 'flex', justifyContent: 'space-between', px: 2.5, pt: 1, }}>
            <Box sx={{ display: 'flex' }}>
                <CBButton color="secondary" onClick={() => onClickConsole()}>
                    console&nbsp;{isConsoleOpen ? <KeyboardArrowDownIcon fontSize='small' sx={{ color: '#8a8a8a' }} /> : <KeyboardArrowUpIcon fontSize='small' sx={{ color: '#8a8a8a' }} />}
                </CBButton>
                <Tooltip title="Reset Code">
                    <IconButton size='small' sx={{ ml: 1, color: 'rgba(255, 255, 255, 0.7)' }} onClick={() => handleResetCode()}>
                        <RestartAltIcon />
                    </IconButton>
                </Tooltip>
                <LanguageMenu key={0} />
            </Box>
            <Box>
                <CBButton disabled={runDisabled} color="secondary" onClick={() => onClickRun()} toolTip='Ctrl + '>Run</CBButton>
                <CBButton disabled={submitDisabled} onClick={() => onClickSubmit()} sx={{ ml: 2 }} toolTip='Ctrl + Enter'>Submit</CBButton>
            </Box>
        </Box>
    )
}

export default ConsoleControl