import { Box, Typography } from '@mui/material'
import CBCard from '../../../../../common/components/CBCard'

function WrongConsoleOutput({output}) {
    return (
        <Box sx={{ justifyContent: 'space-around' }}>
            <Typography sx={{}} variant="h6" color="error.main">Wrong Answer !</Typography>
            {output.status?.stdin && <CBCard label="Input" sx={{ mt: 2 , px:1.5,py:1.25}}>{output.status?.stdin}</CBCard>}
            {output.status?.stdout && <CBCard label="Output" sx={{ mt: 2 , px:1.5,py:1.25}}>{output.status?.stdout}</CBCard>}
            {output.status?.expectedOutput && <CBCard label="Expected" sx={{ mt: 2 , px:1.5,py:1.25}}>{output.status?.expectedOutput}</CBCard>}
        </Box >
    )
}

export default WrongConsoleOutput
