import { Modal, ModalProps, Box, SxProps, Theme, IconButton, Typography } from '@mui/material';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';

interface CBModelProps extends Omit<ModalProps, 'children'> {
    children: React.ReactNode;
    sx?: SxProps<Theme>; // Allow custom styling
}

const CBModel: React.FC<CBModelProps> = ({ open, onClose, title, children, sx, ...props }) => {
    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backdropFilter: 'blur(7px)',
                outline: 'none',
            }}
            {...props}
        >
            <Box sx={{
                bgcolor: '#1d1d1d',
                borderRadius: 4,
                overflow: 'auto',
                p: 3,
                width: { xl: '50%', lg: '50%', md: '60%', sm: '90%', xs: '90%' },
                maxHeight: '660px', minHeight: '400px',
                maxWidth: '652px',
                position: 'relative',
                ...sx
            }}>
                <IconButton
                    aria-label="close"
                    onClick={(event) => onClose && onClose(event, 'backdropClick')}
                    sx={(theme) => ({
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        zIndex: 1,
                        color: theme.palette.grey[500],
                    })}
                >
                    <CloseIcon />
                </IconButton>
                {title && (
                    <Typography variant="h5" sx={{ color: '#fff', p: 2 }}>
                        {title}
                    </Typography>
                )}
                {children}
            </Box>
        </Modal>
    );
};

export default CBModel;
