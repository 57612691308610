import { Button, ButtonProps, Tooltip } from '@mui/material';
import { FC } from 'react';

interface CBButtonProps extends ButtonProps {
  toolTip?: string; // Mark toolTip as optional
}

const CBButton: FC<CBButtonProps> = ({ children, sx, toolTip, ...props }) => {

  // Create the button element
  const button = (
    <Button sx={{
        boxShadow: 'none',
        textTransform: 'capitalize',
        borderRadius: 2,
        ...sx,
      }} variant="contained" {...props}>
      {children}
    </Button>
  );

  // Conditionally wrap the button with Tooltip
  return toolTip ? <Tooltip title={toolTip}>{button}</Tooltip> : button;
};

export default CBButton;
