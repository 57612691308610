import { Box, LinearProgress, Typography } from "@mui/material";
import { DIFFICULTY_COLOR } from "../../../../common/constants";

const UserMetaDataProgress = ({ title, solved, total, level }) => {

    const percent = (total != 0) ? (((solved || 0) / total) * 100) : 0

    const getDifficultyColor = (difficultyLevel) => {
        return DIFFICULTY_COLOR[difficultyLevel]
    }

    return (
        <Box>
            <Box direction='row' sx={{ mt: 2, display: 'flex' }} justifyContent='space-between' alignItems={'end'}>
                <Typography fontSize='0.75rem' flex={1} color='#eff2f699'>{title}</Typography>
                <Box sx={{ display: 'flex', flex: 1, justifyContent: 'center' }} alignItems={'end'}>
                    <Typography variant="body2" fontWeight='medium'>{solved}</Typography>
                    <Typography fontSize='0.75rem' color='#eff2f699' sx={{ml:0.5}}>/{total}</Typography>
                </Box>
                <Typography sx={{ display: 'flex', justifyContent: 'end' }} flex={1} fontSize='0.75rem' color='#eff2f699'>{percent.toFixed(1)}%</Typography>
            </Box>

            <LinearProgress variant="determinate" value={percent}
                sx={{
                    mt: 0.5, height: '8px', borderRadius: '5px', backgroundColor: '#3e3e3e',
                    '& .MuiLinearProgress-bar': { backgroundColor: getDifficultyColor(level), borderRadius: '5px' }
                }} />
        </Box>
    )
}

export default UserMetaDataProgress;