import { Card, Stack, Typography } from "@mui/material"



const UserMetaDataCard = ({ title, value, subValue, children, sx }) => {

    return (
        <Card sx={{
            bgcolor: '#3e3e3e',
            flex: 1,
            direction: 'row',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            p: 2,
            borderRadius: 2,
            ...sx
        }} elevation={0}>
            <Stack direction='column' alignItems='start' justifyContent='center' sx={{ height: '100%' }}>
                {value ?
                    <Stack direction='row'>
                        <Typography fontSize='1.5rem' lineHeight='1.5rem' fontWeight='600' >{value}</Typography>
                        <Typography fontSize='1rem' lineHeight='1.5rem' fontWeight='100' sx={{ ml: 0.5, mt: 0.2 }} >{subValue}</Typography>
                    </Stack> :
                    <Typography fontSize='1.5rem' lineHeight='1.5rem' fontWeight='600' >{value}</Typography>}

                <Typography fontSize='1rem' fontWeight='100' color='#eff2f699' sx={{ mt: 0.5 }}>{title}</Typography>
            </Stack>

            {children}
        </Card>
    )
}

export default UserMetaDataCard