import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { getProblemByID } from '../apis/adminApis';
import { useParams } from "react-router-dom";
import CBTabPanel from '../../common/components/CBTabPanel';
import CBProblemTemplate from '../../common/components/CBProblemTemplate';

const ProblemTabPanel = ({selectedQuestion}) => {
    
    return (

        <div>
            {
            selectedQuestion ?
                <CBTabPanel sx={{ overflow: 'auto' }}>
                    <CBProblemTemplate problem={selectedQuestion} problemIndex={0} totalProblems={1} setProblemIndex={() => {}}/>
                </CBTabPanel> : ""}
        </div>
    );
}

export default ProblemTabPanel