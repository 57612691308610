import Axios from "axios"
import { toast } from "react-toastify"
import { setError } from "../../cbApp/redux/error"
import { store } from "../../common/redux/store"
import { setActiveInputCases } from "../../common/redux/submission"
import { GET_ALL_PROBLEMS, GET_ALL_TOPIC_TAGS, GET_PROBLEM, GET_PROBLEM_METADATA, LANGUAGE_ID_CPP, PROBLEM_CREATE, PROBLEM_UPDATE, PROBLEM_UPDATE_METADATA, PROBLEM_UPDATE_STATUS } from "../constants"
import { setAdminQuestions, setSelectedQuestinStatus, setSelectedQuestion, setSelectedQuestionMetaData } from "../redux/adminQuestions"
import { setTopics } from "../redux/topic"

const headers = {
    'Content-Type': "application/json; charset=utf-8",
    "accept": "application/json"
}

const multipartHeaders = {
    'Content-Type': "multipart/form-data",
    "accept": "application/json"
}


export const getAllTopicTags = () => {
    const targetUrl = GET_ALL_TOPIC_TAGS
    const requestOptions = {
        method: 'GET',
        headers: headers
    };

    Axios.get(targetUrl, requestOptions)
        .then(
            (result) => {
                console.log("getAllTopicTags", result);
                store.dispatch(setTopics(result.data));
            },
            (error) => {
                console.log("Error while getAllTopicTags : ", error);
                store.dispatch(setError({
                    message: error?.response?.data?.message,
                    httpStatus: error?.response?.status,
                    code: error?.response?.data?.code,
                    shouldRedirect: false,
                }));
            }
        )
}

export const getAllProblems = () => {
    const targetUrl = GET_ALL_PROBLEMS
    const requestOptions = {
        method: 'GET',
        headers: headers
    };

    Axios.get(targetUrl, requestOptions)
        .then(
            (result) => {
                console.log("allQuestions", result);
                store.dispatch(setAdminQuestions(result.data));
            },
            (error) => {
                console.log("Error while getAllProblems : ", error);
                store.dispatch(setError({
                    message: error?.response?.data?.message,
                    httpStatus: error?.response?.status,
                    code: error?.response?.data?.code,
                    shouldRedirect: false,
                }));
            }
        )
}

export const getProblemByID = (problem_id) => {
    const targetUrl = GET_PROBLEM + "/" + problem_id
    const requestOptions = {
        method: 'GET',
        headers: headers
    };
    console.log("getProblemByID :targetUrl", targetUrl);

    Axios.get(targetUrl, requestOptions)
        .then(
            (result) => {
                console.log("getProblemByID :problem_id", problem_id);
                console.log("getProblemByID", result);
                store.dispatch(setSelectedQuestion(result.data));
                store.dispatch(setActiveInputCases(result?.data?.sampleInput))
            },
            (error) => {
                console.log("Error while getProblemByID : ", error);
                store.dispatch(setError({
                    message: error?.response?.data?.message,
                    httpStatus: error?.response?.status,
                    code: error?.response?.data?.code,
                    shouldRedirect: false,
                }));
            }
        )
}

export const getMetaDataByProblem = (problem_id) => {
    const targetUrl = GET_PROBLEM_METADATA + "/" + problem_id
    const requestOptions = {
        method: 'GET',
        headers: headers
    };
    console.log("getMetaDataByProblem :targetUrl", targetUrl);

    Axios.get(targetUrl, requestOptions)
        .then(
            (result) => {
                console.log("getMetaDataByProblem :problem_id", problem_id);
                console.log("getMetaDataByProblem", result);
                store.dispatch(setSelectedQuestionMetaData(result.data.metadata));
            },
            (error) => {
                console.log("Error while getMetaDataByProblem : ", error);
                store.dispatch(setError({
                    message: error?.response?.data?.message,
                    httpStatus: error?.response?.status,
                    code: error?.response?.data?.code,
                    shouldRedirect: false,
                }));
            }
        )
}


export const createProblem = (problem, user) => {
    const toastId = toast.loading("Please wait...")
    const targetUrl = PROBLEM_CREATE
    const requestOptions = {
        method: 'POST',
        headers: multipartHeaders
    };

    const formData = new FormData();
    formData.append("problemStatement", problem.problemStatement)
    formData.append("problemTitle", problem.problemTitle)
    formData.append("inputSpecification", problem.inputSpecification)
    formData.append("outputSpecification", problem.outputSpecification)
    formData.append("constraints", problem.constraints)
    formData.append("timeLimit", problem.timeLimit)
    formData.append("memoryLimit", problem.memoryLimit)
    formData.append("difficultyLevel", problem.difficultyLevel)
    formData.append("sampleInput", problem.sampleInput)
    formData.append("sampleOutput", problem.sampleOutput)
    formData.append("tags", problem.tags)
    formData.append("authorId", problem.user)
    formData.append("explanation", problem.ioExplanation)
    formData.append("idealSolution", problem.idealSolution)
    formData.append("idealSolutionLanguageId", LANGUAGE_ID_CPP)
    formData.append("testCaseGenerator", problem.testCaseGenerator)

    if (problem.fileInputTestCases) {
        for (let i = 0; i < problem.fileInputTestCases.length; i++) {
            const file = problem.fileInputTestCases[i];
            formData.append('fileInputTestCases', file);
        }
    }

    if (problem.fileOutputTestCases) {
        for (let i = 0; i < problem.fileOutputTestCases.length; i++) {
            const file = problem.fileOutputTestCases[i];
            formData.append('fileOutputTestCases', file);
        }
    }

    console.log("createProblem :problem", formData, user);
    Axios.post(targetUrl, formData, requestOptions)
        .then(
            (result) => {
                console.log("createProblem : ", result.data);
                window.location.pathname = ("admin/problems/" + result.data.id)
                toast.update(toastId, { render: "Data saved", type: "success", isLoading: false, autoClose: 1000 });
            },
            (error) => {
                console.log("Error while createProblem : ", error);
                store.dispatch(setError({
                    message: error?.response?.data?.message,
                    httpStatus: error?.response?.status,
                    code: error?.response?.data?.code,
                    shouldRedirect: false,
                }));
            }
        )
}


export const updateProblem = (problem, user) => {
    const toastId = toast.loading("Please wait...")
    const targetUrl = PROBLEM_UPDATE
    const requestOptions = {
        method: 'POST',
        headers: multipartHeaders
    };

    const formData = new FormData();
    formData.append("id", problem.id)
    formData.append("problemStatement", problem.problemStatement)
    formData.append("problemTitle", problem.problemTitle)
    formData.append("inputSpecification", problem.inputSpecification)
    formData.append("outputSpecification", problem.outputSpecification)
    formData.append("constraints", problem.constraints)
    formData.append("timeLimit", problem.timeLimit)
    formData.append("memoryLimit", problem.memoryLimit)
    formData.append("difficultyLevel", problem.difficultyLevel)
    formData.append("sampleInput", problem.sampleInput)
    formData.append("sampleOutput", problem.sampleOutput)
    formData.append("tags", problem.tags)
    formData.append("authorId", user)
    formData.append("explanation", problem.ioExplanation)
    formData.append("idealSolution", problem.idealSolution)
    formData.append("idealSolutionLanguageId", LANGUAGE_ID_CPP)
    formData.append("testCaseGenerator", problem.testCaseGenerator)

    if (problem.fileInputTestCases) {
        for (let i = 0; i < problem.fileInputTestCases.length; i++) {
            const file = problem.fileInputTestCases[i];
            formData.append('fileInputTestCases', file);
        }
    }
    if (problem.fileOutputTestCases) {
        for (let i = 0; i < problem.fileOutputTestCases.length; i++) {
            const file = problem.fileOutputTestCases[i];
            formData.append('fileOutputTestCases', file);
        }
    }

    Axios.post(targetUrl, formData, requestOptions)
        .then(
            (result) => {
                console.log("updateProblem : ", result);
                toast.update(toastId, { render: "Data saved", type: "success", isLoading: false, autoClose: 1000 });
            },
            (error) => {
                console.log("Error while getProblemByID : ", error);
                store.dispatch(setError({
                    message: error?.response?.data?.message,
                    httpStatus: error?.response?.status,
                    code: error?.response?.data?.code,
                    shouldRedirect: false,
                }));
            }
        )
}

export const updateProblemMetadata = (problemId, problemTitle, metadata) => {
    const targetUrl = PROBLEM_UPDATE_METADATA
    const requestOptions = {
        method: 'POST',
        headers: headers
    };

    const metadatas = []
    metadata.map(meta =>
        metadatas.push({
            "languageId": meta.languageId,
            "defaultCode": meta.defaultCode,
            "userCode": meta.userCode,
        })
    )

    const metadataPayload = {
        "problemTitle": problemTitle,
        "problemId": problemId,
        "metadata": metadatas,
    }

    console.log("updateProblemMetadata", metadataPayload);


    Axios.post(targetUrl, metadataPayload, requestOptions)
        .then(
            (result) => {
                console.log("updateProblemMetadata : ", result);
            },
            (error) => {
                console.log("Error while getProblemByID : ", error);
                store.dispatch(setError({
                    message: error?.response?.data?.message,
                    httpStatus: error?.response?.status,
                    code: error?.response?.data?.code,
                    shouldRedirect: false,
                }));
            }
        )
}

export const updateProblemStatus = (problemId, status) => {
    const targetUrl = PROBLEM_UPDATE_STATUS
    const requestOptions = {
        method: 'POST',
        headers: headers
    };
    const payload = {
        "problemId": problemId,
        "status": status,
    }

    console.log("updateProblemStatus", payload);

    Axios.post(targetUrl, payload, requestOptions)
        .then(
            (result) => {
                console.log("updateProblemStatus : ", result);
                store.dispatch(setSelectedQuestinStatus(payload.status))
            },
            (error) => {
                console.log("Error while getProblemByID : ", error);
                store.dispatch(setError({
                    message: error?.response?.data?.message,
                    httpStatus: error?.response?.status,
                    code: error?.response?.data?.code,
                    shouldRedirect: false,
                }));
            }
        )
}
