import CheckCircleIcon from '@mui/icons-material/CheckCircleOutlined';
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ThumbDownOutlinedIcon from '@mui/icons-material/ThumbDownOutlined';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import { Accordion, AccordionDetails, AccordionSummary, Box, Chip, IconButton, Stack, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';
import theme from '../../theme';
import { DIFFICULTY_COLOR } from '../constants';
import CBCard from './CBCard';
import CBSeo from './CBSeo';
import DifficultyLevelChip from './DifficultyLevelChip';

interface ProblemModel {
    problemTitle: string;
    difficultyLevel: 'EASY' | 'MEDIUM' | 'HARD';
    accepted: any;
    problemStatement: TrustedHTML;
    sampleInput: any;
    sampleOutput: any;
    ioExplanation: TrustedHTML;
    constraints: TrustedHTML;
    inputSpecification: TrustedHTML;
    outputSpecification: TrustedHTML;
    tags: [];
}

interface CBProblemTemplateProps {
    problem: ProblemModel;
    problemIndex: number;
    setProblemIndex: (problemIndex: number) => void;
    totalProblems: number;
}

export function createMarkup(value: TrustedHTML) {
    return { __html: value };
}

interface SubHeadingProps {
    children: ReactNode;
}

const SubHeading: FC<SubHeadingProps> = ({ children }) => {
    return <Typography variant="body2"><strong>{children}</strong></Typography>
}



const CBProblemTemplate: FC<CBProblemTemplateProps> = ({ problem, problemIndex, setProblemIndex, totalProblems }) => {

    const color = DIFFICULTY_COLOR[problem?.difficultyLevel] || theme.palette.success.main;
    const handleInfoClick = () => {
        window.open(`${process.env.REACT_APP_PROBLEM_FEEDBACK_URL}${problem?.problemTitle}`)
    }

    return (
        <Box sx={{ typography: 'body2' }}>
            {problem && problem.problemTitle &&
                <CBSeo
                    title={`${problem?.problemTitle} | CodeBattle`}
                    description={`Challenge yourself with this interview problem? ${problem?.problemTitle} - ${problem?.problemStatement} - Tags ${problem?.tags}`}
                />
            }
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box>
                    <Typography variant="h6" fontSize='1.125rem' lineHeight='1.75rem' fontWeight={600}>{problemIndex + 1}. {problem?.problemTitle}</Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    <IconButton aria-label="Previous" disabled={problemIndex <= 0} onClick={() => setProblemIndex(problemIndex - 1)}>

                        <ChevronLeftOutlinedIcon sx={{}} />
                    </IconButton>
                    <IconButton aria-label="Next" disabled={problemIndex >= (totalProblems - 1)} onClick={() => setProblemIndex(problemIndex + 1)}>

                        <ChevronRightOutlinedIcon sx={{}} />
                    </IconButton>

                </Box>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <DifficultyLevelChip difficultyLevel={problem?.difficultyLevel} />
                <IconButton size='small' sx={{ color: '#8a8a8a', ml: 1 }}><ThumbUpOutlinedIcon fontSize='small' /></IconButton>
                <IconButton size='small' sx={{ color: '#8a8a8a', ml: 1 }}><ThumbDownOutlinedIcon fontSize='small' /></IconButton>
                <Box sx={{ ml: 1 }}>
                    {problem?.accepted ? <CheckCircleIcon sx={{ color: 'success.main' }} /> : <CircleOutlinedIcon fontSize='small' sx={{ color: '#8a8a8a' }} />}
                </Box>
                <IconButton size='small' sx={{ color: '#8a8a8a', ml: 1 }} onClick={handleInfoClick}><InfoOutlinedIcon fontSize='small' /></IconButton>
            </Box>

            <Box className="questionContent" sx={{ mt: 2, typography: "body2", fontWeight: 500, lineHeight: 1.7 }}>

                <Box className="" dangerouslySetInnerHTML={{ __html: problem?.problemStatement }} />
                <Box sx={{ mt: 4 }}>
                    {problem?.sampleInput && problem?.sampleInput?.map((input: string, index: number) => {
                        return (
                            <Box key={index} sx={{ mb: 2 }}>
                                <SubHeading>Example {index + 1}:</SubHeading>
                                <Stack sx={{ mt: 1 }} spacing={1} direction="row">
                                    <CBCard label="Input" sx={{ flex: 1, color: "text.secondary" }}>{input}</CBCard>
                                    <CBCard label="Output" sx={{ flex: 1, color: "text.secondary" }}>{problem?.sampleOutput?.[index]}</CBCard>
                                </Stack>
                            </Box>
                        );
                    })}
                </Box>

                {problem?.ioExplanation &&
                    <Box sx={{ mt: 4 }} className="questionContent">
                        <SubHeading>Explaination:</SubHeading>
                        <Box sx={{ mt: 2 }} dangerouslySetInnerHTML={createMarkup(problem?.ioExplanation)} />
                    </Box>
                }
                {problem?.constraints &&
                    <Box sx={{ mt: 4 }} className="questionContent">
                        <SubHeading>Constraints:</SubHeading>
                        <Box sx={{ mt: 2 }} dangerouslySetInnerHTML={createMarkup(problem?.constraints)} />
                    </Box>}
                {problem?.inputSpecification &&
                    <Box sx={{ mt: 4 }} className="questionContent">
                        <SubHeading>Input Format:</SubHeading>
                        <Box sx={{ mt: 2 }} dangerouslySetInnerHTML={createMarkup(problem?.inputSpecification)} />
                    </Box>
                }
                {problem?.outputSpecification &&
                    <Box sx={{ mt: 4 }} className="questionContent">
                        <SubHeading>Output Format:</SubHeading>
                        <Box sx={{ mt: 2 }} dangerouslySetInnerHTML={createMarkup(problem?.outputSpecification)} />
                    </Box>
                }
                {problem?.tags && problem?.tags?.length > 0 &&
                    <Accordion sx={{ boxShadow: 'none', mt: 4 }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon sx={{ color: "text.secondary" }} />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            sx={{ p: 0 }}
                        >
                            <Typography color="text.secondary">Topic Tags</Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{ p: 0 }}>
                            {problem?.tags?.map(tag => {
                                return <Chip label={tag} sx={{ color: "text.secondary", mr: 2, mb: 2 }} />
                            })}
                        </AccordionDetails>
                    </Accordion>
                }
            </Box>
        </Box>
    )
}

export default CBProblemTemplate