import Axios from "axios";
import { setDefaultCodes, setEditorLoading } from "../redux/editor";
import {setQuestions } from "../redux/question";
import { setAllPublishedProblems } from "../redux/publishedProblems";
import { setError } from "../redux/error";
import { store } from '../../common/redux/store';
import { GET_PRACTICE_PROBLEM_METADATA, GET_PROBLEM_BY_ID,GET_PROBLEM_METADATA, GET_PUBLISHED_PROBLEMS } from "../constants";
import { setActiveQuestion } from "../redux/contest";

const headers =  {
    'Content-Type': "application/json; charset=utf-8",
    "accept": "application/json"
}

export const getProblemMetadata = (data) => {

    if(!data?.languageId) return;

    const contestId = store.getState().contest.id;
    if(!contestId){
        console.log("Contest ID not present");
        return;
    }

    const targetUrl = `${GET_PROBLEM_METADATA}/${contestId}/${data?.languageId}`

    const requestOptions = {
        headers: headers,
    };

    console.log("Fetching getProblemMetadata for : ",data.languageId);

    store.dispatch(setEditorLoading(true));

    Axios.get(targetUrl, requestOptions)
        .then(
            (result) => {
                console.log("getProblemMetadata result : ", result);
                if (result.status === 200) {
                    store.dispatch(setDefaultCodes(result.data))
                }
                store.dispatch(setEditorLoading(false));
            },
            (error) => {
                console.log(error);
                store.dispatch(setError({
                    message: error?.response?.data?.message,
                    httpStatus: error?.response?.status,
                    code: error?.response?.data?.code,
                    shouldRedirect: error?.response?.status == 404 ? true : false,
                }));
                store.dispatch(setEditorLoading(false));
            }
        )
}

export const getProblemMetadataById = (problemId) => {

  if(!problemId) return;

  const targetUrl = `${GET_PRACTICE_PROBLEM_METADATA}/${problemId}`

  const requestOptions = {
      headers: headers,
  };

  console.log("Fetching getProblemMetadataById for : ",problemId);

  store.dispatch(setEditorLoading(true));

  Axios.get(targetUrl, requestOptions)
      .then(
          (result) => {
              console.log("getProblemMetadataById result : ", result.data);
              if (result.status === 200) {
                store.dispatch(setDefaultCodes(result.data)) 
              }
              store.dispatch(setEditorLoading(false));
          },
          (error) => {
              console.log(error);
              store.dispatch(setError({
                  message: error?.response?.data?.message,
                  httpStatus: error?.response?.status,
                  code: error?.response?.data?.code,
                  shouldRedirect: error?.response?.status == 404 ? true : false,
              }));
              store.dispatch(setEditorLoading(false));
          }
      )
}

export const getProblemByID = (problemId) => {
  if (!problemId) return;

  const targetUrl = `${GET_PROBLEM_BY_ID}/${problemId}`;

  const requestOptions = {
    headers: headers,
  };

  console.log("Fetching getProblemByID for : ", problemId);

  Axios.get(targetUrl, requestOptions).then(
    (result) => {
      console.log("getProblemByID result : ", result);
      if (result.status === 200) {
        store.dispatch(setQuestions([result.data]));
        store.dispatch(setActiveQuestion(result.data));
      }
    },
    (error) => {
      console.log(error);
      store.dispatch(
        setError({
          message: error?.response?.data?.message,
          httpStatus: error?.response?.status,
          code: error?.response?.data?.code,
          shouldRedirect: error?.response?.status == 404 ? true : false,
        })
      );
      store.dispatch(setEditorLoading(false));
    }
  )
}

export const getPublishedProblems = () => {
    const targetUrl = GET_PUBLISHED_PROBLEMS
    const requestOptions = {
        method: 'GET',
        headers: headers
    };

    Axios.get(targetUrl, requestOptions)
        .then(
            (result) => {
                console.log("getPublishedProblems", result);
                if (result.status == 200) {
                    store.dispatch(setAllPublishedProblems(result.data));
                }
                else console.error("Something went wrong");
            },
            (error) => {
                console.log("Error while getPublishedProblems : ", error);
                store.dispatch(setError({
                    message: error?.response?.data?.message,
                    httpStatus: error?.response?.status,
                    code: error?.response?.data?.code,
                    shouldRedirect: false,
                }));
            }
        )
}