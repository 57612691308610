import { Badge, Box, Chip, ListItem, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import CBCard from '../../../../../common/components/CBCard';
import { base64decode } from '../../../../../utils';

const CaseContainer = ({index,color}) => {
    return (
        <Badge variant="dot" color={color} sx={{
            '& .MuiBadge-badge': {
                right: '100%',
                top: '50%',
              },
        }}>
            <Box sx={{paddingLeft: '10px'}}>{"Case " + index}</Box>
        </Badge>
    );
}

function SuccessConsoleOutput({output, sampleInputs}) {
    const [selectedCase, setSelectedCase] = useState(0);

    const handleOnClick = (index) => {
        setSelectedCase(index)
    }

    return (
        <Box sx={{ justifyContent: 'space-around' }}>
            <Typography sx={{}} variant="h6" color={output?.status?.id == 3 ? "success.main" : "error.main"}>{output?.status?.description + '!' || "Compilation Successful!"}</Typography>
            <Stack direction="row" spacing={1} alignItems="center" sx={{ mt: 2 }}>
                {sampleInputs && sampleInputs.map((data, index) => {
                    return (
                        <ListItem key={index} sx={{ width: "auto", p: 0 }}>
                            <Chip
                                label={<CaseContainer index={index} color={output?.submissions[index]?.status?.id == 3 ? "success" : "error"}/>}
                                sx={{borderRadius: 2, bgcolor: selectedCase !== index && 'transparent' }}
                                clickable
                                onClick={() => handleOnClick(index)}
                            />
                        </ListItem>
                    );
                })}
            </Stack>
            {sampleInputs[selectedCase] && <CBCard label="Input" sx={{ mt: 2 , px:1.5,py:1.25}}>{sampleInputs[selectedCase]}</CBCard>}
            <CBCard label="Output" sx={{ mt: 2 , px:1.5,py:1.25}}>{base64decode(output?.submissions[selectedCase]?.stdout)}</CBCard>
            <CBCard label="Expected Output" sx={{ mt: 2 , px:1.5,py:1.25}}>{base64decode(output?.submissions[sampleInputs.length + selectedCase]?.stdout)}</CBCard>
        </Box >
    )
}

export default SuccessConsoleOutput
