import theme from "../theme";
import { CBMenuOption } from "./components/CBMenu";


//URLS
export const BASE_ADDRESS = process.env.REACT_APP_BASE_ADDRESS;
export const SAVE_USER = BASE_ADDRESS + "/api/user";


export const BASE_AWS_CDN_URL = process.env.REACT_APP_BASE_AWS_CDN_URL;
export const IMAGE_CODEBATTLE_SCREENSHOT = BASE_AWS_CDN_URL + '/codebattle-screenshot.webp';
export const IMAGE_CODEBATTLE_META_LOGO = BASE_AWS_CDN_URL + '/codeBattle_meta_logo.png';

export const DIFFICULTY_LEVELS = [
    'EASY',
    'MEDIUM',
    'HARD'
]

export const DIFFICULTY_SMALL = {
    EASY: 'Easy',
    MEDIUM: "Medium",
    HARD: "Hard"
}

export const DIFFICULTY_COLOR = {
    EASY: theme.palette.success.main,
    MEDIUM: theme.palette.warning.main,
    HARD: theme.palette.error.main,
    CUSTOM: theme.palette.info.main
}

export const ERROR_CODE = {
    CON001: "CON001",
    CON002: "CON002",
    CON003: "CON003",
};


export const languageOptions = [
    { value: 54, label: 'C++', code: `` },
    { value: 62, label: 'Java', code: `` },
    { value: 71, label: 'Python3', code: `` }
];

export const languageModeMap = [
    { value: 54, mode: 'c_cpp' },
    { value: 62, mode: 'java' },
    { value: 71, mode: 'python' }
];

export const difficultyLevels = [
    { label: "EASY", value: "EASY" },
    { label: "MEDIUM", value: "MEDIUM" },
    { label: "HARD", value: "HARD" }
]

export const solveStatusMap = [
    { label: "Solved", value: "ACCEPTED" },
    { label: "Attempted", value: "ATTEMPTED" },
    { label: "Todo", value: "TODO" }
]

export const difficultyLevelSmall = [
    { label: "Easy", value: "EASY" },
    { label: "Medium", value: "MEDIUM" },
    { label: "Hard", value: "HARD" }
]

export const SUBMISSION_TYPE = {
    TEST: 'TEST',
    SUBMIT: "SUBMIT"
}


export const GET_BATCH_SUBMISSION_INTERVAL = 2000

export const shakeAnimation = {
    '@keyframes shake': {
        '0%': {
            transform: 'rotate(-15deg)',
        },
        '100%': {
            transform: 'rotate(15deg)',
        },
    },
    animation: 'shake 0.1s ease-in-out infinite;',
};


export const glowingAnimation = {
    '@keyframes glowing': {
        '0%': {
            backgroundColor: '#10a0a0',
            boxShadow: '0 0 5px #10a0a0',
        },
        '50%': {
            backgroundColor: '#10a0a0',
            boxShadow: '0 0 20px #10a0a0',
        },
        '100%': {
            backgroundColor: '#10a0a0',
            boxShadow: '0 0 5px #10a0a0',
        },
    },
    animation: 'glowing 1.5s linear infinite',
};

export const MAX_TOPIC_TAGS = 3;

export const EDITOR_FONT_SIZE_OPTIONS = [
    { value: '13px', label: '13px' },
    { value: '14px', label: '14px' },
    { value: '15px', label: '15px' },
    { value: '16px', label: '16px' },
    { value: '17px', label: '17px' },
    { value: '18px', label: '18px' },
    { value: '19px', label: '19px' },
    { value: '20px', label: '20px' },
    { value: '21px', label: '21px' }
];

export const EDITOR_KEY_BINDING_OPTIONS: CBMenuOption[] = [
    { value: 'Standard', label: 'Standard' },
    { value: 'vim', label: 'vim' },
    { value: 'emacs', label: 'emacs' },
]

export const EDITOR_TAB_SIZE_OPTIONS: CBMenuOption[] = [
    { value: 4, label: '4' },
    { value: 2, label: '2' },
]