import { store } from '../../common/redux/store';

export const processLoadMessage = (lobbyMessages) => {
    const participants = store.getState().participant.participants;
    if (lobbyMessages) {
        lobbyMessages.forEach(lobbyMessage => {
            lobbyMessage.user = participants.find(participant => participant.uid == lobbyMessage?.author)
        });
    }
    return lobbyMessages
}


export const processAddMessage = (lobbyMessage,currentUser) => {
    const participants = store.getState().participant.participants;
    if (lobbyMessage && lobbyMessage?.author) {
        if (lobbyMessage?.author == currentUser?.uid) return null
        lobbyMessage.user = participants.find(participant => participant.uid == lobbyMessage?.author)
    }
    return lobbyMessage
}