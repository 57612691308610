import { useSelector } from 'react-redux';
import CBCurvedTabSection from '../../../../common/components/CBCurvedTabSection';
import { useAuth } from '../../../../common/context/AuthContext';
import EditorTabPanel from './editorTabPanel';
import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import CBButton from '../../../../common/components/CBButton';
import { createPracticeContest } from '../../../apis/contestApis';
import Login from '../../../../common/components/login';


function PracticeEditorSection({problemId}) {
    const participants = useSelector(state => state.participant.participants)
    const { currentUser,loading } = useAuth();
    const [showLogin, setShowLogin] = useState(false)
    const [isHovered, setIsHovered] = useState(false);
    const currentParticipant = participants.find(p => p.uid === currentUser?.uid)?.displayName;
    
    function handleSolveClick(){
        if (!currentUser) {
            setShowLogin(true, () => {
                createPracticeContest({data:[problemId]},currentUser)
            });
          } else {
            createPracticeContest({problems:[problemId]},currentUser)
          }
        
    }

    const tabs = [
        { key: currentParticipant, node: <EditorTabPanel />}
    ]

    const overlayStyle = {
        position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: isHovered?'rgba(26,26,26,0.7)':'rgba(26,26,26,0)', 
                pointerEvents: 'auto',
                zIndex: 10,
                display: 'flex',       
                alignItems: 'center', 
                justifyContent: 'center'
    }

    return (
        <Box position="relative" 
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}>
            <CBCurvedTabSection tabs={tabs} initalTabIndex={0} /> 
            <Box
                sx={
                    overlayStyle
                }>
                {
                isHovered && <CBButton color='secondary' onClick={handleSolveClick}>
                                Start Solving
                            </CBButton>
                }
            </Box>
            <Login showLogin={showLogin} />
        </Box>
    )
}

export default PracticeEditorSection
