import { createSlice } from "@reduxjs/toolkit";
import { ErrorModel, ErrorModelPropTypes } from "../../common/models/ErrorModel";

const errorSlice = createSlice({
    name: "error",
    initialState: ErrorModel,
    reducers: {
      setError: (state, action) => {
        state.message = action.payload?.message;
        state.httpStatus = action.payload?.httpStatus;
        state.code = action.payload?.code;
        state.shouldRedirect = action.payload?.shouldRedirect;
      },
      clearError: (state) => {
        state.message = null;
        state.httpStatus = null;
        state.code = null;
        state.shouldRedirect = false;
      },
    },
  });

export const { setError, clearError } = errorSlice.actions;

errorSlice.propTypes = ErrorModelPropTypes;

export default errorSlice.reducer;