import React, { FC, ReactNode } from 'react'
import { Box, SxProps, Theme } from '@mui/material';

interface CBTabPanelProps {
    children: ReactNode,
    sx: SxProps<Theme> | undefined
};

const CBTabPanel:FC<CBTabPanelProps> = ({children,sx}) => {
  return (
    <Box sx={{ height: 'calc(100vh - 94px)', fontSize: '14px', py: 2, px:2.5, ...sx }}>
        {children}
    </Box>
  )
}

export default CBTabPanel