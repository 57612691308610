import Axios from "axios";
import { GET_SUBMISSIONS, SUBMIT_PROBLEM, TEST_PROBLEM } from "../constants";
import { setError } from "../redux/error";
import { setQuestionAccepted } from "../redux/question";
import { store } from '../../common/redux/store'
import { getScoreboard } from "./scoreboardApi";
import { GET_BATCH_SUBMISSION_INTERVAL, SUBMISSION_TYPE } from "../../common/constants";
import { resetOutput, setOutputLoading, setRunTestOutput, setSubmissionType } from "../../common/redux/submission";

const headers = {
    'Content-Type': "application/json; charset=utf-8",
    "accept": "application/json"
}

export const createTestSubmission = async (data,contestId, response, error) => {
    try {
        const targetUrl = TEST_PROBLEM

        if (!contestId) {
            console.log("Contest ID not present");
            store.dispatch(setError("CONTEST NOT FOUND"));
            return;
        }

        const payload = {
            "problemId": data.problemId,
            "languageId": data.languageId,
            "sourceCode": data.sourceCode,
            "sampleInput": data.sampleInput
        };

        const requestOptions = {
            method: 'POST',
            headers: headers,
        };

        store.dispatch(setSubmissionType(SUBMISSION_TYPE.TEST))
        store.dispatch(setOutputLoading(true))

        console.log("createTestSubmission payload : ", payload);
        const result = await Axios.post(targetUrl, payload, requestOptions);

        console.log("createTestSubmission result : ", result);

        if (result.status === 200) {
            setTimeout(() => {
                getBatchSubmission(result.data, SUBMISSION_TYPE.TEST)
            }, GET_BATCH_SUBMISSION_INTERVAL);
            // processResult(result.data, SUBMISSION_TYPE.TEST);
        }

    } catch (error) {
        console.log("createTestSubmission failure : ",error);
        store.dispatch(setError({
            message: error?.response?.data?.message,
            httpStatus: error?.response?.status,
            code: error?.response?.data?.code,
            shouldRedirect: false,
        }));
        store.dispatch(setOutputLoading(false))
    }
}


export const createSubmitSubmission = (data,contestId, response, error) => {
    const targetUrl = SUBMIT_PROBLEM

    if (!contestId) {
        console.log("Contest ID not present");
    }

    const payload = {
        "contestId": contestId,
        "problemId": data.problemId,
        "languageId": data.languageId,
        "sourceCode": data.sourceCode
    }

    const requestOptions = {
        method: 'POST',
        headers: headers,
    };

    store.dispatch(setSubmissionType(SUBMISSION_TYPE.SUBMIT))
    store.dispatch(setOutputLoading(true))

    Axios.post(targetUrl, payload, requestOptions)
        .then(
            (result) => {
                console.log("createSubmission result : ", result);
                if (result.status === 200) {
                    setTimeout(() => {
                        getBatchSubmission(result.data, SUBMISSION_TYPE.SUBMIT, data.problemId)
                    }, GET_BATCH_SUBMISSION_INTERVAL);
                    // processResult(result.data, SUBMISSION_TYPE.SUBMIT, data.problemId);
                }
            },
            (error) => {
                console.log(error);
                store.dispatch(setError({
                    message: error?.response?.data?.message,
                    httpStatus: error?.response?.status,
                    code: error?.response?.data?.code,
                    shouldRedirect: error?.response?.status == 404 ? true : false,
                }));
                store.dispatch(resetOutput())
            }
        )
}



export const getBatchSubmission = (output, type, problemId) => {
    const targetUrl = GET_SUBMISSIONS;

    const tokens = [...(output.tokens ?? [])];
    // if (Array.isArray(output.submissions)) {
    //     output.submissions.forEach((submission) => {
    //         tokens.push(submission.token);
    //     });
    // }

    const payload = {
        "submissionType": type,
        "tokens": tokens,
        "submissionId": output.id
    }

    const requestOptions = {
        method: 'POST',
        headers: headers
    };

    Axios.post(targetUrl, payload, requestOptions)
        .then(
            (result) => {
                console.log(result);
                if (result.status === 200) {
                    processResult(result.data, type, problemId);
                } else {

                }
            },
            (error) => {
                console.log(error);
                store.dispatch(setError({
                    message: error?.response?.data?.message,
                    httpStatus: error?.response?.status,
                    code: error?.response?.data?.code,
                    shouldRedirect: error?.response?.status == 404 ? true : false,
                }));
                store.dispatch(resetOutput())
            }
        )
}

const processResult = (data, type, problemId) => {
    try {
        console.log("processResult result.data : ", data);
        console.log("processResult result.data.status : ", data.status);
        let customResult = {}
        if (type == SUBMISSION_TYPE.TEST) {
            customResult = {
                status: data.status,
                submissions: data.submissions
            }
        } else {
            customResult = {
                status: data.status,
                id: data.id
            }
        }
        store.dispatch(setRunTestOutput(customResult))
        if (customResult.status.id <= 2) {
            setTimeout(() => {
                const tokens = []
                if (Array.isArray(data.submissions)) {
                    data.submissions.forEach((submission) => {
                        tokens.push(submission.token);
                    });
                }
                const get_submission_payload = {
                    id: data?.id,
                    tokens: tokens
                }
                getBatchSubmission(get_submission_payload, type, problemId)
            }, GET_BATCH_SUBMISSION_INTERVAL);
        } else {
            store.dispatch(setOutputLoading(false))
            if (type === SUBMISSION_TYPE.SUBMIT) {
                if (customResult.status.id === 3) store.dispatch(setQuestionAccepted(problemId))
                setTimeout(() => {
                    getScoreboard()
                }, 1000);
            }
        }
    } catch (error) {
        console.log("Something went wrong in proccessing submission : ",error);
        store.dispatch(setError({
            message: error?.response?.data?.message,
            httpStatus: error?.response?.status,
            code: error?.response?.data?.code,
            shouldRedirect: error?.response?.status == 404 ? true : false,
        }));
        store.dispatch(resetOutput())
    }
}