import { Chip } from '@mui/material'
import React from 'react'

function CBChip({label,sx,...props}) {
    return (
        <Chip
            sx={{ borderRadius: 2, ...sx }}
            label={label}
            {...props}
        />
    )
}

export default CBChip
