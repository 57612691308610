import React from 'react';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const CBTag = ({ topic, isSelected, handleClick, ...props }) => {
    const chipColor = isSelected ? '#007575' : '#202020';
    const textColor = isSelected ? '#FCFCFC' : '#999999';
    const hoverColor = '#FCFCFC';

    return (
        <Chip
            disableRipple  
            sx={{
                m: 0.5,
                bgcolor: chipColor,
                borderRadius: 1,
                '&:hover': {
                    bgcolor: chipColor,
                }
            }}
            onClick={() => handleClick(topic)}
            label={
                <Box display="flex" alignItems="center">
                    <Typography
                        color={textColor}
                        fontSize={'0.875rem'}
                        sx={{ '&:hover': { color: hoverColor }}}>
                        {topic?.name}
                    </Typography>
                    <Chip
                        label={topic?.count}
                        size='small'
                        sx={{
                            borderRadius: '10px',
                            ml: 1,
                            color: textColor,
                            height: '20px',
                            alignItems: 'center'
                        }}
                    />
                </Box>
            }
            {...props}
        />
    );
};

export default CBTag;
