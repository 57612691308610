import { ListItemIcon, ListItemText, Menu, MenuItem, MenuList, MenuProps } from '@mui/material';
import { FC, ReactNode } from 'react';
import { Check } from '@mui/icons-material';

export interface CBMenuOption {
    value: string | number;
    label: ReactNode;
    startIcon?: ReactNode; 
    endIcon?: ReactNode;
}

interface CBMenuProps {
    target: MenuProps['anchorEl'];
    onCloseMenu: () => void;
    onItemSelect: (option: CBMenuOption) => void;
    options: CBMenuOption[];
    checkIcon: boolean;
    selectedOption: CBMenuOption;
}

const CBMenu: FC<CBMenuProps> = ({ target, onCloseMenu, options, onItemSelect, checkIcon, selectedOption, ...props }) => {

    const isSelected = (option : CBMenuOption) => option?.value === selectedOption?.value;
    
    return (
        <Menu
            anchorEl={target}
            open={Boolean(target)}
            onClose={() => onCloseMenu()}
            {...props}
        >
            <MenuList dense sx={{ width: 150 }}>
                {options?.map((option) => (
                    <MenuItem
                        key={option?.value}
                        value={option?.value}
                        onClick={() => onItemSelect(option)}
                    >
                        {option?.startIcon && <ListItemIcon>{option?.startIcon}</ListItemIcon>}
                        <ListItemText primary={option?.label} />
                        {option?.endIcon}
                        {checkIcon && isSelected(option) && <Check fontSize="small" color="primary"/>}
                    </MenuItem>
                ))}
            </MenuList>
        </Menu>
    )
}

export default CBMenu