import 'bootstrap/dist/css/bootstrap.min.css';
import { useEffect, useState } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { auth, firebaseui, uiConfig } from '../../firebase';
import image from '../assets/login_popup.svg';
import '../css/modal.css';
import { useAuth } from '../context/AuthContext';

const Login = ({ handleCloseLogin, handleShowSignup, showLogin }) => {

  const { oneTapSignIn } = useAuth();

  const formDefault = {
    userName: { value: "", errorMsg: "", errorState: null },
    password: { value: "", errorMsg: "", errorState: null },
    validated: false,
  };

  const [formState, setFormState] = useState(formDefault);

  useEffect(() => {
    console.log("Login componentDidMount/Update showLogin:", showLogin);
    console.log("Login componentDidMount/Update findButton:", document.getElementById('firebaseui-auth-container'));
    console.log("Login componentDidMount/Update AuthUI:", firebaseui.auth.AuthUI.getInstance());
    
    if (showLogin) {
      const ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(auth);
      ui.start('#firebaseui-auth-container', uiConfig);
      console.log("Login componentDidMount/Update AuthUI:", ui);
      oneTapSignIn()
    }
  }, [showLogin]);

  const hideAndShowSignup = () => {
    handleCloseLogin();
    handleShowSignup();
  };

  const isDataValid = () => {
    const userName = { ...formState.userName };
    const password = { ...formState.password };

    if (userName.value === "") {
      userName.errorMsg = "User name can't be blank";
      userName.errorState = false;
    } else {
      userName.errorState = true;
    }

    if (password.value === "") {
      password.errorMsg = "Password can't be blank";
      password.errorState = false;
    } else {
      password.errorState = true;
    }

    setFormState({
      userName: userName,
      password: password,
    });

    return userName.errorState || password.errorState;
  };

  const handleTextChange = (e) => {
    setFormState({
      ...formState,
      [e.target.id]: { ...formState[e.target.id], value: e.target.value },
    });
  };

  const { userName, password, validated } = formState;

  return (
    <Modal show={showLogin} onHide={handleCloseLogin} centered style={{ backdropFilter: 'blur(5px)' }}>
      <Row className="login">
        <Col sm={6} className="border-right modal-right d-flex align-items-center modal_image">
          <img src={image} className="img-fluid login_img" alt="" />
        </Col>
        <Col sm={6}>
          <Modal.Header className="justify-content-center border-bottom-0 pb-0" closeButton>
            <Modal.Title className="text-center">
              Login
              <p className="text-center">Join the best coding community</p>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="pb-0">
            <div id="firebaseui-auth-container"></div>
          </Modal.Body>
        </Col>
      </Row>
    </Modal>
  );
};

export default Login;