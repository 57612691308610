import { Box, FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import { useState } from "react";


const CBRadioGroup = ({ options, selectedOption, setSelectedOption }) => {

    const handleRadioChange = (event) => {
        setSelectedOption(options?.find(option => option?.value === event?.target?.value));
    };

    const problemNumsByDiff = options?.find(option => option?.value === selectedOption?.value)?.problemNumsByDiff


    const optionWithNumber = (option) => {
        return (
            <Box display={'flex'} alignItems={'center'} flexDirection={'row'}>
                <Typography>{option?.label}</Typography>
                <Typography bgcolor={'#282828'} sx={{ px: 1, ml: 1 }} color={'#999999'}>x{problemNumsByDiff[option.value]}</Typography>
            </Box>
        )
    }

    return (
        <RadioGroup row sx={{ mt: 1 }}>
            {
                options?.map(option => {
                    return <FormControlLabel
                        sx={{ bgcolor: 'rgba(62, 62, 62, 0.3)', alignSelf: 'center', color: (selectedOption.value === option.value) ? '#FCFCFC' : '#999999', borderRadius: 2, px: 2, py: 1, '&:hover': { color: '#FCFCFC' }, '&.MuiFormControlLabel-root': { ml: 0, mt: {xl:0,lg:0,md:0,sm:0,xs:1.5}, mb: 0, mr: 1.5 } }}
                        value={option?.value}
                        control={<Radio sx={{ p: 0, mr: 1 }} />}
                        label={optionWithNumber(option)}
                        checked={selectedOption?.value === option?.value}
                        onChange={handleRadioChange} />
                })
            }
        </RadioGroup>
    )
}

export default CBRadioGroup;