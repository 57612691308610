import { Box } from "@mui/material";
import RecentSubmissions from "../components/sections/profileSections/recentSubmission";
import TimeGraph from "../components/sections/profileSections/timeGraph";
import UserMetaData from "../components/sections/profileSections/userMetaData";

const ProfilePage = () => {

    return (
        <Box sx={{
            width: '100%',
            px: {
                xl: 40,
                lg: 30,
                md: 16,
                sm: 6,
                xs: 2,
            },
            pt: 3,
            bgcolor: '#1a1a1a',
            height: "100vh",
            maxHeight: '-webkit-fill-available',
            overflow: "auto"
        }}>

            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <UserMetaData />
                <TimeGraph />
                <RecentSubmissions />
            </Box>
        </Box>
    );
}

export default ProfilePage;