import { Box } from '@mui/material';
import CBCurvedTabSection from '../../../../common/components/CBCurvedTabSection';
import { languageModeMap, languageOptions } from '../../../../common/constants';
import { store } from "../../../../common/redux/store";
import { setSelectedQuestionMetaData } from '../../../redux/adminQuestions';
import MetadataEditors from './metadataEditors';
// import CBEditor from '../../common/components/CBEditor';

const ProblemMetaSection = ({selectedQuestionMetaData}) => {

    console.log("ProblemMetaSection",selectedQuestionMetaData);

    const onDefaultCodeChange = (e,index) => {
        const updatedMetadata = {
            ...selectedQuestionMetaData[index], // Copy all properties from the original object
            defaultCode: e, // Update the defaultCode property
        };
          
        const updatedMetadatas = [
            ...selectedQuestionMetaData.slice(0, index), // Copy all objects before the updated object
            updatedMetadata, // Add the updated object
            ...selectedQuestionMetaData.slice(index + 1), // Copy all objects after the updated object
        ];
        
        store.dispatch(setSelectedQuestionMetaData(updatedMetadatas))
    }

    const onUserCodeChange = (e,index) => {
        const updatedMetadata = {
            ...selectedQuestionMetaData[index], // Copy all properties from the original object
            userCode: e, // Update the defaultCode property
        };
          
        const updatedMetadatas = [
            ...selectedQuestionMetaData.slice(0, index), // Copy all objects before the updated object
            updatedMetadata, // Add the updated object
            ...selectedQuestionMetaData.slice(index + 1), // Copy all objects after the updated object
        ];
        
        store.dispatch(setSelectedQuestionMetaData(updatedMetadatas))
    }


    const metadataSectionTabs = []

    selectedQuestionMetaData.map((meta,index) =>{
        const lang = languageOptions.find(l => l?.value === meta?.languageId)
        console.log("ProblemMetaSection",lang);
        if(lang){
            const mode = languageModeMap.find(l => l?.value === meta?.languageId)?.mode
            const metaProp = {
                "lang":lang?.label,
                "mode": mode,
                "userCode": meta?.userCode,
                "defaultCode": meta?.defaultCode
            }

            console.log("ProblemMetaSection",metaProp);

            metadataSectionTabs.push(
                {
                    key: lang?.label,
                    node: <MetadataEditors onDefaultCodeChange={onDefaultCodeChange} onUserCodeChange={onUserCodeChange} metadata={metaProp} index={index} />
                }
            )
        }
    })

    return (
        <Box sx={{ display: 'flex', width: '100%',flexDirection:'column' }}>
            <CBCurvedTabSection tabs={metadataSectionTabs} initalTabIndex={0} />
        </Box>
    )
}

export default ProblemMetaSection;

