import { Box, Link, Typography, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { useEffect,useState } from 'react';
import { useSelector } from 'react-redux';
import { getAllProblems } from '../apis/adminApis';
import CBTabPanel from '../../common/components/CBTabPanel';
import { useAuth } from '../../common/context/AuthContext';
import CBButton from '../../common/components/CBButton';
import CBLoader from '../../common/components/CBLoader';
import { useDispatch } from 'react-redux';
import { setSelectedQuestion } from '../redux/adminQuestions';
import { difficultyLevels } from '../../common/constants';
import DifficultyMenu from '../../cbApp/components/DifficultyMenu';
import { ProblemStatusArray } from '../constants';

const AdminPage = () => {

    const { questions } = useSelector(state => state.adminQuestion)
    const dispatch = useDispatch();
    const { currentUser, loading } = useAuth();
    const [difficulty,setDifficulty] = useState(difficultyLevels[0])
    const [status,setStatus] = useState(ProblemStatusArray[0])

    //const [filteredProblems,setFilteredProblems] = useState(questions)

    useEffect(() => {
        if (!loading && currentUser) {
            getAllProblems()
        }
    }, [loading, currentUser])

    const onCreateNewProblem = () => {
        window.location.pathname = ("admin/createProblem/")
    }



    const handleQuestionClick = (problem_id) => {
        // dispatch(setSelectedQuestion(null));
        window.location.pathname = ("admin/problems/" + problem_id)
    };

    // const handleDifficultyChange = (e) => {
    //     setDifficulty(e)
    //     setFilteredProblems(questions.filter(
    //         (question) =>
    //           question.difficultyLevel === e.value &&
    //           question.status === status.value
    //       )
    //     );

    // }
    // const handleStatusChange = (e) => {
    //     setStatus(e)
    //     setFilteredProblems(questions.filter(
    //         (question) =>
    //           question.difficultyLevel === difficulty.value &&
    //           question.status === e.value
    //       )
    //     );
    // }

    return (
        <div>
            {
                loading ? <CBLoader /> :
                    <CBTabPanel sx={{ overflow: 'auto',pl:24,pr:24 }}>
                        {/* <Box sx={{display:'flex',flexDirection:'row'}}>
                            <DifficultyMenu difficultyMap={difficultyLevels} difficultyLevel={difficulty} setDifficultyLevel={(e)=>handleDifficultyChange(e)}/>
                            <DifficultyMenu difficultyMap={ProblemStatusArray} difficultyLevel={status} setDifficultyLevel={(e)=>handleStatusChange(e)}/>
                        </Box> */}
                        <CBButton onClick={onCreateNewProblem} >New Problem</CBButton>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ fontWeight: 'bold', fontSize: '14px' }}>Title</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold', fontSize: '14px' }}>Difficulty</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold', fontSize: '14px' }}>Status</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {questions.map((problem, index) => (
                                    <TableRow key={problem.id} sx={{ bgcolor: index % 2 == 1 ? '#2a2a2a' : '#1a1a1a' }}>
                                        <TableCell>
                                            <CBButton color='inherit' variant='text' onClick={() => handleQuestionClick(problem.id)}>{index + 1}. {problem.problemTitle}</CBButton>
                                        </TableCell>
                                        <TableCell>{problem.difficultyLevel}</TableCell>
                                        <TableCell>{problem.status}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </CBTabPanel>
            }
        </div>
    )
}

export default AdminPage;

