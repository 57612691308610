import DoneIcon from '@mui/icons-material/Done';
import LinkIcon from '@mui/icons-material/Link';
import PersonAddIcon from '@mui/icons-material/PersonAddOutlined';
import { Avatar, Badge, Box, Button, IconButton, Tooltip, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { useSelector } from 'react-redux';
import { useAuth } from '../../../../common/context/AuthContext';
import { USER_STATUS } from '../../../constants';
import InvitePopup from '../../InvitePopup';
import LobbyActionButton from './lobbyActionButton';
import ChatBox from './chatBox';
import ContestStatusBanner from './contestStatusBanner';
import SettingMenu from './settingMenu';
import LinkAvatar from '../../common/LinkAvatar';
import { PATH_PROFILE } from '../../../../common/utils/NavOptionRoutes';
import SettingsPopup from '../../SettingsPopup';
import SettingsIcon from '@mui/icons-material/SettingsOutlined';

const CopyToolTip = () => {
    const [copiedLink, setCopiedLink] = useState(false)

    const copyLink = () => {
        navigator.clipboard.writeText(window.location.href)
        setCopiedLink(true)
    }

    return (
        <Tooltip title={copiedLink ?
            <Box>
                <DoneIcon fontSize="small" sx={{ mr: 0.5, px: 0.3, }} />
                Copied
            </Box>
            : "Copy Invite Link"} onOpen={() => setCopiedLink(false)}>
            <IconButton sx={{ color: '#8a8a8a', ml: 1 }} onClick={() => copyLink()}>
                <LinkIcon />
            </IconButton>
        </Tooltip>)
}


const LobbySection = ({ contest_id }) => {
    const participants = useSelector(state => state.participant.participants)
    const isUserReady = useSelector(state => state.participant.isUserReady)
    const contest = useSelector(state => state.contest)
    const [showInvitePopup, setShowInvitePopup] = useState(false)
    const [showSettingsPopup, setShowSettingsPopup] = useState(false)
    const { currentUser } = useAuth();
    const participantCarouselRef = useRef(null);
    const participantCarouselItemRef = useRef(null);
    const [participantCarouselColSize, setParticipantCarouselColSize] = useState(8);

    console.log("LobbySection participants : ", participants);

    const getMyUserStatus = () => {
        if (!currentUser || !participants) return
        const myUser = participants.find(participant => participant.uid === currentUser.uid)
        if (myUser) return myUser.status
    }

    useEffect(() => {
        function handleResize() {
            const colSize = participantCarouselRef.current.offsetWidth / 56;
            console.log("ColSize : ", colSize);
            setParticipantCarouselColSize(colSize);
        }
        window.addEventListener('resize', handleResize)
        return _ => {
            window.removeEventListener('resize', handleResize)
        }
    })

    useEffect(() => {
        const colSize = participantCarouselRef.current.offsetWidth / 56;
        console.log("ColSize : ", colSize);
        setParticipantCarouselColSize(colSize);
    }, [participantCarouselRef.current])


    return (
        <div style={{ height: 'calc(100vh - 50px)', display: 'flex', flexDirection: 'column' }}>
            <Box>
                <Box sx={{ mx: 2, height: '44px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography sx={{ fontWeight: 600, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', maxWidth: '100%' }}>{contest.name}
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <CopyToolTip />
                        <IconButton sx={{ color: '#8a8a8a', ml: 1 }} onClick={() => setShowInvitePopup(true)}>
                            <PersonAddIcon />
                        </IconButton>
                        <IconButton sx={{ color: '#8a8a8a', ml: 1 }} onClick={() => setShowSettingsPopup(true)}>
                            <SettingsIcon />
                        </IconButton>
                        {/* <SettingMenu contest_id={contest_id} /> */}
                    </Box>
                </Box>
                <Box sx={{ mb: 1.5, mx: 2, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                    <Typography color="#8a8a8a" variant="caption">{participants.length} players</Typography>

                    <Box ref={participantCarouselRef} sx={{ display: 'flex', width: '100%', mt: 2 }}>
                        <Carousel
                            additionalTransfrom={0}
                            arrows
                            autoPlaySpeed={3000}
                            centerMode={false}
                            className=""
                            containerClass="container"
                            dotListClass=""
                            draggable
                            focusOnSelect={false}
                            infinite={false}
                            itemClass=""
                            keyBoardControl
                            minimumTouchDrag={80}
                            pauseOnHover
                            renderArrowsWhenDisabled={false}
                            renderButtonGroupOutside={false}
                            renderDotsOutside={false}
                            responsive={{
                                any: {
                                    breakpoint: {
                                        max: 30000,
                                        min: 0
                                    },
                                    items: Math.floor(participantCarouselColSize),
                                    partialVisibilityGutter: 30
                                }
                            }}
                            rewind={false}
                            rewindWithAnimation={false}
                            rtl={false}
                            shouldResetAutoplay
                            showDots={false}
                            sliderClass=""
                            slidesToSlide={1}
                            swipeable>
                            {participants.map((participant, index) => {
                                return <Box key={index} sx={{ mr:2, height: 44 }}>
                                    {(participant.status === USER_STATUS.IN_CONTEST_READY || participant.status === USER_STATUS.IN_CONTEST_STARTED) ?
                                        <Badge variant="dot" color="success" anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                                            <LinkAvatar onClick={() => window.open(PATH_PROFILE + participant.uid)} sx={{ width: 40, height: 40, borderRadius: '15px' }} variant="rounded" alt={participant.displayName} src={participant.photoURL} />
                                        </Badge> :
                                        <LinkAvatar onClick={() => window.open(PATH_PROFILE + participant.uid)} sx={{ width: 40, height: 40, borderRadius: '15px' }} variant="rounded" alt={participant.displayName} src={participant.photoURL} />
                                    }
                                </Box>
                            })}
                        </Carousel>
                    </Box>
                    <LobbyActionButton userStatus={getMyUserStatus()} contest_id={contest_id} contestStatus={contest.status} />
                </Box>
                <ContestStatusBanner contestStatus={contest.status} />
            </Box>
            <ChatBox currentUser={currentUser} contestID={contest?.id} />
            <InvitePopup showInvitePopup={showInvitePopup} setShowInvitePopup={setShowInvitePopup} />
            <SettingsPopup 
                showSettingsPopup={showSettingsPopup} 
                setShowSettingsPopup={setShowSettingsPopup} 
            />

        </div >
    )
}

export default LobbySection;
