import { Box, Chip, IconButton, Stack, Typography, alpha } from '@mui/material';
import { DIFFICULTY_COLOR, DIFFICULTY_SMALL } from '../constants';
import theme from '../../theme';

function DifficultyLevelChip({difficultyLevel,label=null}) {

  const color = DIFFICULTY_COLOR[difficultyLevel] || theme.palette.success.main;
  const displayLabel = label || (difficultyLevel === 'CUSTOM' ? 'Custom' : DIFFICULTY_SMALL[difficultyLevel]);

  return (
    <Chip sx={{ borderRadius: '8px', bgcolor: alpha(color, 0.15), width: 'fit-content' }} size="small" label={
        <Typography variant="caption" color={color}>
            {displayLabel}
        </Typography>
    } />
  )
}

export default DifficultyLevelChip