import { Typography, Box, IconButton, Grid } from "@mui/material";
import CBButton from "../../common/components/CBButton";
import { ReactComponent as AboutUsIcon } from "../../common/assets/about_us.svg";

const AboutPage = () => {
  const handleFeedbackClick = () => {
    window.open(process.env.REACT_APP_FEEDBACK_URL);
  };

  const handleJoinUsClick = () => {
    window.open(process.env.REACT_APP_JOIN_US_URL);
  };

  return (
    <Grid
      container
      alignItems="center"
      direction={{ sm: "row", md: "row-reverse" }}
      sx={{
        height: "100vh",
        overflow: "auto",
        justifyContent: "space-evenly",
      }}
    >
      <Grid
        item
        md={4}
        sm={12}
        sx={{
          width: "100%",
          // border: "1px solid white",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: { xs: 3, md: 0 },
        }}
      >
        <IconButton
          sx={{
            height: { xs: "300px", md: "400px" },
            width: { xs: "300px", md: "400px" },
          }}
        >
          <AboutUsIcon />
        </IconButton>
      </Grid>

      <Grid
        item
        md={7}
        sm={12}
        sx={{
          // border: "1px solid white",
          // paddingLeft: { md: 8 },
          padding: 3,
        }}
      >
        <Typography
          letterSpacing="0.2rem"
          lineHeight={1.2}
          fontWeight={700}
          color="#10a0a0"
          sx={{
            mb: { xs: 4, md: 6 },
            fontSize: {
              lg: "4rem",
              md: "4rem",
              sm: "2rem",
              xs: "2rem",
            },
          }}
        >
          About Us
        </Typography>

        <Typography variant="body1" sx={{ lineHeight: "2" }}>
          CodeBattle is a fun and competitive 🚀 coding platform for friends 💯
          <br></br>
          We have challenges ✨ for all skill levels
          <br></br>
          Whether you're a beginner or a seasoned pro 🚀, CodeBattle is the
          perfect place to learn and grow 🌱
          <br></br>
          We're always adding new challenges and features 🛠, so check back often
          👀
          <br></br>
          We appreciate your feedback 🙏 and suggestions. Help us make
          CodeBattle the best it can be! 💯
        </Typography>

        <Box
          sx={{
            marginTop: 5,
            paddingBottom: { xs: 6, md: 0 },
            // width: { xs: "100%", sm: "45%", md: "36%" },
            maxWidth: { md: "36%" },
            minWidth: "36%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <CBButton variant="outlined" onClick={handleFeedbackClick}>
            Feedback
          </CBButton>

          <CBButton onClick={handleJoinUsClick}>Join us</CBButton>
        </Box>
      </Grid>
    </Grid>
  );
};

export default AboutPage;
