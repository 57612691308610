import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import Split from 'react-split';
import CBLoader from '../../common/components/CBLoader';
import CBSeo from '../../common/components/CBSeo';
import Login from "../../common/components/login";
import { useAuth } from '../../common/context/AuthContext';
import { store } from '../../common/redux/store';
import { joinContest } from '../apis/contestApis';
import InvitePopup from '../components/InvitePopup';
import ProblemContentSection from '../components/sections/contentSections/problemContentSection';
import EditorSection from '../components/sections/editorSections/editorSection';
import LobbySection from '../components/sections/lobbySections/lobbySection';
import ReadySection from '../components/sections/readySection/readySection';
import { CONTEST_STATUS, ROOM_TYPE } from '../constants';
import { setRoomType } from '../redux/contest';
import WebsocketInit from '../websocket/websocketInit';


const EditorPage = () => {
	const { contest_id } = useParams();
	const contest = useSelector(state => state.contest)
	const [showLogin, setShowLogin] = useState(false)
	const [showInvitePopup, setShowInvitePopup] = useState(false)
	const [invitePopupCounter, setInvitePopupCounter] = useState(0)
	const { currentUser, loading } = useAuth();
	const { questions } = useSelector(state => state.question)
	const location = useLocation();

	useEffect(() => {
		if (isPracticeRoom()) {
			store.dispatch(setRoomType(ROOM_TYPE.PRACTICE));
		} else if (isContestRoom()) {
			store.dispatch(setRoomType(ROOM_TYPE.CONTEST));
		}
	}, [location.pathname]);

	useEffect(() => {
		if (!loading && !currentUser) {
			setShowLogin(true);
		} else if (!loading && currentUser) {
			setShowLogin(false);
			console.log("EditorPage contest_id", contest_id);
			joinContest(contest_id, currentUser);
		}
	}, [loading, currentUser]);

	useEffect(() => {
		if (currentUser && invitePopupCounter === 0) {
			setShowInvitePopup(true);
			setInvitePopupCounter(prevCounter => prevCounter + 1);
		}
	}, [currentUser, invitePopupCounter]);


	const isPracticeRoom = () => {
		return location.pathname.includes("/practice/room/")
	}

	const isContestRoom = () => {
		return location.pathname.includes("/editor/")
	}


	console.log("EditorPage : ", questions, contest.status);

	const defineSection = () => {
		if (contest.status === CONTEST_STATUS.IDLE) {
            return <CBLoader />;
        }
		if (isContestRoom() && (contest.status === CONTEST_STATUS.CREATED || contest.status === CONTEST_STATUS.STARTING)) {
			return <Split minSize={300} style={{ display: 'flex', flexDirection: 'row', backgroundColor: '#1a1a1a' }} sizes={[73, 27]} gutterSize={8} key={"ContestRoom"}>
				<ReadySection contest_id={contest_id} />
				<LobbySection contest_id={contest_id} />
			</Split>
		}
		else {

			if((contest.status === CONTEST_STATUS.CREATED || contest.status === CONTEST_STATUS.STARTING)){
				return <CBLoader/>
			}

			return <Split minSize={300} style={{ display: 'flex', flexDirection: 'row', backgroundColor: '#1a1a1a' }} sizes={[27, 46, 27]} gutterSize={8} key={"PracticeRoom"}>
				<ProblemContentSection />
				<EditorSection />
				<LobbySection contest_id={contest_id} />
			</Split>
		}
	}

	return (
		<div>
			<CBSeo
				title="CodeBattle Room - Compete Now!"
				description="Click On this Link to Join the coding room on CodeBattle.in! Battle it out with friends and other coders, solve real interview questions, and sharpen your problem-solving skills in a live, competitive environment. Join now and prove your skills!"
			/>
			<Login showLogin={showLogin} />
			{loading ?
				<CBLoader />
				:
				<div>
					{isPracticeRoom() && <InvitePopup showInvitePopup={showInvitePopup} setShowInvitePopup={setShowInvitePopup} />}
					<WebsocketInit contest_id={contest_id} currentUser={currentUser} />
					{defineSection()}
				</div>
			}
		</div>
	)
}

export default EditorPage;
