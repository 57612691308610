import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Box, IconButton } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import CBFlatTabSection from '../../../../../common/components/CBFlatTabSection';
import ProblemConsoleOutput from './ProblemConsoleOutput';
import TestcaseTabPanel from './TestcaseTabPanel';


export default function ProblemConsole({ handleConsole, outputLoading,output,activeInputCases,submissionType }) {

    const [selectedKey, setSelectedKey] = useState(0)
    const consoleRef = useRef(null)
    console.log("ProblemConsole",output);

    useEffect(() => {
        if (outputLoading) {
            setSelectedKey(1)
        }
    }, [outputLoading])

    const tabs = [
        {
            key: 'Testcase',
            node: <>{activeInputCases && <TestcaseTabPanel size={consoleRef?.current?.clientHeight - 48} sampleInputs={activeInputCases}/>}</>
        },
        {
            key: 'Result',
            node: <ProblemConsoleOutput size={consoleRef?.current?.clientHeight - 48} output={output} sampleInputs={activeInputCases} outputLoading={outputLoading} submissionType={submissionType}/>
        }
    ]


    const onchangeDone = (_e,newKey) => {
        setSelectedKey(newKey);
    }

    return (
        <Box ref={consoleRef} sx={{ zIndex: 100, overflow: 'hidden' }}>
            <CBFlatTabSection selectedKey={selectedKey} setSelectedKey={onchangeDone} tabs={tabs} initalTabIndex={0} end={
                <IconButton aria-label="" onClick={handleConsole}>
                    <KeyboardArrowDownIcon sx={{ color: '#8a8a8a' }} />
                </IconButton>
            } />
        </Box>
    );
}
