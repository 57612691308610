import PropTypes from "prop-types";
import { ERROR_CODE } from "../constants";

export const ErrorModel = {
    message: null,
    httpStatus: null,
    code: null,
    shouldRedirect: false,
};


export const ErrorModelPropTypes = {
    message: PropTypes.string,
    httpStatus: PropTypes.number,
    shouldRedirect: PropTypes.bool,
    code: PropTypes.oneOf(Object.values(ERROR_CODE))
};
