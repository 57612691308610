import { Box, Typography } from "@mui/material";
import { useState } from "react";
import { zeroPad } from 'react-countdown';


const CBTimeSelector = ({ selectedTime, sx }) => {

    const maxTime = 60;
    const timeInterval = 5;
    const numIntervals = maxTime / timeInterval;
    const intervalArray = Array.from({ length: numIntervals }, (_, index) => index);
    const numSelectedInterval = selectedTime / timeInterval;

    intervalArray.map((intervalIndex) => console.log(intervalIndex))

    return (
        <Box bgcolor={'rgba(62, 62, 62, 0.3)'} borderRadius={2} sx={{ px: 1.5, py: 1, display: 'inline-flex', flexDirection: 'row', alignItems: 'center', ...sx }}>

            <Typography sx={{ mr: 1.5 }}>{zeroPad(timeInterval)} m</Typography>
            {intervalArray.map((intervalIndex) => (

                <div
                    key={intervalIndex}
                    style={{
                        backgroundColor: (intervalIndex < numSelectedInterval) ? '#0CA0A0' : '#3E3E3E',
                        height: (intervalIndex % 3 === 2) ? '23px' : '15px',
                        width: '2.5px',
                        marginLeft: intervalIndex > 0 ? '4px' : '0px',
                        borderRadius: '8px'
                    }}
                />
            ))}

            <Typography sx={{ ml: 1.5 }}>{zeroPad(maxTime)} m</Typography>

        </Box>
    )
}

export default CBTimeSelector;