import TimerIcon from '@mui/icons-material/TimerOutlined';
import { Box, Typography } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import React from 'react';
import Countdown, { zeroPad } from 'react-countdown';
import { useSelector } from 'react-redux';
import { shakeAnimation } from '../../../../common/constants';
import { CONTEST_STATUS } from '../../../constants';

const defaultColor = '#10a0a07f';

function ContestStatusBanner({ contestStatus }) {
    const [progress, setProgress] = React.useState(0);
    const endTime = useSelector(state => state.countdown.endTime)
    const contestLength = useSelector(state => state.contest.contestLength)

    const updateGradient = (compareTo) => {
        const duration = endTime - Date.now();
        const percentage = duration > 0 ? 100 - (((duration / (60 * 1000)) / compareTo) * 100) : 100;
        setProgress(percentage)
    }

    const countdownRenderer = ({ days, hours, minutes, seconds }) => {
        if (days && days > 0) {
            const totalHours = days * 24 + hours;
            return <span>{zeroPad(totalHours)}:{zeroPad(minutes)}:{zeroPad(seconds)}</span>
        }
        else if(hours && hours > 0){
            return <span>{zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}</span>
        }
        return <span>{zeroPad(minutes)}:{zeroPad(seconds)}</span>
    }

    return (
        <>
            {(contestStatus === CONTEST_STATUS.STARTING || contestStatus === CONTEST_STATUS.STARTED) ?
                <>
                    <Box sx={{ background: defaultColor, p: 1, display: 'flex', alignItems: 'center' }}>
                        <TimerIcon fontSize="small" sx={{ mx: 2, ...((progress > 95) && shakeAnimation) }} />
                        {contestStatus === CONTEST_STATUS.STARTING ?
                            <Typography variant="body2" sx={{ display: 'flex', flexDirection: 'row' }}>
                                <Typography variant="body2">Round will starts in{' '}
                                <Countdown
                                    onTick={() => updateGradient(2)}
                                    key={endTime}
                                    autoStart
                                    date={endTime}
                                    renderer={countdownRenderer}
                                />
                                {' '}or when everyone is ready</Typography>
                            </Typography>
                            : contestStatus === CONTEST_STATUS.STARTED ?
                                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                    <Typography variant="body2" sx={{ mr: 1 }}>Round ends in{' '}
                                    <Countdown
                                        onTick={() => updateGradient(contestLength)}
                                        key={endTime}
                                        autoStart
                                        date={endTime}
                                        renderer={countdownRenderer}
                                    />
                                    </Typography>
                                </Box>
                                : null
                        }
                    </Box>
                    <LinearProgress variant="determinate" value={progress} /></>
                : null}
        </>
    )
}

export default ContestStatusBanner
