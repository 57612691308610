import Axios from "axios";
import { setError } from "../redux/error";
import { store } from "../../common/redux/store";
import { setLeaderboardUsers } from "../redux/leaderboard";
import { GET_ALL_LEADERBOARD_USERS } from "../constants";


const headers = {
    'Content-Type': "application/json; charset=utf-8",
    "accept": "application/json"
}

export const getLeaderboardUsers = () => {
    const targetUrl = GET_ALL_LEADERBOARD_USERS
    const requestOptions = {
        method: 'GET',
        headers: headers
    };

    Axios.get(targetUrl, requestOptions)
        .then(
            (result) => {
                console.log("getLeaderboardUsers", result);
                if (result.status == 200) {
                    store.dispatch(setLeaderboardUsers(result?.data?.leaderboard));
                }
                else console.error("getLeaderboardUsers : Something went wrong");
            },
            (error) => {
                console.log("Error while getLeaderboardUsers : ", error);
                store.dispatch(setError({
                    message: error?.response?.data?.message,
                    httpStatus: error?.response?.status,
                    code: error?.response?.data?.code,
                    shouldRedirect: false,
                }));
            }
        )
}