import { Avatar, AvatarProps } from '@mui/material'
import React, { FC } from 'react'

interface LinkAvatarProps extends AvatarProps { }

const LinkAvatar: FC<LinkAvatarProps> = ({ sx, ...props }) => {
    return (
        <Avatar sx={{
            cursor: 'pointer',
            '&:hover': {
                opacity: 0.8, // optional: reduce opacity on hover
            },
            ...sx
        }} {...props} />
    )
}

export default LinkAvatar