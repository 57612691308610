import { Box, Skeleton, Typography } from "@mui/material";
import CBTag from "./CBTag";
import { useSelector } from "react-redux";
import React from "react";
import { getAllPublishedTopicTags } from "../../cbApp/apis/contestApis";
import CBCenterInfo from "./CBCenterInfo";
import { MAX_TOPIC_TAGS } from "../constants";


const TopicsTabPanel = ({ selectedTags, setSelectedTags, sx }) => {

  const { topics, topicsLoading } = useSelector(state => state.publishedProblemTopic)

  const filteredTopics = topics?.filter(topic => topic?.count >= 3);

  React.useEffect(() => {
      getAllPublishedTopicTags()
  }, [])

  const handleClick = (topic) => {
    if (selectedTags?.includes(topic?.name)) {
      setSelectedTags(selectedTags => selectedTags.filter(tag => tag !== topic?.name));
    } else {
      if (selectedTags.length >= MAX_TOPIC_TAGS) {
        return; // Prevent selecting more than 3 tags
      }
      setSelectedTags(selectedTags => [...selectedTags, topic?.name]);
    }
  };


  const Loading = () => {
    const skeletonArray = Array.from({ length: 50 }, (_, index) => {
      const randomWidth = Math.floor(Math.random() * 70) + 40; 
      return (
        <Skeleton key={index} variant="rounded" height='24px' width={`${randomWidth}px`} sx={{ mt: 1, ml: 1 }} />
      );
    });

    return <>{skeletonArray}</>;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'start',
        flexWrap: 'wrap',
        listStyle: 'none',
        p: 2,
        height: '180px',
        overflowY: 'auto',
        ...sx,
      }}>

      {topicsLoading ?
        <Loading />
        :
        filteredTopics?.length <= 0 ?
          <CBCenterInfo info={'No Tags Found 😔'} />
          :
          filteredTopics?.map((topic, index) => {
            const isDisabled = !selectedTags.includes(topic?.name) && selectedTags.length >= MAX_TOPIC_TAGS;
            return (
              <CBTag 
                topic={topic} 
                isSelected={selectedTags.includes(topic?.name)} 
                handleClick={() => handleClick(topic)} 
                disabled={isDisabled}
                key={topic.name}
              />
            );
          })}
    </Box>
  );
}

export default TopicsTabPanel;