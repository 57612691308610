import { Box } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Split from 'react-split';
import CBEditor from '../../../../common/components/CBEditor';
import CBTabPanel from '../../../../common/components/CBTabPanel';
import { createSubmitSubmission, createTestSubmission } from '../../../apis/submissionApis';
import { CONTEST_STATUS, DEFAULT } from '../../../constants';
import { resetQuestionCode, setEditorLoading, updateQuestionCode } from '../../../redux/editor';
import ProblemConsole from './consoleSections/ProblemConsole';
import ConsoleControl from './consoleControl';
import { store } from '../../../../common/redux/store';
import { EDITOR_KEY_BINDING_OPTIONS } from '../../../../common/constants';
import { toast } from 'react-toastify';

let recur_cnt = 0;

function EditorTabPanel() {
    const splitRef = useRef();

    const [sizes, setSizes] = useState([100, 0]);

    const { 
        defaultCode,
        questionCode, 
        questionLang, 
        editorMode, 
        selectedLanguage, 
        editorLoading,
        fontSize,
        keyBinding,
        wordWrap,
        tabSize 
    } = useSelector(state => state.editor)
    const dispatch = useDispatch();

    const [theme, setTheme] = useState('dracula')
    const [code, setCode] = useState('')
    const [output, setOutput] = useState("")
    const [isConsoleOpen, setIsConsoleOpen] = useState(false)

    const { activeQuestion, status: contestStatus } = useSelector(state => state.contest)
    const { outputLoading, activeInputCases, runTestOuput, submissionType} = useSelector(state => state.submission)
    const contestId = store.getState().contest.id;
    // const handleGetSizes = () => {
    //     console.log("split ref", splitRef);
    //     if (splitRef.current && splitRef.current.split) {
    //         const sizes = splitRef.current.split.getSizes();
    //         console.log(sizes);
    //     }
    // };

    const handleDrag = (newSizes) => {
        setSizes(newSizes);
    };

    const onChange = (newValue) => {
        console.log("editor onChange");
        setCode(newValue)
        try {
            if (activeQuestion?.id && questionLang[activeQuestion?.id]) {
                dispatch(updateQuestionCode({
                    key: activeQuestion?.id + questionLang[activeQuestion?.id],
                    value: newValue
                }))
            } else {
                dispatch(updateQuestionCode({
                    key: DEFAULT + questionLang[DEFAULT],
                    value: newValue
                }))
            }
        } catch (error) {
            console.log("Something went wrong : ", error);
        }
    }

    const handleCompile = (e) => {
        console.log("code to be complied : ", code);
        recur_cnt = 0;
        if (outputLoading || (contestStatus != CONTEST_STATUS.STARTED && contestStatus != CONTEST_STATUS.ENDED)){
            toast.error("Cannot Run Code Now 😔");
            return;
        }
        if (!isConsoleOpen) {
            handleConsole()
        }
        console.log("sample input passed to backend : ", activeInputCases);
        const contestId = store.getState().contest.id;
        createTestSubmission({
            problemId: activeQuestion?.id,
            languageId: selectedLanguage?.value,
            "sourceCode": code,
            "sampleInput": activeInputCases,
        },
        contestId)
    }

    const handleSubmit = (e) => {
        if(outputLoading || (contestStatus != CONTEST_STATUS.STARTED  && contestStatus != CONTEST_STATUS.ENDED)){
            toast.error("Cannot Submit Code Now 😔");
            return;
        }
        console.log("code to be Submitted : ", code);
        if (!isConsoleOpen) {
            handleConsole()
        }
        recur_cnt = 0;
        createSubmitSubmission({
            problemId: activeQuestion?.id,
            languageId: selectedLanguage?.value,
            "sourceCode": code,
        },
        contestId)

    }

    const handleConsole = () => {
        if (isConsoleOpen) setSizes([100, 0])
        else setSizes([50, 50])
        setIsConsoleOpen(!isConsoleOpen)
    }

    const handleResetCode = (e) => {
        console.log("handleResetCode : ", activeQuestion?.id + questionLang[activeQuestion?.id]);
        setCode(defaultCode[activeQuestion?.id + questionLang[activeQuestion?.id]])
        dispatch(resetQuestionCode({
            key: activeQuestion?.id + questionLang[activeQuestion?.id]
        }))
    }

    useEffect(() => {
        console.log("Inside the useEffect editorLoading : ", editorLoading);
        if (!editorLoading) {
            let code = "";
            try {
                if (activeQuestion?.id && questionLang[activeQuestion?.id]) {
                    code = questionCode[activeQuestion?.id + questionLang[activeQuestion?.id]] || "";
                    console.log("if !editorLoading and active question then : ", code);
                } else {
                    code = questionCode[DEFAULT + questionLang[DEFAULT]];
                    console.log("if !editorLoading and DEFAULT then : ", code);
                }
            } catch (error) {
                console.log("Something went wrong : ", error);
            }
            console.log("if !editorLoading then final code is : ", code);
            setCode(code);
        }
    }, [editorLoading, questionLang, activeQuestion?.id])

    useEffect(() => {
        const handleKeyDown = (event) => {
            const isCtrlOrCmd = event.ctrlKey || event.metaKey; // Meta key for macOS
            if (isCtrlOrCmd && event.key === "'") {
                handleCompile();
            }
            if (isCtrlOrCmd && event.key === 'Enter') {
                handleSubmit();
            }
        };
    
        window.addEventListener('keydown', handleKeyDown);
    
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [code, isConsoleOpen, activeQuestion, selectedLanguage, activeInputCases]);

    return (
        <CBTabPanel sx={{ px: 0}}>
            <Split gutterSize={(isConsoleOpen ? 8 : 0)} ref={splitRef} minSize={0} direction="vertical" style={{ height: 'calc(100vh - 164px)' }} sizes={sizes} onDrag={handleDrag}>
                <CBEditor
                    height={`${sizes[0]}%`}
                    mode={editorMode ? editorMode : "c_cpp"}
                    theme={theme ? theme : 'dracula'}
                    onChange={(e) => onChange(e)}
                    value={code}
                    fontSize={fontSize?.value}
                    wrapEnabled={wordWrap}
                    tabSize={tabSize?.value}
                    {...(keyBinding?.value !== EDITOR_KEY_BINDING_OPTIONS[0]?.value && { keyboardHandler: keyBinding?.value })}
                />
                {isConsoleOpen ? <ProblemConsole outputLoading={outputLoading} handleConsole={handleConsole} output={runTestOuput} activeInputCases={activeInputCases} submissionType={submissionType}/> : <Box />}
            </Split>
            <ConsoleControl
                    isConsoleOpen={isConsoleOpen}
                    onClickRun={handleCompile}
                    onClickConsole={handleConsole}
                    onClickSubmit={handleSubmit}
                    handleResetCode={handleResetCode}
                    runDisabled={outputLoading || (contestStatus != CONTEST_STATUS.STARTED && contestStatus != CONTEST_STATUS.ENDED)}
                    submitDisabled={outputLoading || (contestStatus != CONTEST_STATUS.STARTED  && contestStatus != CONTEST_STATUS.ENDED)}
                />
        </CBTabPanel>
    )
}

export default EditorTabPanel
