import { Autocomplete, Box, Button, Chip, Stack, TextField, Typography } from "@mui/material";
import { useState,useEffect } from "react";
import DifficultyMenu from '../../../../cbApp/components/DifficultyMenu';
import CBTextField from "../../../../common/components/CBTextField";
import { difficultyLevels } from "../../../../common/constants";
import { store } from "../../../../common/redux/store";
import { convertToHtml, convertToMarkDown } from "../../../utils/markdownUtils";
import CBButton from "../../../../common/components/CBButton";
import CBEditor from "../../../../common/components/CBEditor";
import { de } from "date-fns/locale";
import { getAllTopicTags } from "../../../apis/adminApis";
import { useSelector } from "react-redux";
import { setError } from "../../../../cbApp/redux/error";
import { useAuth } from "../../../../common/context/AuthContext";


const markdownFields = ['problemStatement','ioExplanation','constraints','inputSpecification','outputSpecification',]


const ProblemEditorSection = ({selectedQuestion,setSelectedQuestion}) =>  {

    
    const [selectedQuestionState, setSelectedQuestionState] = useState(null);
    const { topics } = useSelector(state => state.problemTopic)
    const { currentUser, loading } = useAuth();
    const topicNames = topics?topics.map(topic => topic.name):[];
    useEffect(() => {
        if(!loading && currentUser){
            getAllTopicTags()
        }
    }, [currentUser, loading])

    useEffect(() => {
        console.log("not empty useEffect : ",selectedQuestionState);
        if(selectedQuestionState == null && selectedQuestion != null){
            const markdownConverted = {...selectedQuestion}
            markdownFields.forEach(field => {
                markdownConverted[field] = convertToMarkDown(markdownConverted[field])
            })
            setSelectedQuestionState(markdownConverted)
        }
    }, [selectedQuestion])
    

    const handleSelectDificultyLevel = (option) => {
        onTextChage(option?.value,"difficultyLevel")
    }

    const SubHeading = ({ children }) => {
        return <Typography variant="body2"><strong>{children}</strong></Typography>
    }

    const onMarkdownChange = (text, key) => {
        console.log("onMarkdownChange:" + key + ": " + text)
        setSelectedQuestionState({ ...selectedQuestionState, [key]: text })
        store.dispatch(setSelectedQuestion({ ...selectedQuestion, [key]: convertToHtml(text) }))
    }

    const onTextChage = (text, key) => {
        console.log("sample:" + key + ": " + text)
        setSelectedQuestionState({ ...selectedQuestionState, [key]: text })
        store.dispatch(setSelectedQuestion({ ...selectedQuestion, [key]: text }))
    }

    const onTestcaseChange = (input,output) => {
        store.dispatch(setSelectedQuestion({ ...selectedQuestion, "sampleInput": [...input],"sampleOutput": [...output] }))
    }

    const onInputTestcaseChange = (input) => {
        store.dispatch(setSelectedQuestion({ ...selectedQuestion, "sampleInput": [...input]}))
    }

    const onOutputTestcaseChange = (output) => {
        store.dispatch(setSelectedQuestion({ ...selectedQuestion,"sampleOutput": [...output] }))
    }

    const onTestcaseRemoved =(index) => {
        const sampleInputCopy = [...selectedQuestion?.sampleInput]
        const sampleOutputCopy = [...selectedQuestion?.sampleOutput]
        console.log("Mohit 1: ",sampleInputCopy);
        sampleInputCopy.splice(index,1)
        sampleOutputCopy.splice(index,1)
        console.log("Mohit 2: ",sampleInputCopy);
        store.dispatch(setSelectedQuestion({ ...selectedQuestion, "sampleInput": [...sampleInputCopy],"sampleOutput": [...sampleOutputCopy] }))
    }


    const onTagsChanged = (tags) => {
        store.dispatch(setSelectedQuestion({ ...selectedQuestion, "tags": tags}))
    }

    const isValidFileNames = (files) => {
        const totalFiles = files.length;
        for (let i = 0; i < totalFiles; i++) {
          const expectedFileName = (i).toString()+".txt";
          if (files[i].name !== expectedFileName) {
            return false;
          }
        }
        return true;
      };

    const sortFiles = (files) => {
        const sortedFiles = Array.from(files).sort((a, b) => {
            const aNumber = parseInt(a.name, 10);
            const bNumber = parseInt(b.name, 10);
            return aNumber - bNumber;
          });

        return sortedFiles
    }

    const handleInputFileChange = (event) => {
        const files = event.target.files;
        if(files==null){
            store.dispatch(setError({
                message: "Input Files Can't be null",
                httpStatus: null,
                code: null,
                shouldRedirect: false,
            }));

            return
        }
        const sortedFiles = sortFiles(files);
        if (isValidFileNames(sortedFiles)) {
            store.dispatch(setSelectedQuestion({...selectedQuestion, "fileInputTestCases":sortedFiles}));
        }else{
            store.dispatch(setError({
                message: "Input Files should be numeric(0 to N)",
                httpStatus: null,
                code: null,
                shouldRedirect: false,
            }));
        }
      };
    

    const handleOutputFileChange = (event) => {
        const files = event.target.files;
        if(files==null || selectedQuestion?.fileInputTestCases==null || selectedQuestion?.fileInputTestCases?.length != files?.length){

            store.dispatch(setError({
                message: "Output Files should be equal Input Files",
                httpStatus: null,
                code: null,
                shouldRedirect: false,
            }));

            return
        }

        const sortedFiles = sortFiles(files);

        if (isValidFileNames(sortedFiles)) {
            store.dispatch(setSelectedQuestion({...selectedQuestion,"fileOutputTestCases":sortedFiles}));
        }else{
            store.dispatch(setError({
                message: "Output Files should be numeric(0 to N)",
                httpStatus: null,
                code: null,
                shouldRedirect: false,
            }));
        }
    };
    

    return (
        <div>{
            selectedQuestion &&
                <Box sx={{ display: 'flex', flexDirection: 'column', overflow: 'auto', height: 'calc(100vh - 94px)', px: 2.5, py: 2 }}>
                    {console.log("ProblemEditorSection : ",selectedQuestion)}
                    <Box sx={{}}>
                        <Typography variant='body2'>Problem Title</Typography>
                        <CBTextField
                            disabled={selectedQuestion?.id != null}
                            value={selectedQuestionState?.problemTitle}
                            onChange={(e) => onTextChage(e?.target?.value, 'problemTitle')}
                            placeholder='Type Here... (Markdown Supported)' />
                    </Box>
                    <Box sx={{ mt: 4 , display:'flex', alignItems:'center'}}>
                        <Typography variant='body2'>Dificulty Level</Typography>
                        <DifficultyMenu difficultyMap={difficultyLevels} difficultyLevel={{value:selectedQuestion?.difficultyLevel, label:selectedQuestion?.difficultyLevel}} setDifficultyLevel={handleSelectDificultyLevel} />

                    </Box>

                    <Box sx={{ mt: 4 }}>
                        <Typography variant='body2'>Problem Statement</Typography>
                        <CBTextField
                            value={(selectedQuestionState?.problemStatement)}
                            onChange={(e) => onMarkdownChange(e?.target?.value, 'problemStatement')}
                            placeholder='Type Here... (Markdown Supported)' />
                    </Box>

                    <Box sx={{ mt: 4, display:'flex', flexDirection:'column'}}>
                        <Box>
                            {console.log("Mohit : 3 ", selectedQuestion?.sampleInput)}
                            {selectedQuestion?.sampleInput && selectedQuestion?.sampleInput?.map((input, index) => {
                                return (
                                    <Box key={index} sx={{ mb: 2 }}>
                                        <Box sx={{mb: 1, display:'flex',flexDirection:'row',justifyContent:'space-between', alignItems:'center'}}>
                                            <SubHeading>Example {index + 1}:</SubHeading>
                                            <CBButton variant="text" onClick={() => onTestcaseRemoved(index)}>Remove</CBButton>
                                        </Box>
                                        <Stack direction="row">
                                            <CBTextField
                                                value={input}
                                                onChange={(e) => {
                                                    console.log("onchange: " + e?.target?.value)
                                                    const sampleInput = [...selectedQuestion?.sampleInput]
                                                    sampleInput[index] = e?.target?.value
                                                    onInputTestcaseChange(sampleInput)
                                                }}
                                                placeholder='Type Here... (Markdown Supported)' 
                                            />
                                            <Box sx={{mr:1}}/>
                                            <CBTextField
                                                value={selectedQuestion?.sampleOutput?.[index]}
                                                onChange={(e) => {
                                                    console.log("onchange: " + e?.target?.value)
                                                    const sampleOutput = [...selectedQuestion?.sampleOutput]
                                                    sampleOutput[index] = e?.target?.value
                                                    onOutputTestcaseChange(sampleOutput)
                                                }}
                                                placeholder='Type Here... (Markdown Supported)' 
                                            />
                                            
                                        </Stack>
                                    </Box>

                                );
                            })}
                        </Box>
                        <CBButton variant="text"  onClick={() => {
                            let sampleInput = [...(selectedQuestion?.sampleInput ?? []),(selectedQuestion?.sampleInput?.[0] ?? [])]
                            let sampleOutput = [...(selectedQuestion?.sampleOutput ?? []),(selectedQuestion?.sampleOutput?.[0] ?? [])]

                            onTestcaseChange(sampleInput,sampleOutput)
                        }}>
                            Add Test Case
                        </CBButton>
                    </Box>

                    <Box sx={{ mt: 4 }}>
                        <Typography variant='body2'>Explaination</Typography>
                        <CBTextField
                            value={selectedQuestionState?.ioExplanation}
                            onChange={(e) => onMarkdownChange(e?.target?.value, 'ioExplanation')}
                            placeholder='Type Here... (Markdown Supported)' />
                    </Box>
                    <Box sx={{ mt: 4 }}>
                        <Typography variant='body2'>Constraints</Typography>
                        <CBTextField
                            value={selectedQuestionState?.constraints}
                            onChange={(e) => onMarkdownChange(e?.target?.value, 'constraints')}
                            placeholder='Type Here... (Markdown Supported)' />
                    </Box>

                    <Box sx={{ mt: 4 }}>
                        <Typography variant='body2'>Input Format</Typography>
                        <CBTextField
                            value={selectedQuestionState?.inputSpecification}
                            onChange={(e) => onMarkdownChange(e?.target?.value, 'inputSpecification')}
                            placeholder='Type Here... (Markdown Supported)' />
                    </Box>

                    <Box sx={{ mt: 4 }}>
                        <Typography variant='body2'>Output Format</Typography>
                        <CBTextField
                            value={selectedQuestionState?.outputSpecification}
                            onChange={(e) => onMarkdownChange(e?.target?.value, 'outputSpecification')}
                            placeholder='Type Here... (Markdown Supported)' />
                    </Box>

                    <Box sx={{ mt: 4 }}>
                        <Typography variant='body2'>Time Limit (In seconds)</Typography>
                        <CBTextField
                            value={selectedQuestion?.timeLimit}
                            onChange={(e) => onTextChage(e?.target?.value, 'timeLimit')}
                            placeholder='Type Here... ' />
                    </Box>

                    <Box sx={{ mt: 4 }}>
                        <Typography variant='body2'>Memory Limit (In MB)</Typography>
                        <CBTextField
                            value={selectedQuestion?.memoryLimit}
                            onChange={(e) => onTextChage(e?.target?.value, 'memoryLimit')}
                            placeholder='Type Here... ' />
                    </Box>

                    <Typography sx={{ mt: 4 }} variant='body2'>Tags</Typography>

                    <Autocomplete
                        multiple
                        id="tags-outlined"
                        options={topicNames}
                        value={selectedQuestion?.tags?selectedQuestion?.tags:[]}
                        getOptionLabel={(option) => option}
                        filterSelectedOptions
                        onChange={(event,value)=>onTagsChanged(value)}
                        renderInput={(params) => (
                        <CBTextField
                            {...params}
                            placeholder="Add Tags"
                        />
                        )}
                    />


                    <Box sx={{ mt: 4 }}>
                        <Typography variant='body2' sx={{mb:2}}>IdealSolution (In C++)</Typography>
                        <CBEditor
                            height="500px"
                            width="100%"
                            mode="c_cpp"
                            theme='dracula'
                            onChange={(e) => onTextChage(e,"idealSolution")}
                            value={selectedQuestion?.idealSolution}
                        />
                    </Box>

                    <Box sx={{ mt: 4 }}>
                        <Typography variant='body2' sx={{mb:2}}>Test case generator (In C++)</Typography>
                        <CBEditor
                            height="500px"
                            width="100%"
                            mode="c_cpp"
                            theme='dracula'
                            onChange={(e) => onTextChage(e,"testCaseGenerator")}
                            value={selectedQuestion?.testCaseGenerator}
                        />
                    </Box>

                    <Box sx={{ mt: 4 }}>
                        <Typography variant='body2' sx={{mb:2}}>Input Cases Files (.txt)</Typography>
                        <Button
                            variant="contained"
                            component="label">
                            Upload Input File....
                            <input
                                type="file"
                                multiple
                                accept=".txt"
                                onChange={handleInputFileChange}
                                />
                        </Button>
                    </Box>  

                    <Box sx={{ mt: 4 }}>
                        <Typography variant='body2' sx={{mb:2}}>Output Cases Files (.txt)</Typography>
                        <Button
                            variant="contained"
                            component="label">
                            Upload Output File....
                            <input
                                disabled={selectedQuestion?.fileInputTestCases==null}
                                type="file"
                                multiple
                                accept=".txt"
                                onChange={handleOutputFileChange}
                                />
                        </Button>
                    </Box>            
                </Box>
        }</div>
    )
}


  export default ProblemEditorSection;