import { createSlice } from '@reduxjs/toolkit';

const websocketSlice = createSlice({
  name: 'websocket',
  initialState: {
    socket: null,
    connected: false
  },
  reducers: {
    setWebSocket: (state, action) => {
      state.socket = action.payload;
    },
    setConnected: (state, action) => {
      state.connected = action.payload;
    }
  }
});

export const { setWebSocket, setConnected } = websocketSlice.actions;

export default websocketSlice.reducer;
