import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    showLoginPopup : {
        isVisible : true,
        isCloseable : true
    },
    isUserSignedOut : false
}

export const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        setShowLoginPopup: (state, action) => {
            state.showLoginPopup = action.payload
        },
        setUserSignedOut: (state, action) => {
            state.isUserSignedOut = action.payload
        }
    },
})

export const { setShowLoginPopup, setUserSignedOut } = loginSlice.actions

export default loginSlice.reducer