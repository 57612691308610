import { BASE_ADDRESS } from "../common/constants";

export const GET_ALL_PROBLEMS = BASE_ADDRESS+"/api/problem/list";
export const GET_PROBLEM =  BASE_ADDRESS + "/api/problem"
export const GET_PROBLEM_METADATA =  BASE_ADDRESS + "/api/problem/metadata"
export const PROBLEM_UPDATE =  BASE_ADDRESS + "/api/problem/update"
export const PROBLEM_CREATE =  BASE_ADDRESS + "/api/problem"
export const PROBLEM_UPDATE_METADATA =  BASE_ADDRESS + "/api/problem/metadata/update"
export const ADMIN_SUBMIT_PROBLEM = BASE_ADDRESS+"/api/solution/admin/submit";
export const PROBLEM_UPDATE_STATUS = BASE_ADDRESS+"/api/problem/status";
export const GET_ALL_TOPIC_TAGS = BASE_ADDRESS+"/api/topic/all";
export const ADMIN_GET_SOLUTION = BASE_ADDRESS+"/api/solution/admin/solution";


export const initialProblemMetadata = [
    {
        "problemId":"",
        "languageId":54,
        "defaultCode":"#include <bits/stdc++.h>\nusing namespace std;\n\n\n###ed1bd2ae-d85a-46a0-bb14-1be921f63bb0###\n\n\nint main() {\n\tSolution sol;\n\t//initialise input variables\n\t//scan them\n\t//output them\n\treturn 0;\n}",
        "userCode":"class Solution {\npublic:\n    // returnType funcName(Param...){\n    //     write your code here\n    // }\n};",
        "id":""
    },
    {
        "problemId":"",
        "languageId":62,
        "defaultCode":"import java.util.*;\nimport java.math.*;\n\n###ed1bd2ae-d85a-46a0-bb14-1be921f63bb0###\n\n\npublic class Main {\n\tpublic static void main(String[] args) {\n\t\tScanner sc = new Scanner(System.in);\n\t\t//code\n\t}\n}",
        "userCode":"class Solution {\n\t// write your function here\n}",
        "id":""
    },
    {
        "problemId":"",
        "languageId":71,
        "defaultCode":"###ed1bd2ae-d85a-46a0-bb14-1be921f63bb0###\n\n\nif __name__ == '__main__':\n\tsol = Solution()\n\t#write code here",
        "userCode":"class Solution:\n     #def your function here",
        "id":""
    }]


export const ProblemEditStage = [
    "PROBLEM_DESCRIPTION",
    "PROBLEM_METADATA",
    "PROBLEM_SOLVE",
]

export const ProblemStatus = {
    PUBLISHED: 'PUBLISHED',
    DRAFT: 'DRAFT'
}

export const ProblemStatusArray = [
    {label:"PUBLISHED",value:"PUBLISHED"},
    {label:"DRAFT",value:"DRAFT"}
]

export const LANGUAGE_ID_CPP = 54